import React from "react"

function ImagePanel ({ imageID, rows, columns }) {

    if (rows && columns) {
        return (
            <div style={{gridArea: `span ${rows}/ span ${columns}`}}>
                <img src={imageID} alt="customPanel" style={{width:"100%", height:"100%", borderRadius: "5px"}}></img>
            </div>
        )
    }

    //If we don't have it, it's just a standalone panel
    return (
        <div className="aspect-ratio-box">
            <img
                src={imageID}
                alt="customPanel"
                style={{width:"100%", height:"100%", borderRadius: "5px"}}
                className="aspect-ratio-box-inside">
            </img>
        </div>
    )

}

export default ImagePanel