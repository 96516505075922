import React, { useRef, Suspense } from "react"

import 'primereact/resources/themes/mdc-dark-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

import PrimeReact from 'primereact/api' //for ripple effect

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom"

import { FirebaseProvider } from "./context/FirebaseContext"
import { MapsProvider } from "./context/MapsContext"
import { GameModeProvider } from "./context/GameModeContext"

import Navbars from "./components/Essentials/Navbars"
import Home from "./components/Home/Home"
import Player from "./components/Player/Player"
import Maps from "./components/Maps/Maps"
import Discover from "./components/Maps/Discover"
import Map from "./components/Maps/Map"
import RecordsLeaderboard from "./components/Leaderboards/RecordsLeaderboard"
import PointsLeaderboard from "./components/Leaderboards/PointsLeaderboard"
import JumpstatsLeaderboard from "./components/Leaderboards/JumpstatsLeaderboard"
import Login from "./components/Auth/Login"

import { Toast } from "primereact/toast"

function App() {

    const messageToast = useRef()

    PrimeReact.ripple = true

    const PlayerSearch = React.lazy(() => import('./components/PlayerSearch/PlayerSearch'))
    const Settings = React.lazy(() => import('./components/Player/Settings/Settings'))
    const Admin = React.lazy(() => import('./components/Admin/Admin'))
    const Servers = React.lazy(() => import('./components/Servers/Servers'))
    const ServerBrowser = React.lazy(() => import('./components/Servers/ServerBrowser'))
    const DistbugCalculator = React.lazy(() => import('./components/DistbugCalculator/DistbugCalculator'))
    const MapStats = React.lazy(() => import('./components/Maps/Pages/Stats/MapStats'))
    const Achievements = React.lazy(() => import('./components/Player/Achievements/Achievements'))

    return (
        <Router>
            <FirebaseProvider>
                <GameModeProvider>
                    <MapsProvider messageToast={messageToast}>
                        <Navbars messageToast={messageToast} />
                        <Switch>
                            <Route exact path="/">
                                <Home messageToast={messageToast} />
                            </Route>
                            <Route path="/player/:steamid">
                                <Player messageToast={messageToast} Achievements={Achievements} Settings={Settings} />
                            </Route>
                            <Route exact path="/maps">
                                <Maps messageToast={messageToast} />
                            </Route>
                            <Route exact path="/maps/discover">
                                <Discover messageToast={messageToast} />
                            </Route>
                            <Route path="/maps/:mapName">
                                <Map messageToast={messageToast} ServerBrowser={ServerBrowser} MapStats={MapStats} />
                            </Route>
                            <Route path="/store">
                                Store
                            </Route>
                            <Route path="/leaderboards/records">
                                <RecordsLeaderboard messageToast={messageToast} />
                            </Route>
                            <Route path="/leaderboards/points">
                                <PointsLeaderboard messageToast={messageToast} />
                            </Route>
                            <Route path="/leaderboards/jumpstats/:jumptype">
                                <JumpstatsLeaderboard messageToast={messageToast} />
                            </Route>
                            <Route path="/servers">
                                <Suspense fallback={<></>}>
                                    <Servers messageToast={messageToast} ServerBrowser={ServerBrowser} />
                                </Suspense>
                            </Route>
                            <Route path="/bans">
                                Bans
                            </Route>
                            <Route path="/playersearch">
                                <Suspense fallback={<></>}>
                                    <PlayerSearch messageToast={messageToast} />
                                </Suspense>
                            </Route>
                            <Route path="/compare">
                                Compare
                            </Route>
                            <Route path="/distbug">
                                <Suspense fallback={<></>}>
                                    <DistbugCalculator messageToast={messageToast} />
                                </Suspense>
                            </Route>
                            <Route path="/games">
                                Games
                            </Route>
                            <Route path="/options">
                                Options
                            </Route>
                            <Route path="/login">
                                <Login messageToast={messageToast} />
                            </Route>
                            <Route path="/signout">
                                Sign Out
                            </Route>
                            <Route path={"/admin"} >
                                <Suspense fallback={<></>}>
                                    <Admin messageToast={messageToast} />
                                </Suspense>
                            </Route>
                        </Switch>
                    </MapsProvider>
                </GameModeProvider>
            </FirebaseProvider>
            <Toast ref={messageToast} />
        </Router>
    );
}

export default App;