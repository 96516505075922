import { useState } from "react"

import { doc, getDoc, setDoc, increment, serverTimestamp } from "firebase/firestore/lite"

import { useFirebaseUser, useFirestore } from "../../context/FirebaseContext"
import { GetAPI_PlayerTimes } from '../../Utils/APICalls'
import LoginButton from "../Essentials/LoginButton"

import { Dialog } from 'primereact/dialog'
import { Button } from "primereact/button"
import { Rating } from 'primereact/rating'

function RatingDialog ({ dialogRating, setDialogRating, mapName, mapID, playerTimesData, messageToast }) {

    const [currentUser] = useFirebaseUser()
    const db = useFirestore()

    const [loadingRating, setLoadingRating] = useState(false)

    const uploadToFirebase = async () => {
        try {
            const docRef = doc(db, "users", currentUser.uid, "ratings", mapID.toString())
            const docSnap = await getDoc(docRef)
    
            if (docSnap.exists()) {
                // Player has already voted for this map.
                messageToast.current.show({severity: 'warn', summary: 'Already voted', detail: `You have already rated this map with ${docSnap.data().stars} star${docSnap.data().stars > 1 ? "s" : ""}.`, life: 4000})
            } else {
                // Player hasn't voted for this map yet, upload vote.
                const mapRef = doc(db, "maps", mapID.toString())
    
                await Promise.all([
                    setDoc(docRef, {stars: dialogRating}),
                    setDoc(mapRef, {
                            stars: increment(dialogRating),
                            votes: increment(1),
                            updated_on: serverTimestamp()
                        }, {merge: true}
                    )
                ]) 
                messageToast.current.show({severity: 'success', summary: 'Success!', detail: `Your vote (${dialogRating} star${dialogRating > 1 ? "s" : ""}) has been submitted! Overall rating is updated once a day.`, life: 4000})
            }

        } catch (err) {
            messageToast.current.show({severity: 'error', summary: 'KZ Profile', detail: `An error occurred with KZ Profile's backend.`, sticky: true})
        }
        
        setLoadingRating(false)
        setDialogRating(null)
    }

    const confirmVote = () => {

        if (!mapID) {
            setDialogRating(null)
            return
        }

        setLoadingRating(true)

        //If we have a run in this gameMode continue
        if (playerTimesData.pro.id || playerTimesData.tp.id) {
            uploadToFirebase()
        }
        //If we don't, call the API to see if the player has at least 1 run in this map in any mode.
        else {
            GetAPI_PlayerTimes(currentUser.uid, mapName, 0, null, null, 1)
                .then(response => {
                    if (response.data.length) {
                        uploadToFirebase()
                    } else {
                        setLoadingRating(false)
                        setDialogRating(null)
                        messageToast.current.show({severity: 'warn', summary: 'No time', detail: "You must finish the map in any mode before rating it.", life: 4000})
                    }
                })
                .catch(() => {
                    setLoadingRating(false)
                    setDialogRating(null)
                    messageToast.current.show({severity: 'error', summary: 'Global API', detail: "An error occurred with the global API.", life: 4000})
                })
        }
    }
    
    return (
        mapID ?
        <Dialog
            visible={dialogRating !== null}
            header={currentUser ? "Confirm rating" : "Not logged in"}
            footer={
                currentUser &&
                <div className="p-d-flex p-jc-end">
                    <Button disabled={loadingRating} className="p-button-text" label="No" onClick={() => setDialogRating(null)} />
                    <Button loading={loadingRating} label="Yes" onClick={confirmVote} />
                </div>
            }
            onHide={() => {
                if (!loadingRating) {
                    setDialogRating(null)
                }
            }}
            closeOnEscape={false}
        >
            {currentUser ?
                <>
                    Rate <b style={{color: "var(--primary-color)", margin: "0 0.25rem"}}>{mapName}</b> with 
                    <Rating style={{display:"inline-block", margin: "0 .5rem"}} readOnly={loadingRating} value={dialogRating} onChange={e=>setDialogRating(e.value)} cancel={false} />
                    [ {dialogRating} ] star{dialogRating > 1 && "s"}?
                    
                </> :
                <>
                    <span className="p-mb-2">To rate maps, please first log in.</span>
                    <div className="p-d-flex p-jc-center p-mt-3">
                        <LoginButton />
                    </div>
                </>
            }
        </Dialog>
        : null
    )
}

export default RatingDialog