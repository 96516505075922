import React, { useState, useEffect } from "react"

import "./BannerBackground.css"

import { useGlobalMaps } from "../../context/MapsContext"

function BannerBackground ({ mapID }) {

    const [mapName, setMapName] = useState(undefined)
    const globalMaps = useGlobalMaps()

    useEffect(()=>{
        if (!mapID) {
            //we don't have the id yet, might be loading
            
            setMapName(undefined)

        } else if (!/^[\d]{1,}$/.test(mapID)) {
            //We have the name, use that. This gets called twice, but it's fine, doesn't rerender.
            
            setMapName(mapID)

        } else if (globalMaps.length) {
            //We have the id, return the name
            
            const mapFound = globalMaps.find(map=>{
                return map.id === mapID
            })
            
            if (mapFound) { //just in case the id is wrong or the map got degloballed
                setMapName(mapFound.name)
            } else {
                setMapName(undefined)
            }
        }
    },[globalMaps, mapID])

    return (
        <div className="banner-background" style={{backgroundImage: mapName ? `url("https://raw.githubusercontent.com/KZGlobalTeam/map-images/public/webp/${mapName}.webp")` : ""}}></div>
    )
}

export default BannerBackground