import { useState } from "react"
import { useLocation, useHistory } from "react-router-dom"

export const useSearchOrStorage = (key, initialValue) => {

    const history = useHistory()
    const location = useLocation()
    const urlSearchParams = new URLSearchParams(location.search)

    const [searchOrStorageValue, setSearchOrStorageValue] = useState(() => {
        try {
            const param = urlSearchParams.get(key)
            const storageItem = window.localStorage.getItem(key)
            if (param) {
                if (param === "true") {
                    return true
                } else if (param === "false") {
                    return false
                } else {
                    return param
                }
            } else if (storageItem) {
                return JSON.parse(storageItem)
            } else {
                return initialValue
            }
        } catch (error) {
            console.log(error)
            return initialValue
        }
    })
    const setValue = (value) => {
        try {
            const valueToSearchOrStore = value instanceof Function ? value(searchOrStorageValue) : value
            setSearchOrStorageValue(valueToSearchOrStore)
            window.localStorage.setItem(key, JSON.stringify(valueToSearchOrStore))
            //For cleaning the old search param (cleaner urls). Maybe add a setting for this:
            history.push({search: ""})
            //For updating the current search param:
            /*urlSearchParams.set(searchParam, valueToSearchOrStore)
            history.push({search: urlSearchParams.toString()})*/
        } catch (error) {
            console.log(error)
        }
      }
    return [searchOrStorageValue, setValue]
}