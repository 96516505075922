import React from "react"

import "./LeaderboardCard.css"

import {
    Link
} from "react-router-dom"

import leaderboard1 from '../../img/leaderboard-1.png'
import leaderboard2 from '../../img/leaderboard-2.png'
import leaderboard3 from '../../img/leaderboard-3.png'

function LeaderboardCard ({ playerPlace, playerID, playerName, playerScore, playerAvatar, leaderboardType }) {
    
    const placingProps = [
        {
            color: "#e3ad39",
            marginTop: "0px",
            numberImage: leaderboard1
        },
        {
            color: "#d7d7d7",
            marginTop: "clamp(20px, 6vw, 40px)",
            numberImage: leaderboard2
        },
        {
            color: "#ad8a56",
            marginTop: "clamp(40px, 12vw, 80px)",
            numberImage: leaderboard3
        }
    ]

    const cardStyle = {
        marginTop: placingProps[playerPlace-1].marginTop,
        //backgroundImage: `linear-gradient(0deg, rgba(18,18,18,1) 5%, rgba(18,18,18,0.5) 100%), url(${playerAvatar})`
    }

    return (
        <div className="l-card-container" style={cardStyle}>
            <img alt="avatar-background" src={playerAvatar} className="l-avatar-background"></img>
            <div className="l-background-gradient"></div>
            <img alt="placing" src={placingProps[playerPlace-1].numberImage} className="l-placing"></img>
            <div className="l-avatar">
                <Link to={`/player/${playerID}${leaderboardType === "jumpstats" ? "/jumpstats/longjump" : ""}`}>
                    <img className="l-avatar-img" alt="avatar" src={playerAvatar}></img>
                </Link>
            </div>
            <div style={{paddingTop: "1rem"}}>
                <Link to={`/player/${playerID}${leaderboardType === "jumpstats" ? "/jumpstats/longjump" : ""}`}>
                    <div className="l-name p-text-nowrap p-text-truncate">{playerName}</div>
                </Link>
                <div
                    className="l-score"
                    style={{
                        color: placingProps[playerPlace-1].color,
                        fontSize: leaderboardType === "records" ? "clamp(2.5rem, 8vw, 3.5rem)" : "clamp(1.5rem, 6vw, 2.5rem)"
                    }}
                >
                    {leaderboardType === "jumpstats" ?
                        playerScore.toLocaleString(undefined, { minimumFractionDigits: 4 }) :
                        playerScore.toLocaleString()}
                </div>
            </div>
        </div>
    )
}

export default LeaderboardCard