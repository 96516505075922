import React from 'react'

import "./MapCard.css"

import { Link } from "react-router-dom"

import ErrorImage from '../../img/error-image.png'

import VideoGallery from "./VideoGallery"
import { colorPoints, mapTimeString } from '../../Utils/TableRows'

import { Button } from 'primereact/button'

function MapCard ( { map, runType, setDialogVisibility, setDialogProperties } ) {
    
    const TIER = ["Very Easy","Easy","Medium","Hard","Very Hard","Extreme","Death"]
    const tierColors = ["#40fe40", "#a1fe47", "#ece37a", "#e4ae39", "#fe4040", "#fe0000", "#d22ce5"]

    const openTimesDialog = () => {
        setDialogProperties({
            header: map.name,
            content: map.filters.map(filter => {
                //I don't show NUB finish in this panel because pro and tp gives more info. When shown both times, you know which
                //one is NUB. When only showing one time, then NUB can be more desired than TP.
                const proFinish = map.finishes.find(finish => finish.stage === filter && !finish.has_teleports)
                const tpFinish = map.finishes.find(finish => finish.stage === filter && finish.has_teleports)
                return (
                    <div key={filter}>
                        <h3>{filter === 0 ? "Main Stage" : `Bonus ${filter}`}</h3>
                        <div>
                            <span className="pro-time p-mb-2" style={{width:"2.5rem", display:"inline-block"}}>PRO:</span>
                            {proFinish ? <>{mapTimeString(proFinish)} | {colorPoints(proFinish)}</> : "-"}
                        </div>
                        <div>
                            <span className="tp-time p-mb-2" style={{width:"2.5rem", display:"inline-block"}}>TP:</span>
                            {tpFinish ? <>{mapTimeString(tpFinish)} | {colorPoints(tpFinish)}</> : "-"}
                        </div>
                    </div>
                )
            })
        })
        setDialogVisibility(true)
    }

    const coloredFinish = () => {

        let points

        if (runType === "pro") {
            points = map.finishes.find(finish => {
                return !finish.has_teleports && finish.stage === 0
            })
            points = points ? points.points : null
        }
        else if (runType === "tp") {
            points = map.finishes.find(finish => {
                return finish.has_teleports && finish.stage === 0
            })
            points = points ? points.points : null
        }
        else if (runType === "nub") {
            points = map.finishes.filter(finish => {
                return finish.stage === 0
            })
            
            points = points.length ? points.reduce((a,b) => {return a.time <= b.time ? a : b}).points : null
        }

        if (points===1000){
            return <Button icon="pi pi-clock" className="p-button-rounded p-button-warning p-button-text blurry-text" style={{color: "#e4ae39"}} onClick={openTimesDialog} />
        }
        if(points >= 900){
            return <Button icon="pi pi-clock" className="p-button-rounded p-button-danger p-button-text blurry-text" style={{color: "#fa292e"}} onClick={openTimesDialog} />
        }
        if (points >= 800) {
            return <Button icon="pi pi-clock" className="p-button-rounded p-button-info p-button-text blurry-text" style={{color: "#5d96d6"}} onClick={openTimesDialog} />
        }
        if (points >= 700) {
            return <Button icon="pi pi-clock" className="p-button-rounded p-button-success p-button-text blurry-text" style={{color: "#4abc8d"}} onClick={openTimesDialog} />
        }
        if (points) {
            return <Button icon="pi pi-clock" className="p-button-rounded p-button-plain p-button-text blurry-text" style={{color: "rgba(255, 255, 255, 0.86)"}} onClick={openTimesDialog} />
        }
        return <Button icon="pi pi-clock" className="p-button-rounded p-button-plain p-button-text blurry-text" style={{color: "rgba(255, 255, 255, 0.6)"}} onClick={openTimesDialog} />
    }

    const coloredBonus = () => {

        let points

        if (runType === "pro") {
            points = map.finishes.filter(finish => {
                return !finish.has_teleports && finish.stage !== 0
            })
        }
        else if (runType === "tp") {
            points = map.finishes.filter(finish => {
                return finish.has_teleports && finish.stage !== 0
            })
        }
        else if (runType === "nub") {
            points = map.finishes.filter(finish => {
                return finish.stage !== 0
            })
        }

        points = points.length ? points.reduce((a,b) => {return a.points >= b.points ? a : b}).points : null
        
        if (points===1000){
            return <Button icon="pi pi-flag" className="p-button-rounded p-button-warning p-button-text blurry-text" style={{color: "#e4ae39"}} onClick={openTimesDialog} />
        }
        if(points >= 900){
            return <Button icon="pi pi-flag" className="p-button-rounded p-button-danger p-button-text blurry-text" style={{color: "#fa292e"}} onClick={openTimesDialog} />
        }
        if (points >= 800) {
            return <Button icon="pi pi-flag" className="p-button-rounded p-button-info p-button-text blurry-text" style={{color: "#5d96d6"}} onClick={openTimesDialog} />
        }
        if (points >= 700) {
            return <Button icon="pi pi-flag" className="p-button-rounded p-button-success p-button-text blurry-text" style={{color: "#4abc8d"}} onClick={openTimesDialog} />
        }
        if (points) {
            return <Button icon="pi pi-flag" className="p-button-rounded p-button-plain p-button-text blurry-text" style={{color: "rgba(255, 255, 255, 0.86)"}} onClick={openTimesDialog} />
        }
        return <Button icon="pi pi-flag" className="p-button-rounded p-button-plain p-button-text blurry-text" style={{color: "rgba(255, 255, 255, 0.6)"}} onClick={openTimesDialog} />
    }
        
    return (
        <>
        <Link className="hoverable-map-card aspect-ratio-box" to={`/maps/${map.name}`}>
            <div className="aspect-ratio-box-inside map-image" style={{backgroundImage:`url(https://raw.githubusercontent.com/KZGlobalTeam/map-images/public/webp/thumb/${map.name}.webp), url(${ErrorImage})`}}>
            </div>
            <div className="map-info" style={{boxShadow: `0px -4px 0px 0px ${tierColors[map.difficulty-1]} inset`}}>
                <div className="map-name p-text-nowrap p-text-truncate p-p-1">
                    {map.name}
                </div>
                {map.mappers &&
                <div className="mapper-name">
                    <span style={{color:"grey"}}>by</span> {map.mappers.length > 2 ? `${map.mappers[0]} & ${map.mappers.length - 1} more` : map.mappers.join(" & ")}
                </div>
                }
            </div>
        </Link>
        <div className="map-card-description">
            <div className="map-tier" style={{color: tierColors[map.difficulty-1]}}>
                {TIER[map.difficulty-1]}
            </div>
            <Link className="map-name p-text-nowrap p-text-truncate" to={`/maps/${map.name}`}>
                {map.name}
            </Link>
            <div className="map-details">
                <div className="map-rating">
                    <i className="pi pi-star p-mr-1"></i>
                    <span>{map.rating.toFixed(1)}</span>
                </div>
                {map.filters.indexOf(0) !== -1 && coloredFinish()}
                {map.filters.indexOf(1) !== -1 && coloredBonus()}
                {map.videos && <Button icon="pi pi-caret-right" className="p-button-rounded p-button-plain p-button-text blurry-text" onClick={()=>{
                    setDialogProperties({
                        header: map.name,
                        content: <VideoGallery videos={map.videos} />
                    })
                    setDialogVisibility(true)
                }} />}
            </div>
        </div>
        </>
    )
}

export default MapCard