import { useState } from "react"
import { useLocation, useHistory } from "react-router-dom"

//explanation on useLocalStorage.js
export const useSearchParams = (searchParam, initialValue) => {
    const location = useLocation()
    const history = useHistory()
    const urlSearchParams = new URLSearchParams(location.search)
    const [searchParamValue, setSearchParamValue] = useState(() => {
        try {
            const param = urlSearchParams.get(searchParam)
            if (param) {
                if (param === "true") {
                    return true
                }
                if (param === "false") {
                    return false
                }
                return param
            }
            return initialValue
        } catch (error) {
            console.log(error)
            return initialValue
        }
    })
    const setValue = (value, persistant) => {
        try {
            const valueToSearch = value instanceof Function ? value(searchParamValue) : value
            setSearchParamValue(valueToSearch)
            //Maybe add a setting for this:
            if (persistant) {
                //Update the current search param:
                urlSearchParams.set(searchParam, valueToSearch)
                history.push({search: urlSearchParams.toString()})
            } else {
                //Delete all old params
                history.push({search: ""})
            }
        } catch (error) {
            console.log(error)
        }
      }
    return [searchParamValue, setValue]
}