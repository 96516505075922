import React, { useState, useEffect, useContext } from "react"

import "./Discover.css"

import { MapsContext } from "../../context/MapsContext"

import MapCard from "./MapCard"

import { Carousel } from 'primereact/carousel'

function Discover () {

    const mapsContext = useContext(MapsContext)

    //Carousel
    const [lastRelease, setLastRelease] = useState([])
    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 4,
            numScroll: 4
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ]

    useEffect(()=>{
        document.title = "Discover - KZ Profile"
    },[])

    useEffect(() => {
        //or sort by created_on and grab the maps until the date changes
        if (mapsContext.globalMaps.length) {
            const latestMap = mapsContext.globalMaps.reduce((mapA, mapB) => {
                const aMill = new Date (mapA.created_on.substr(0, 10)).getTime()
                const bMill = new Date (mapB.created_on.substr(0, 10)).getTime()
                return (aMill >= bMill ? mapA : mapB)
            })
            const latestRelease = mapsContext.globalMaps.filter((map)=>{
                return map.created_on.substr(0, 10) === latestMap.created_on.substr(0, 10)
            })
            setLastRelease(latestRelease)
        }
    },[mapsContext.globalMaps])

    const mapsListTemplate = (map) => {
        return (
            <div className="p-p-1">
                <MapCard map={map} imageOnly />
            </div>
        )
    }

    return (
        <div>
            <Carousel
                value={lastRelease}
                itemTemplate={mapsListTemplate}
                className="mapspage-carousel"
                numVisible={6}
                numScroll={6}
                responsiveOptions={responsiveOptions}
                circular={true}
                header={<h1 style={{padding:"0 3.25rem"}}>Last Release</h1>}
                style={{position:"relative"}}
            >
            </Carousel>
        </div>
    )
}

export default Discover