import React, { useState, useEffect, useContext, useCallback, Suspense, useRef } from "react"

import "./Map.css"
import ErrorImage from '../../img/error-image.png'
import WrMedal from '../../img/medals/WRs.png'
import RedMedal from '../../img/medals/900s.png'
import BlueMedal from '../../img/medals/800s.png'

import {
    Link,
    NavLink,
    Switch,
    Route,
    useRouteMatch,
    useParams
} from "react-router-dom"

import { formatDistanceToNowStrict } from 'date-fns'

import { useGlobalMaps, useKzProfileMaps } from "../../context/MapsContext"
import { GameModeContext, GameModeID } from "../../context/GameModeContext"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import { useLocalSettings } from "../../hooks/useLocalSettings"
import { GetAPI_MapTimes, GetAPI_PlayerTimes, GetSteam_Profile, GetAPI_MapFilters } from '../../Utils/APICalls'

import { colorPoints, formatDate } from "../../Utils/TableRows"

import BannerBackground from "../Essentials/BannerBackground"
import ModeChooser from "../Essentials/ModeChooser"
import VideoGallery from "./VideoGallery"
import PlayerFlag from '../Essentials/PlayerFlag'
import MapRanking from "./Pages/MapRanking"
import BonusRanking from "./Pages/BonusRanking"
import RatingDialog from "./RatingDialog"

import { Rating } from 'primereact/rating'
import { Tooltip } from 'primereact/tooltip'
import { Skeleton } from 'primereact/skeleton'

function Map ({ messageToast, ServerBrowser, MapStats }) {

    let match = useRouteMatch()
    let bonusMatch = useRouteMatch('/maps/:mapName/bonus/:stage') //if !== null we're in bonus tab.
    let { mapName } = useParams()

    const globalMaps = useGlobalMaps()
    const kzProfileMaps = useKzProfileMaps()
    const {gameMode, runType} = useContext(GameModeContext)
    const [steamProfileData] = useLocalStorage("steamProfileData", null) //smies: 76561198325578948 mine: 76561198267993933
    const [localSettings] = useLocalSettings()

    const [extendedMapData, setExtendedMapData] = useState({})

    const [mapTimesData, setMapTimesData] = useState({pro: null, tp: null, nub: null})
    const [playerTimesData, setPlayerTimesData] = useState({pro: {}, tp: {}, nub: {}})
    const [wrData, setWrData] = useState({pro: null, tp: null, nub: null})
    
    const TIER = ["Very Easy","Easy","Medium","Hard","Very Hard","Extreme","Death"]
    const tierColors = ["#40fe40", "#a1fe47", "#ece37a", "#e4ae39", "#fe4040", "#fe0000", "#d22ce5"]
    
    const [currentBonusData, setCurrentBonusData] = useState({
        mapName: null,
        stage: null,
        gameMode: null,
        pro: null,
        tp: null,
        nub: null
    })
    const bonusFiltersRef = useRef({mapID: 0, filters: []})
    
    const [loading, setLoading] = useState(true)
    const [loadingPlayerData, setLoadingPlayerData] = useState(true)
    const [loadingWrData, setLoadingWrData] = useState(true)

    const [loadingServers, setLoadingServers] = useState(false)

    const [dialogRating, setDialogRating] = useState(null)

    useEffect(()=>{
        //Effects that only have to be run once per map
        if (globalMaps.length && kzProfileMaps) {
            document.title = `${mapName} - KZ Profile`
            window.scrollTo(0, 0)
    
            const mapData = globalMaps.find(map => 
                map.name === mapName
            )

            if (mapData) {

                setExtendedMapData(() => {

                    const kzProfileMapData = kzProfileMaps.find(map => 
                        map.id === mapData.id
                    )

                    if (kzProfileMapData) {
                        return {
                            ...mapData,
                            ...kzProfileMapData,
                            rating: kzProfileMapData.votes ? kzProfileMapData.stars / kzProfileMapData.votes : 0,
                            created_on: mapData.created_on === "0001-01-01T00:00:00" ? "2018-01-09T10:45:49" : mapData.created_on
                        }
                    }

                    return {
                        ...mapData,
                        rating: 0,
                        created_on: mapData.created_on === "0001-01-01T00:00:00" ? "2018-01-09T10:45:49" : mapData.created_on
                    }
                })

            } else {
                messageToast.current.show({severity: 'error', summary: 'No map found.', detail: "No global map has this name.", sticky: true})
                console.log("There's no map with this name.")
            }
        }
    },[mapName, globalMaps, kzProfileMaps, messageToast])

    const fetchInitialData = useCallback(() => {
        messageToast.current.clear()
        setLoading(true)
        setLoadingPlayerData(true)
        setLoadingWrData(true)
        
        //Are we using nub or tp?
        const secondaryRunType = localSettings.modeChooserType.split("-").pop()

        let promises = [
            GetAPI_MapTimes(mapName, 0, gameMode, "pro", 100, 0),
            GetAPI_MapTimes(mapName, 0, gameMode, secondaryRunType, 100, 0)
        ]

        //Regardless of already having the player times in MapsContext, I think every time the player opens a map's page,
        //I should show them the most updated time. For example, the player is in Maps grid, plays a map, then clicks the map's card,
        //the map's data loads and he still sees the old time or no time at all. This would be confusing.
        //I won't update the playerTimesRef with this data because I'd need to do it with bonuses too. Pressing refresh in Maps or
        //Player page is cleaner.
        if (steamProfileData) {
            //No limit because there are some maps in API that returns two tp records for example fikablock with mine.
            promises.push(GetAPI_PlayerTimes(steamProfileData.steamid, mapName, 0, gameMode, null))
        }
        
        Promise.all(promises)
            .then(results => {

                setMapTimesData({
                    pro: results[0].data.map((record, index) => {
                        return {...record, place: index + 1}
                    }),
                    [secondaryRunType]: results[1].data.map((record, index) => {
                        return {...record, place: index + 1}
                    })
                })
                
                //Player Data
                if (results[2]) {
                    const proTime = results[2].data.find(finish => finish.teleports === 0)
                    const tpTime = results[2].data.find(finish => finish.teleports > 0)
                    let nubTime
                    if (!proTime) {
                        nubTime = tpTime
                    } else if (!tpTime) {
                        nubTime = proTime
                    } else {
                        nubTime = proTime.time <= tpTime.time ? proTime : tpTime
                    }
                    setPlayerTimesData({
                        pro: proTime ? proTime : {},
                        tp: tpTime ? tpTime: {},
                        nub: nubTime ? nubTime : {}
                    })
                }
                
                setLoading(false)
                setLoadingPlayerData(false)

                //Get Steam profile data of Wrs
                if (results[0].data.length || results[1].data.length) { //don't even call backend if there're no records.
                    //If data[0] is undefined (0 runs) it won't fetch that steamid
                    GetSteam_Profile([results[0].data[0]?.steamid64, results[1].data[0]?.steamid64])
                        .then(response => {
                            setWrData({
                                //Null or Steam + API data
                                pro: results[0].data[0] && {...response.data.response.players.find(player => player.steamid === results[0].data[0].steamid64), ...results[0].data[0]},
                                [secondaryRunType]: results[1].data[0] && {...response.data.response.players.find(player => player.steamid === results[1].data[0].steamid64), ...results[1].data[0]}
                            })
                            setLoadingWrData(false)
                        })
                        .catch(err => {
                            setWrData({
                                //Null or only API data
                                pro: results[0].data[0] && {...results[0].data[0], avatarmedium: ErrorImage, loccountrycode: "_unknown"},
                                [secondaryRunType]: results[1].data[0] && {...results[1].data[0], avatarmedium: ErrorImage, loccountrycode: "_unknown"}
                            })
                            setLoadingWrData(false)
                            messageToast.current.show({severity: 'warn', summary: 'Steam', detail: "An error occurred with Steam's backend.", life: 5000})
                            console.log(err)
                        })
                } else {
                    //The gameMode changed, and now there're no wrs
                    setWrData({pro: null, tp: null, nub: null})
                    setLoadingWrData(false)
                }
            })
            .catch(err => {
                messageToast.current.show({severity: 'error', summary: 'Global API', detail: 'An error occurred with the global API.', sticky: true})
                console.log(err)
                setLoading(false)
                setLoadingPlayerData(false)
                setLoadingWrData(false)
            })
    },[mapName, gameMode, steamProfileData, localSettings, messageToast])

    useEffect(()=>{
        fetchInitialData()
    },[fetchInitialData])

    useEffect(()=>{
        if (mapTimesData[runType] && !mapTimesData[runType].length) {
            messageToast.current.show({severity: 'warn', summary: 'Map Ranking', detail: 'This map has 0 runs in this game mode and run type.', life: 4000})
        }
    },[mapTimesData, runType, messageToast])

    const loadMore = (offset) => {
        setLoading(true)

        GetAPI_MapTimes(mapName, 0, gameMode, runType, 100, offset)
            .then(response => {
                const dataWithIndex = response.data.map((record, index) => {
                    return {...record, place: index + 1 + offset}
                })

                setMapTimesData(oldData => {
                    return {...oldData, [runType]: [...oldData[runType], ...dataWithIndex]}
                })

                setLoading(false)
                window.scrollTo(0, 0)
            })
            .catch(err => {
                messageToast.current.show({severity: 'error', summary: 'Global API', detail: 'An error occurred with the global API.', sticky: true})
                console.log(err)
                setLoading(false)
            })
    }

    const playerPlace = () => {
        if (playerTimesData[runType].time) { //check if player has a time
            const playerPlacing = mapTimesData[runType].findIndex(run => 
                run.steamid64 === steamProfileData.steamid
            )
            if (playerPlacing !== -1) {
                return `# ${playerPlacing + 1}`
            }
            return  (
                <>
                    <button className="extra-functionality-button">
                        <i className="pi pi-search"></i>
                    </button>
                    <span>{`# ${mapTimesData[runType].length}+`}</span>
                </>
            )
        }
        return "# 0"
    }

    const PBMedal = (points) => {
        if (points===1000){
            return(WrMedal)
        }
        if(points >= 900){
            return(RedMedal)
        }
        return(BlueMedal)
    }

    const getMapFilters = useCallback(async (mapID, game_mode, run_type, onlyBonus) => {
        
        const returnFilters = (allFilters) => {
            const filtersToReturn = allFilters.filter((filter) => {
                if (onlyBonus && filter.stage === 0) {
                    return false
                }
                return (filter.mode_id === GameModeID[game_mode] && filter.has_teleports === (run_type === "pro" || run_type === "nub" ? false : true))
            })
            return filtersToReturn
        }

        if (bonusFiltersRef.current.mapID === mapID) {
            return returnFilters(bonusFiltersRef.current.filters)
        }

        const apiFilters = await GetAPI_MapFilters(mapID)
        
        bonusFiltersRef.current = {mapID: mapID, filters: [...apiFilters.data]}
        return returnFilters(apiFilters.data)

        //Maybe I should console.log/messageToast the error, but if this fails, most probably another api call has already failed and notify.

    },[])

    return (
        <div>
            <div className="map-banner">
                <BannerBackground mapID={mapName} />
                <ModeChooser />
                <div className="map-banner-content">
                    <div className="map-info">
                        <div className="map-description">
                            <div  className="map-description-right">
                                <a href={extendedMapData.workshop_url} target="_blank" rel="noreferrer" style={{width: "100%"}}>
                                    <div className="map-thumbnail" style={{backgroundImage: `url("https://raw.githubusercontent.com/KZGlobalTeam/map-images/public/webp/${mapName}.webp"), url(${ErrorImage})`}}></div>
                                </a>
                                <div className="map-rating">
                                    <Rating
                                        value={extendedMapData.rating}
                                        cancel={false}
                                        onChange={(e) => setDialogRating(e.value)}
                                    />
                                    <span className="p-ml-2">
                                        {extendedMapData.rating?.toFixed(1)} ({extendedMapData.votes ? extendedMapData.votes.toLocaleString() : "0"})
                                    </span>
                                </div>
                            </div>
                            <div className="map-description-left p-text-truncate">
                                <div className="map-title p-text-truncate">{mapName}</div>
                                <div className="map-tier" style={{color: tierColors[extendedMapData.difficulty - 1]}}>{TIER[extendedMapData.difficulty - 1]}</div>
                                <div className="map-mapper p-ml-1">
                                    <div>
                                        {extendedMapData.mappers?.length && <span style={{color:"rgba(255,255,255,0.6)", fontWeight:"normal"}}>by</span>} {
                                            extendedMapData.mappers?.map((mapper, index) => {
                                                const mapperLink = mapper.steamid ? <Link to={`/player/${mapper.steamid}`}>{mapper.name}</Link> : mapper.name
                                                if (index === 0){
                                                    return <span key={index}>{mapperLink}</span>
                                                }
                                                if (index === extendedMapData.mappers.length - 1) {
                                                    return <span key={index}><span> {"&"} </span> {mapperLink}</span>
                                                }
                                                return <span key={index}><span>, </span> {mapperLink}</span>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="map-details">
                            {loadingWrData ?
                            <div className="wr-info">
                                <Skeleton shape="circle" size="4rem" className="wr-avatar"></Skeleton>
                                <div className="p-ml-3">
                                    <Skeleton width="6rem" className="wr-name p-mb-2"></Skeleton>
                                    <Skeleton width="12rem" className="wr-details"></Skeleton>
                                </div>
                            </div> :
                            wrData[runType] ?
                            <div className="wr-info">
                                <Link className="wr-avatar" to={`/player/${wrData[runType].steamid64}`}>
                                    <div className="wr-avatar" style={{backgroundImage: `url(${wrData[runType].avatarmedium})`}}></div>
                                </Link>
                                <div className="p-ml-3">
                                    <div className="wr-name p-mb-2">
                                        <Link className="p-mr-2" to={`/player/${wrData[runType].steamid64}`}>
                                            {wrData[runType].player_name}
                                        </Link>
                                        <PlayerFlag nationality={wrData[runType].loccountrycode} flagHeight="20" />
                                    </div>
                                    <div className="wr-details">
                                        {`${new Date(wrData[runType].time * 1000).toISOString().substr(11, 11)} • ${formatDistanceToNowStrict(new Date(wrData[runType].created_on) - new Date().getTimezoneOffset() * 60 * 1000, { addSuffix: true })}`}
                                    </div>
                                </div>
                            </div> :
                            <div className="wr-info">
                                <div className="wr-avatar">
                                    <div className="wr-avatar"></div>
                                </div>
                                <div className="p-ml-3">
                                    <div className="wr-name p-mb-2">
                                        🕸 unclaimed 🕸
                                    </div>
                                    <div className="wr-details">
                                        00:00:00.00 • never
                                    </div>
                                </div>
                            </div>}
                            <div className="pb-info">
                                <div style={{color:"rgba(255,255,255,0.6)", marginRight:"1rem"}}>
                                    <div className="p-mb-2">PLACE:</div>
                                    <div className="p-mb-2">TIME:</div>
                                    <div>POINTS:</div>
                                </div>
                                {loadingPlayerData ?
                                <div>
                                    <Skeleton width="1.25rem" className="p-mb-2 p-ml-auto"></Skeleton>
                                    <Skeleton width="4.5625rem" className="p-mb-2 p-ml-auto"></Skeleton>
                                    <Skeleton width="1.25rem" className="p-ml-auto"></Skeleton>
                                </div> :
                                <div style={{textAlign:"right"}}>
                                    <div className="p-mb-2">{playerPlace()}</div>
                                    <div className="p-mb-2">{playerTimesData[runType].time ? new Date(playerTimesData[runType].time * 1000).toISOString().substr(11, 11) : "00:00:00.00"}</div>
                                    {colorPoints(playerTimesData[runType])}
                                </div>}
                                {!loadingPlayerData && playerTimesData[runType].points >= 800 &&
                                <div className="pb-medal">
                                    <img alt="pb-medal" src={PBMedal(playerTimesData[runType].points)}></img>
                                </div>}
                            </div>
                            <div className="map-api-info">
                                <div style={{color:"rgba(255,255,255,0.6)", marginRight:"1rem"}}>
                                    <div className="p-mb-2">RELEASED:</div>
                                    <div className="p-mb-2">FILE SIZE:</div>
                                    <div>WORKSHOP:</div>
                                </div>
                                {extendedMapData.id &&
                                <div style={{textAlign:"right"}}>
                                    <Tooltip target=".tooltip-object" position="top" />
                                    <div className="p-mb-2 tooltip-object" data-pr-tooltip={extendedMapData.created_on && formatDate(extendedMapData)}>{extendedMapData.created_on && formatDistanceToNowStrict(new Date(extendedMapData.created_on) - new Date().getTimezoneOffset() * 60 * 1000, { addSuffix: true })}</div>
                                    <div className="p-mb-2">
                                        <button className="extra-functionality-button">
                                            <i className="pi pi-download"></i>
                                        </button>
                                        <span>{(extendedMapData.filesize / Math.pow(1024, 2)).toFixed(0) + " MB"}</span>
                                    </div>
                                    <button
                                        className="workshop-button tooltip-object"
                                        data-pr-tooltip="Copied to clipboard!"
                                        data-pr-event="focus"
                                        onClick={() => {
                                            extendedMapData.workshop_url && navigator.clipboard.writeText(extendedMapData.workshop_url.substring(extendedMapData.workshop_url.indexOf("=") + 1))
                                        }}
                                    >
                                        {extendedMapData.workshop_url && extendedMapData.workshop_url.substring(extendedMapData.workshop_url.indexOf("=") + 1)}
                                    </button>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="video-gallery-wrapper">
                        <VideoGallery videos={extendedMapData.videos ? extendedMapData.videos : []} mapName={mapName} />
                    </div>
                </div>
                <RatingDialog
                    dialogRating={dialogRating}
                    setDialogRating={setDialogRating}
                    mapName={mapName}
                    mapID={extendedMapData.id}
                    playerTimesData={playerTimesData}
                    messageToast={messageToast}
                />
                <ul className="tab-menu">
                    <li className="tab-menu-item">
                        <NavLink className="tab-menu-link" exact to={match.url} >
                            <span className="tab-menu-icon pi pi-align-justify"></span>
                            <span className="tab-menu-text p-d-none p-d-md-inline-flex">Ranking</span>
                        </NavLink>
                    </li>
                    <li className="tab-menu-item">
                        <NavLink className="tab-menu-link" to={`${match.url}/bonus/${currentBonusData.mapName === mapName ? currentBonusData.stage : 1}`}
                            isActive={()=>{
                                return bonusMatch !== null
                            }}
                        >
                            <span className="tab-menu-icon pi pi-flag"></span>
                            <span className="tab-menu-text p-d-none p-d-md-inline-flex">Bonus</span>
                        </NavLink>
                    </li>
                    <li className="tab-menu-item">
                        <NavLink className="tab-menu-link" to={`${match.url}/stats`} >
                            <span className="tab-menu-icon pi pi-chart-bar"></span>
                            <span className="tab-menu-text p-d-none p-d-md-inline-flex">Stats</span>
                        </NavLink>
                    </li>
                    <li className="tab-menu-item">
                        <NavLink className="tab-menu-link" to={`${match.url}/servers`} >
                            <span className="tab-menu-icon pi pi-desktop"></span>
                            <span className="tab-menu-text p-d-none p-d-md-inline-flex">Servers</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div className="map-route-content" >
                <Switch>
                    <Route exact path={match.path}>
                        <MapRanking
                            loading={loading}
                            mapTimesData={mapTimesData[runType]}
                            steamid={steamProfileData ? steamProfileData.steamid : null}
                            fetchInitialData={fetchInitialData}
                            loadMore={loadMore}
                            tableTitle="Map Ranking"
                        />
                    </Route>
                    <Route path={`${match.path}/bonus/:stage`} >
                        <BonusRanking
                            mapName={mapName}
                            currentBonusData={currentBonusData}
                            setCurrentBonusData={setCurrentBonusData}
                            getMapFilters={getMapFilters}
                            mapID={extendedMapData.id}
                            steamid={steamProfileData?.steamid}
                            localSettings={localSettings}
                            messageToast={messageToast}
                        />
                    </Route>
                    <Route path={`${match.path}/stats`} >
                        <Suspense fallback={<></>}>
                            <MapStats
                                mapName={mapName}
                                mapID={extendedMapData.id}
                                getMapFilters={getMapFilters}
                                playerTimesData={playerTimesData}
                                loadingPlayerData={loadingPlayerData}
                            />
                        </Suspense>
                    </Route>
                    <Route path={`${match.path}/servers`} >
                        <Suspense fallback={<></>}>
                            <ServerBrowser masterServerFilter={mapName} loading={loadingServers} setLoading={setLoadingServers} messageToast={messageToast} />
                        </Suspense>
                    </Route>
                </Switch>
            </div>
        </div>
    )
}

export default Map