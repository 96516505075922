import React, {useState, useRef} from "react"

import { colorPoints, mapLink, serverLink, mapTimeButton, formatDate, invertedSort } from "../../Utils/TableRows"

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { Slider } from 'primereact/slider'
import { MultiSelect } from 'primereact/multiselect'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Tooltip } from 'primereact/tooltip'

import "./Finishes.css"

function Finishes ({finishedMaps, finishedBonuses, fetchPlayerFinishes, defaultSortOrder, dateFormat, loading, messageToast}) {

    const [globalFilter, setGlobalFilter] = useState("")
    const [localFilters, setLocalFilters] = useState({})
    const [currentPanel, setCurrentPanel] = useState("map_name") //to know what overlay panel is currently open, so I can close it when another one opens (a bug doesn't close it sometimes)

    const [mapsFilterValue, setMapsFilterValue] = useState("")
    const [mapsFilterMode, setMapsFilterMode] = useState("contains")

    const [pointsFilterValue, setPointsFilterValue] = useState(0)
    const [pointsFilterMode, setPointsFilterMode] = useState("lt")

    const [timesFilterHours, setTimesFilterHours] = useState(0)
    const [timesFilterMinutes, setTimesFilterMinutes] = useState(0)
    const [timesFilterSeconds, setTimesFilterSeconds] = useState(0)
    const [timesFilterMiliseconds, setTimesFilterMiliseconds] = useState(0)
    const [timesFilterMode, setTimesFilterMode] = useState("gt")

    const [tiersFilterValue, setTiersFilterValue] = useState([])

    const [stagesFilterValue, setStagesFilterValue] = useState(0)
    const [stagesFilterMode, setStagesFilterMode] = useState("equals")

    const [datesFilterValue, setDatesFilterValue] = useState("")
    const [datesFilterMode, setDatesFilterMode] = useState("dateIs")

    const [serversFilterValue, setServersFilterValue] = useState("")
    const [serversFilterMode, setServersFilterMode] = useState("contains")

    const [showBonuses, setShowBonuses] = useState(false)
    
    //Filter modes for dropdowns
    const stringFilterModes = [
        {label: 'Contains', value: 'contains'},
        {label: 'Starts with', value: 'startsWith'},
        {label: 'Ends with', value: 'endsWith'},
        {label: 'Equals', value: 'equals'},
        {label: 'Not equals', value: 'notEquals'}
    ]
    const pointsFilterModes = [
        {label: 'Less than', value: 'lt'},
        {label: 'Less than or equal to', value: 'lte'},
        {label: 'Greater than', value: 'gt'},
        {label: 'Greater than or equal to', value: 'gte'},
        {label: 'Equals', value: 'equals'},
        {label: 'Not equals', value: 'notEquals'}
    ]
    const timesFilterModes = [
        {label: 'Slower than', value: 'gt'},
        {label: 'Faster than', value: 'lt'}
    ]
    const tiersMultiselectOptions = [
        {label: 'Very Easy', value: 1},
        {label: 'Easy', value: 2},
        {label: 'Medium', value: 3},
        {label: 'Hard', value: 4},
        {label: 'Very Hard', value: 5},
        {label: 'Extreme', value: 6},
        {label: 'Death', value: 7}
    ]
    const stagesFilterModes = [
        {label: 'Equals', value: 'equals'},
        {label: 'Not equals', value: 'notEquals'},
        {label: 'Less than', value: 'lt'},
        {label: 'Less than or equal to', value: 'lte'},
        {label: 'Greater than', value: 'gt'},
        {label: 'Greater than or equal to', value: 'gte'}
    ]
    const dateFilterModes = [
        {label: 'Date is', value: 'dateIs'},
        {label: 'Date before', value: 'dateBefore'},
        {label: 'Date after', value: 'dateAfter'}
    ]
    //Refs
    const dt = useRef(null)
    const mapsOPanel = useRef(null)
    const pointsOPanel = useRef(null)
    const timesOPanel = useRef(null)
    const tiersOPanel = useRef(null)
    const stagesOPanel = useRef(null)
    const datesOPanel = useRef(null)
    const serversOPanel = useRef(null)

    const fieldReferences = {
        map_name: {header: "Map", overlayPanel: mapsOPanel},
        points: {header: "Points", overlayPanel: pointsOPanel},
        time: {header: "Time", overlayPanel: timesOPanel},
        difficulty: {header: "Tier", overlayPanel: tiersOPanel},
        stage: {header: "Stage", overlayPanel: stagesOPanel},
        created_on: {header: "Date", overlayPanel: datesOPanel},
        server_name: {header: "Server", overlayPanel: serversOPanel}
    }

    const tableTop = (
        <div className="p-d-flex p-ai-center" style={{height:"100%"}}>
            <div className="tableTopItem">
                <Button
                    icon="pi pi-refresh"
                    className="p-button-rounded p-button-text p-button-white"
                    onClick={e => {
                        fetchPlayerFinishes(true)
                    }}
                    tooltip="Refresh data"
                    tooltipOptions={{ position: 'top', showDelay: 500}}
                />
                <Button
                    icon="pi pi-flag"
                    className={`p-button-rounded p-button-text ${showBonuses ? "" : "p-button-white"}`}
                    onClick={e => {
                        setShowBonuses(prevState => !prevState)
                    }}
                    tooltip="Show bonuses"
                    tooltipOptions={{ position: 'top', showDelay: 500}}
                />
                <Button
                    icon="pi pi-filter-slash"
                    className="p-button-rounded p-button-text p-button-white"
                    onClick={e => {
                        setGlobalFilter("")
                        setLocalFilters({})
                        setMapsFilterValue("")
                        setPointsFilterValue(0)
                        setTimesFilterHours(0)
                        setTimesFilterMinutes(0)
                        setTimesFilterSeconds(0)
                        setTimesFilterMiliseconds(0)
                        setTiersFilterValue([])
                        setStagesFilterValue(0)
                        setDatesFilterValue("")
                        setServersFilterValue("")
                        dt.current.reset()
                    }}
                    tooltip="Clear filters"
                    tooltipOptions={{ position: 'top', showDelay: 500}}
                />
            </div>
            <div className="tableTopItem p-text-center">{showBonuses ? "Finished Bonuses" : "Finished Maps"}</div>
            <div className="tableTopItem p-text-right">
                <span className="p-input-icon-right">
                    <InputText className="p-inputtext-sm" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" type="search" style={{width:"100%"}} />
                </span>
            </div>
        </div>
    )

    const headerTemplate = (fieldName) => {
        //p-column-title class is added so column can be sorted when clicking the span
        return(
            <span className="p-d-inline-flex p-ai-center">
                <Button
                    onClick={e => {
                        fieldReferences[currentPanel].overlayPanel.current.hide(e) //there's a bug where the previous panel wouldn't close automatically
                        setCurrentPanel(fieldName)
                        fieldReferences[fieldName].overlayPanel.current.show(e)
                    }}
                    icon="pi pi-filter"
                    className={`p-button-rounded p-button-text ${!localFilters[fieldName] && "p-button-plain"}`}
                />
                <span className="p-column-title" style={{height:"2.25rem", padding:"0.571rem"}}>{fieldReferences[fieldName].header}</span>
            </span>
        )
    }

    const tierLabel = (rowData) => {
        const TIER = ["Very Easy","Easy","Medium","Hard","Very Hard","Extreme","Death"]
        return TIER[rowData.difficulty - 1]
    }

    return (
        <div>
            <DataTable 
                className="p-datatable-sm"
                value={showBonuses ? finishedBonuses : finishedMaps}
                header={tableTop}
                ref={dt}
                globalFilter={globalFilter}
                sortMode="multiple"
                multiSortMeta={[{field: defaultSortOrder, order: -1}]}
                defaultSortOrder={-1}
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rows={50}
                rowsPerPageOptions={[20,50,100]}
                rowHover={true}
                //scrollable /*overflow:auto in wrapper seems to work better*/
                loading={loading}
                filters={localFilters}
                onFilter={(e) => setLocalFilters(e.filters)}
                emptyMessage="No records found."
                /*frozenWidth="136px"*/
            >
                <Column 
                    field="map_name"
                    header={headerTemplate("map_name")}
                    body={mapLink}
                    className="p-text-nowrap p-text-truncate p-text-left"
                    headerStyle={{width:"136px"}}
                    /*frozen*/
                    sortable
                    sortFunction={e => invertedSort(e, dt)}>
                </Column>
                <Column 
                    field="points"
                    header={headerTemplate("points")}
                    body={colorPoints}
                    headerStyle={{width:"136px"}}
                    sortable>
                </Column>
                <Column 
                    field="time"
                    header={headerTemplate("time")}
                    body={(rowData) => mapTimeButton(rowData, messageToast)}
                    headerStyle={{width:"136px"}}
                    sortable
                    sortFunction={e => invertedSort(e, dt)}>
                </Column>
                {showBonuses ?
                <Column 
                    field="stage"
                    header={headerTemplate("stage")}
                    headerStyle={{width:"136px"}}
                    sortable>
                </Column> :
                <Column 
                    field="difficulty"
                    header={headerTemplate("difficulty")}
                    body={tierLabel}
                    headerStyle={{width:"136px"}}
                    sortable>
                </Column>
                }
                <Column 
                    field="created_on"
                    header={headerTemplate("created_on")}
                    body={rowData => formatDate(rowData)}
                    headerStyle={{width:"136px"}}
                    sortable>
                </Column>
                <Column 
                    field="server_name"
                    header={headerTemplate("server_name")}
                    body={serverLink}
                    className="p-fluid"
                    headerStyle={{width:"136px"}}
                    sortable
                    sortFunction={e => invertedSort(e, dt)}>
                </Column>
            </DataTable>
            
            <OverlayPanel ref={mapsOPanel}>
                <div className="p-d-flex p-jc-center p-mb-3">
                    Maps Filter
                </div>
                <div>
                    <Dropdown
                        value={mapsFilterMode}
                        options={stringFilterModes}
                        scrollHeight="300px"
                        onChange={e => {
                            setMapsFilterMode(e.value)
                        }}
                        className="p-d-flex p-mb-3"
                    />
                    <InputText
                        placeholder={"Map search"}
                        value={mapsFilterValue}
                        onChange={e => {
                            setMapsFilterValue(e.target.value)
                        }}
                        className="p-mb-3"
                    />
                    <div className="p-d-flex p-jc-around">
                        <Button
                            label="Clear"
                            onClick={e=>{
                                mapsOPanel.current.hide(e)
                                setMapsFilterValue("")
                                setMapsFilterMode("contains")
                                dt.current.filter("", "map_name", "contains")
                            }}
                            className="p-button-outlined p-button-sm"
                        />
                        <Button
                            label="Apply"
                            onClick={e=>{
                                mapsOPanel.current.hide(e)
                                if (mapsFilterValue) {
                                    dt.current.filter(mapsFilterValue, "map_name", mapsFilterMode)
                                } else {
                                    dt.current.filter("", "map_name", mapsFilterMode)
                                }
                            }}
                            className="p-button-sm"
                        />
                    </div>
                </div>
            </OverlayPanel>

            <OverlayPanel ref={pointsOPanel}>
                <div className="p-d-flex p-jc-center p-mb-3">
                    Points Filter
                </div>
                <div>
                    <Dropdown
                        value={pointsFilterMode}
                        options={pointsFilterModes}
                        /*scrollHeight="300px"*/
                        onChange={e => {
                            setPointsFilterMode(e.value)
                            /*dt.current.filter(pointsFilter.value, "points", e.value)*/
                        }}
                        className="p-d-flex p-mb-3"
                    />
                    <InputNumber
                        value={pointsFilterValue}
                        mode="decimal"
                        min={0}
                        max={1000}
                        showButtons
                        onValueChange={e => {
                            setPointsFilterValue(e.target.value)
                            /*dt.current.filter(e.target.value, "points", pointsFilter.mode)*/
                        }}
                        className="p-mb-3"
                    />
                    <div className="p-d-flex p-jc-around">
                        <Button
                            label="Clear"
                            onClick={e=>{
                                pointsOPanel.current.hide(e)
                                setPointsFilterValue(0)
                                setPointsFilterMode("lt")
                                dt.current.filter("", "points", "lt")
                            }}
                            className="p-button-outlined p-button-sm"
                        />
                        <Button
                            label="Apply"
                            onClick={e=>{
                                pointsOPanel.current.hide(e)
                                dt.current.filter(pointsFilterValue, "points", pointsFilterMode)
                            }}
                            className="p-button-sm"
                        />
                    </div>
                </div>
            </OverlayPanel>

            <OverlayPanel ref={timesOPanel} style={{width:"240px"}}>
                <div className="p-d-flex p-jc-center p-mb-3">
                    Times Filter
                </div>
                <div>
                    <Dropdown
                        value={timesFilterMode}
                        options={timesFilterModes}
                        onChange={e => {
                            setTimesFilterMode(e.value)
                        }}
                        className="p-d-flex p-mb-3"
                    />
                    <div className="p-d-flex p-jc-center p-mb-3">
                        {timesFilterHours.toString().padStart(2,"0")}:{timesFilterMinutes.toString().padStart(2,"0")}:{timesFilterSeconds.toString().padStart(2,"0")}.{timesFilterMiliseconds.toString().padStart(2,"0")}
                    </div>
                    <div className="p-grid p-ai-center p-mb-3">
                        <div className="p-col-3">hr:</div>
                        <div className="p-col-9">
                            <Slider
                                value={timesFilterHours}
                                min={0}
                                max={99}
                                onChange={e => {
                                    setTimesFilterHours(e.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="p-grid p-ai-center p-mb-3">
                        <div className="p-col-3">min:</div>
                        <div className="p-col-9">
                            <Slider
                                value={timesFilterMinutes}
                                min={0}
                                max={59}
                                onChange={e => {
                                    setTimesFilterMinutes(e.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="p-grid p-ai-center p-mb-3">
                        <div className="p-col-3">sec:</div>
                        <div className="p-col-9">
                            <Slider
                                value={timesFilterSeconds}
                                min={0}
                                max={59}
                                onChange={e => {
                                    setTimesFilterSeconds(e.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="p-grid p-ai-center p-mb-3">
                        <div className="p-col-3">ms:</div>
                        <div className="p-col-9">
                            <Slider
                                value={timesFilterMiliseconds}
                                min={0}
                                max={99}
                                onChange={e => {
                                    setTimesFilterMiliseconds(e.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="p-d-flex p-jc-around">
                        <Button
                            label="Clear"
                            onClick={e=>{
                                timesOPanel.current.hide(e)
                                setTimesFilterHours(0)
                                setTimesFilterMinutes(0)
                                setTimesFilterSeconds(0)
                                setTimesFilterMiliseconds(0)
                                setTimesFilterMode("gt")
                                dt.current.filter("", "time", "gt")
                            }}
                            className="p-button-outlined p-button-sm"
                        />
                        <Button
                            label="Apply"
                            onClick={e=>{
                                timesOPanel.current.hide(e)
                                let filterValue = timesFilterHours*3600 + timesFilterMinutes*60 + timesFilterSeconds + timesFilterMiliseconds/100
                                dt.current.filter(filterValue, "time", timesFilterMode)
                            }}
                            className="p-button-sm"
                        />
                    </div>
                </div>
            </OverlayPanel>

            <OverlayPanel ref={tiersOPanel} style={{minWidth:"240px"}}>
                <div className="p-d-flex p-jc-center p-mb-3">
                    Tiers Filter
                </div>
                <div>
                    <MultiSelect
                        value={tiersFilterValue}
                        options={tiersMultiselectOptions}
                        onChange={e => {
                            setTiersFilterValue(e.value)
                        }}
                        scrollHeight="400px"
                        className=" p-d-flex p-mb-3"
                    />
                    <div className="p-d-flex p-jc-around">
                        <Button
                            label="Clear"
                            onClick={e=>{
                                tiersOPanel.current.hide(e)
                                setTiersFilterValue([])
                                dt.current.filter([], "difficulty", "in")
                            }}
                            className="p-button-outlined p-button-sm"
                        />
                        <Button
                            label="Apply"
                            onClick={e=>{
                                tiersOPanel.current.hide(e)
                                if (tiersFilterValue.length > 0) {
                                    dt.current.filter(tiersFilterValue, "difficulty", "in")
                                } else {
                                    dt.current.filter([], 'difficulty', 'in')
                                }
                            }}
                            className="p-button-sm"
                        />
                    </div>
                </div>
            </OverlayPanel>

            <OverlayPanel ref={stagesOPanel}>
                <div className="p-d-flex p-jc-center p-mb-3">
                    Stages Filter
                </div>
                <div>
                    <Dropdown
                        value={stagesFilterMode}
                        options={stagesFilterModes}
                        onChange={e => {
                            setStagesFilterMode(e.value)
                        }}
                        className="p-d-flex p-mb-3"
                    />
                    <InputNumber
                        value={stagesFilterValue}
                        mode="decimal"
                        min={1}
                        max={100}
                        showButtons
                        onValueChange={e => {
                            setStagesFilterValue(e.target.value)
                        }}
                        className="p-mb-3"
                    />
                    <div className="p-d-flex p-jc-around">
                        <Button
                            label="Clear"
                            onClick={e=>{
                                stagesOPanel.current.hide(e)
                                setStagesFilterValue(0)
                                setStagesFilterMode("equals")
                                dt.current.filter("", "stage", "equals")
                            }}
                            className="p-button-outlined p-button-sm"
                        />
                        <Button
                            label="Apply"
                            onClick={e=>{
                                stagesOPanel.current.hide(e)
                                dt.current.filter(stagesFilterValue, "stage", stagesFilterMode)
                            }}
                            className="p-button-sm"
                        />
                    </div>
                </div>
            </OverlayPanel>

            <OverlayPanel ref={datesOPanel}>
                <div className="p-d-flex p-jc-center p-mb-3">
                    Dates Filter
                </div>
                <div>
                    <Dropdown
                        value={datesFilterMode}
                        options={dateFilterModes}
                        onChange={e => {
                            setDatesFilterMode(e.value)
                        }}
                        className="p-d-flex p-mb-3"
                    />
                    <Calendar
                        value={datesFilterValue}
                        dateFormat={dateFormat.replace("yyyy", "yy").toLowerCase()}
                        mask="9999-99-99"
                        monthNavigator
                        yearNavigator
                        yearRange="2018:2030"
                        onChange={e => {
                            setDatesFilterValue(e.value)
                        }}
                        className="p-mb-3"
                    />
                    <div className="p-d-flex p-jc-around">
                        <Button
                            label="Clear"
                            onClick={e=>{
                                datesOPanel.current.hide(e)
                                setDatesFilterValue("")
                                setDatesFilterMode("dateIs")
                                dt.current.filter("", "created_on", "dateIs")
                            }}
                            className="p-button-outlined p-button-sm"
                        />
                        <Button
                            label="Apply"
                            onClick={e=>{
                                datesOPanel.current.hide(e)
                                if (datesFilterValue) {
                                    dt.current.filter(datesFilterValue, "created_on", datesFilterMode)
                                } else {
                                    dt.current.filter("", "created_on", datesFilterMode)
                                }
                            }}
                            className="p-button-sm"
                        />
                    </div>
                </div>
            </OverlayPanel>

            <OverlayPanel ref={serversOPanel}>
                <div className="p-d-flex p-jc-center p-mb-3">
                    Servers Filter
                </div>
                <div>
                    <Dropdown
                        value={serversFilterMode}
                        options={stringFilterModes}
                        scrollHeight="300px"
                        onChange={e => {
                            setServersFilterMode(e.value)
                        }}
                        className="p-d-flex p-mb-3"
                    />
                    <InputText
                        placeholder={"Server search"}
                        value={serversFilterValue}
                        onChange={e => {
                            setServersFilterValue(e.target.value)
                        }}
                        className="p-mb-3"
                    />
                    <div className="p-d-flex p-jc-around">
                        <Button
                            label="Clear"
                            onClick={e=>{
                                serversOPanel.current.hide(e)
                                setServersFilterValue("")
                                setServersFilterMode("contains")
                                dt.current.filter("", "server_name", "contains")
                            }}
                            className="p-button-outlined p-button-sm"
                        />
                        <Button
                            label="Apply"
                            onClick={e=>{
                                serversOPanel.current.hide(e)
                                if (serversFilterValue) {
                                    dt.current.filter(serversFilterValue, "server_name", serversFilterMode)
                                } else {
                                    dt.current.filter("", "server_name", serversFilterMode)
                                }
                            }}
                            className="p-button-sm"
                        />
                    </div>
                </div>
            </OverlayPanel>
            <Tooltip />
        </div>
    )

}

export default Finishes