import React from "react"

import { Link } from "react-router-dom"

import { lightFormat } from "date-fns"

import { GetAPI_Server, GetAPI_Place } from "./APICalls"

export const colorPoints = (rowData) => {
    const points = rowData.points

    if (points===1000){
        return(<span className="points1000">{points}</span>)
    }
    if (points >= 900){
        return(<span className="points900">{points}</span>)
    }
    if (points >= 800) {
        return(<span className="points800">{points}</span>)
    }
    if (points >= 700) {
        return(<span className="points700">{points}</span>)
    }
    if (!points){
        return(0)
    }
    return(points)
}

export const playerLink = (rowData) => {
    return(
        <Link to={`/player/${rowData.steamid64}`}>
            {rowData.player_name}
        </Link>
    )
}

export const mapLink = (rowData) => {
    return(
        <Link to={`/maps/${rowData.map_name}`}>
            {rowData.map_name}
        </Link>
    )
}

export const serverLink = (rowData) => {
    return (
        <button className="table-link server-column-link" onClick={() => {
            GetAPI_Server(rowData.server_id)
                .then(response => {
                    window.location.replace(`steam://connect/${response.data.ip}:${response.data.port}`)
                })
                .catch(err => {
                    console.log("Error retrieving server data.", err)
                })
        }}>
            {rowData.server_name}
        </button>
    )
}

export const getTimeString = (time) => {
    const dateString = new Date(time * 1000).toISOString()

    if (time/86400 < 1) {
        return dateString.substr(11, 11)
    }

    const hours = Math.floor((time / (60 * 60))).toString()
    return `${hours.padStart(2)}${dateString.substr(13, 9)}`
}

export const mapTimeString = (rowData) => {
    return <span id={rowData.id}>{getTimeString(rowData.time)}</span>
}

export const mapTimeButton = (rowData, messageToast) => {
    return (
        <button id={rowData.id} className="table-link" onClick={() => {
            GetAPI_Place(rowData.id)
                .then(response => {
                    messageToast.current.show({severity: 'success', summary: `Run ${rowData.id}`, detail: `Place: ${response.data}`})
                })
                .catch(err => {
                    messageToast.current.show({severity: 'errow', summary: `Run ${rowData.id}`, detail: "An error occurred retrieving this run's place."})
                    console.log("Error retrieving record place.", err)
                })
        }}>
            {getTimeString(rowData.time)}
        </button>
    )
}

const localSettings = window.localStorage.getItem("localSettings")
const dateFormat = localSettings ? JSON.parse(localSettings).dateFormat : "yyyy-MM-dd"

export const formatDate = (rowData, useHours) => {
    return lightFormat(new Date(rowData.created_on), `${dateFormat}${useHours ? ", hh:mm:ss" : ""}`)
}

export const invertedSort = (event, dt) => {
    let value = dt.current.props.value
    value.sort((data1, data2) => {
        let value1 = data1[event.field]
        let value2 = data2[event.field]
        let result = null

        if (value1 == null && value2 != null)
            result = -1
        else if (value1 != null && value2 == null)
            result = 1
        else if (value1 == null && value2 == null)
            result = 0
        else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2)
        else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0

        return (-event.order * result)
    })
    return value
}