import React, { createContext, useContext } from "react"

import { useSearchOrStorage } from "../hooks/useSearchOrStorage"

export const GameModeID = {
    kz_timer: 200,
    kz_simple: 201,
    kz_vanilla: 202
}

export const GameModeName = {
    200: "kz_timer",
    201: "kz_simple",
    202: "kz_vanilla"
}

//See MapsContext.js for explanation of use
//I need this context to change the gameMode without reloading the page, as localStore is only read once.
export const GameModeContext = createContext()

export const GameModeProvider = ({children}) => {

    const [gameMode, setGameMode] = useSearchOrStorage('mode', 'kz_timer')
    const [runType, setRunType] = useSearchOrStorage('run', 'pro')

    return (
        <GameModeContext.Provider value={{gameMode, runType, setGameMode, setRunType}}>
            { children }
        </GameModeContext.Provider>
    )

}

//Hooks for easier access.
export function useGameModeContext() {
    const context = useContext(GameModeContext)
    if (context === undefined) {
        throw new Error("Context must be used within a Provider")
    }
    return [context.gameMode, context.setGameMode]
}

export function useRunTypeContext() {
    const context = useContext(GameModeContext)
    if (context === undefined) {
        throw new Error("Context must be used within a Provider")
    }
    return [context.runType, context.setRunType]
}