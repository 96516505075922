import React, { useState, useEffect, useContext, useCallback, Suspense } from "react"

import "./Player.css"

import {
    Switch,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    useParams
} from "react-router-dom"

import { useLocalStorage } from "../../hooks/useLocalStorage"
import { useLocalSettings } from "../../hooks/useLocalSettings"
import { MapsContext, useGlobalMaps } from "../../context/MapsContext"
import { GameModeContext, GameModeID } from "../../context/GameModeContext"
import { FirebaseContext } from "../../context/FirebaseContext"
import { GetSteam_Profile } from '../../Utils/APICalls'
import { GetSteam64 } from "../../Utils/SteamID"

import ModeChooser from "../Essentials/ModeChooser"
import BannerBackground from "../Essentials/BannerBackground"
import PlayerPanel from "./Panels/PlayerPanel"
import InformationGrid from "./InformationGrid"
import CompletionsPanel from "./Panels/CompletionsPanel"
import VideoPanel from "./Panels/VideoPanel"
import ImagePanel from "./Panels/ImagePanel"
//import MapperPanel from "./Panels/MapperPanel"
import Finishes from "./Finishes"
import Unfinishes from "./Unfinishes"
import Jumpstats from "./Jumpstats"

function Player ({ messageToast, Achievements, Settings }) {

    const [steamProfileData] = useLocalStorage("steamProfileData", null)
    const [localSettings] = useLocalSettings()

    //React Router Hooks
    let match = useRouteMatch()
    let { steamid } = useParams()
    steamid = GetSteam64(steamid)
    let jumpstatsMatch = useRouteMatch("/player/:steamid/jumpstats/:jumpstat")

    const {gameMode, runType} = useContext(GameModeContext)

    const globalMaps = useGlobalMaps()
    const { getGlobalFilters, getAPIPlayer, getPlayerTimes } = useContext(MapsContext)

    const { getFirestoreUser } = useContext(FirebaseContext)

    const [loadingData, setLoadingData] = useState(true)
    const [mapFilters, setMapFilters] = useState([])
    const [playerFinishesPRO, setPlayerFinishesPRO] = useState([])
    const [playerFinishesTP, setPlayerFinishesTP] = useState([])

    //Useful information for Player's children components gathered in one loop done after maps and finishes data arrived
    //Finished Maps Table
    const [finishedMaps, setFinishedMaps] = useState([])
    const [finishedBonuses, setFinishedBonuses] = useState([])
    //Unfinished Maps Table
    const [unfinishedMaps, setUnfinishedMaps] = useState([])
    //Player Profile Info
    const [playerName, setPlayerName] = useState("")
    const [playerNationality, setPlayerNationality] = useState(undefined)
    const [playerAvatar, setPlayerAvatar] = useState("")
    const [playerBanner, setPlayerBanner] = useState(undefined)
    const [playerColor, setPlayerColor] = useState()
    const [playerPanel, setPlayerPanel] = useState()
    const [gridPanels, setGridPanels] = useState([])
    const [playerRoles, setPlayerRoles] = useState([])
    const [playerSocials, setPlayerSocials] = useState({})
    //Completions
    const [mapsByTier, setMapsByTier] = useState([0,0,0,0,0,0,0])
    const [mapsTotal, setMapsTotal] = useState(0)
    const [completedMapsByTier, setCompletedMapsByTier] = useState([0,0,0,0,0,0,0])
    const [completedMapsTotal, setCompletedMapsTotal] = useState(0)
    //Points
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsAvg, setPointsAvg] = useState("0.00")
    const [points1000s, setPoints1000s] = useState(0)
    const [points900s, setPoints900s] = useState(0)
    const [points800s, setPoints800s] = useState(0)
    //Rank
    const [playerRank, setPlayerRank] = useState("NEWBIE")

    //Jumpstats
    const [currentJumpstat, setCurrentJumpstat] = useState({}) //to check if I have to fetch again
    const [jumpstatData, setJumpstatData] = useState([])

    //GET PLAYER'S STEAM AND API DATA
    useEffect(()=>{
        //loading is set in "fetchPlayerFinishes" as it has more dependencies including steamid
        window.scrollTo(0, 0)

        //KZ API - PLAYER DATA
        getAPIPlayer(steamid)
            .then(response => {
                if (!response.data[0].name) {
                    //This player has never played KZ.
                    messageToast.current.show({severity: 'error', summary: 'smh. 😒', detail: 'This player has never played kz.', sticky: true})
                } else if (response.data[0].is_banned === true) {
                    //This player has been banned from global API.
                    messageToast.current.show({severity: 'error', summary: 'smh. 😒', detail: 'This player has been banned from the global API.', sticky: true})
                }
            })
            .catch(() => {
                messageToast.current.show({severity: 'error', summary: 'Global API', detail: `An error occurred with the global API.`, sticky: true})
            })

        //STEAM + FIRESTORE PLAYER DATA
        //I use allSettled instead of all because I want people to still be able to see players' profiles when Firestore reaches daily free limit.
        Promise.allSettled([
            GetSteam_Profile([steamid]),
            getFirestoreUser(steamid)
        ])
        .then(responses=>{

            let player_name = ""
            let player_nationality = ""
            let player_avatar = ""

            //First we set everything with Steam data, then we'll overwrite with Firestore data if there's some
            if (responses[0].status === "fulfilled") {
                const steamPlayerData = responses[0].value.data.response.players[0]
                if (steamPlayerData) {
                    player_name = steamPlayerData.personaname
                    player_nationality = steamPlayerData.loccountrycode
                    player_avatar = steamPlayerData.avatarfull
                } else {
                    //Steam didn't return any player. This player doesn't even exist!
                    messageToast.current.show({severity: 'error', summary: 'Uhh...', detail: `This player doesn't even exist!`, sticky: true})
                }
            } else if (responses[0].status === "rejected") {
                messageToast.current.show({severity: 'error', summary: 'Steam', detail: `An error occurred with Steam's backend.`, sticky: true})
            }

            //Now we overwrite with Firebase data and set the rest
            if (responses[1].status === "fulfilled") {
                const firestoreUserData = responses[1].value
                if (firestoreUserData) {
                    //Name
                    if (firestoreUserData.name) {
                        player_name = firestoreUserData.name
                    }
                    //Nationality
                    if (firestoreUserData.loc) {
                        player_nationality = firestoreUserData.loc
                    }
                    //Avatar
                    if (firestoreUserData.avatar) {
                        const matches = /^([a-z]{2})([1-6]{1})([1-6]{1})\/?([\w-]{1,})?$/.exec(firestoreUserData.avatar)
                        if (matches[1] === "im") {
                            player_avatar = `https://i.imgur.com/${matches[4]}.jpg`
                        } else if (matches[1] === "gi") {
                            player_avatar = `https://media.giphy.com/media/${matches[4]}/giphy.gif`
                        }
                    }
                    //Banner
                    setPlayerBanner(firestoreUserData.banner) //if null no banner will be shown, see BannerBackground component.
                    //Color
                    setPlayerColor(firestoreUserData.color ? `#${firestoreUserData.color}` : "#448AFF")
                    //Main panel
                    setPlayerPanel(firestoreUserData.panel ? firestoreUserData.panel : "co22")
                    //Information panels
                    setGridPanels(firestoreUserData.panels)
                    //Roles
                    setPlayerRoles(firestoreUserData.roles ? firestoreUserData.roles : [])
                    //Socials
                    let firestorePlayerSocials = {
                        s: `https://steamcommunity.com/profiles/${steamid}/`
                    }
                    if (firestoreUserData.socials) {
                        firestorePlayerSocials.t = firestoreUserData.socials.t ?  `https://twitch.tv/${firestoreUserData.socials.t}` : null
                        firestorePlayerSocials.d = firestoreUserData.socials.d ? `https://discord.com/invite/${firestoreUserData.socials.d}` : null
                        firestorePlayerSocials.y = firestoreUserData.socials.y ? `https://youtube.com/${firestoreUserData.socials.y}` : null
                    }
                    setPlayerSocials(firestorePlayerSocials)
                } else {
                    //Default values for players without any Firestore data at all
                    setPlayerBanner(undefined)
                    setPlayerColor("#448AFF")
                    setPlayerPanel("co22")
                    setGridPanels(undefined)
                }
            } else if (responses[1].status === "rejected") {
                messageToast.current.show({severity: 'error', summary: 'KZ Profile', detail: `An error occurred with KZ Profile's backend.`, sticky: true})
            }

            //At the end we set state of the data that could have been overwritten
            setPlayerName(player_name)
            document.title = `${player_name} - KZ Profile`
            setPlayerNationality(player_nationality)
            setPlayerAvatar(player_avatar)
        })

    },[steamid, messageToast, getFirestoreUser, getAPIPlayer])

    //GET MAP FILTERS
    useEffect(()=>{
        //loading is set in "fetchPlayerFinishes" as it has more dependencies including gameMode
        //kzpro filters appear in has_teleports=true right now, so just fetch has_teleports=false and filter kzpro maps.
        getGlobalFilters(GameModeID[gameMode])
            .then(response => {
                setMapFilters(response)
            })
            .catch(err => {
                console.log(err)
                setLoadingData(false)
            })
    },[gameMode, getGlobalFilters])

    //This function only gets recreated when steamid and gameMode changes. When it's recreated, the useEffect below will notice it and
    //call it, hence the data starts fetching. This allows me to call this function from the Refresh Button.
    const fetchPlayerFinishes = useCallback((refresh) => {
        messageToast.current.clear()
        setLoadingData(true)
        getPlayerTimes(steamid, gameMode, refresh)
            .then(results => {
                setPlayerFinishesPRO(results[0])
                setPlayerFinishesTP(results[1])
            })
            .catch(err => {
                console.log(err)
                setLoadingData(false)
            })
    }, [steamid, gameMode, messageToast, getPlayerTimes])

    //Each time the steamid or gamemode changes, fetchPlayerFinishes is recreated. This useEffect acknowledges the recreation and calls it
    //This allows me to call the function from other places like the Refresh Button
    useEffect(() => {
        fetchPlayerFinishes(false)
    }, [fetchPlayerFinishes])

    //Only when ALL data has arrived, start processing it.
    useEffect(() => {
        if (globalMaps.length &&
            mapFilters.length &&
            mapFilters[0].mode_id === GameModeID[gameMode] &&
            (playerFinishesPRO.status === 200 && playerFinishesPRO.config.params.modes_list_string === gameMode && playerFinishesPRO.config.params.steamid64 === steamid) &&
            (playerFinishesTP.status === 200 && playerFinishesTP.config.params.modes_list_string === gameMode && playerFinishesTP.config.params.steamid64 === steamid))
        {
            //Gather all the information necessary for all Player's children components here for better performance (one loop)
            //These are temporary variables to update inside the loop. Set states will only be called once we have the final value.
            let finished_maps = []
            let finished_bonuses = []
            let unfinished_maps = []
            let maps_by_tier = [0,0,0,0,0,0,0]
            let maps_total = 0
            let completed_maps_by_tier = [0,0,0,0,0,0,0]
            let completed_maps_total = 0
            let points_total = 0
            let points_avg = 0
            let points_1000s = 0
            let points_900s = 0
            let points_800s = 0
            let player_rank = "NEWBIE"

            //Inside useEffect to prevent infinite loop
            const RANKING = [
                "NEWBIE",
                "NEWBIE+",
                "SCRUB",
                "SCRUB+",
                "TRAINEE",
                "TRAINEE+",
                "CASUAL",
                "CASUAL+",
                "REGULAR",
                "REGULAR+",
                "SKILLED",
                "SKILLED+",
                "EXPERT",
                "EXPERT+",
                "SEMIPRO",
                "SEMIPRO+",
                "PRO",
                "PRO+",
                "DEMIGOD",
                "GOD"
            ]

            if ((runType === "pro" && !playerFinishesPRO.data.length) ||
                (runType === "tp" && !playerFinishesTP.data.length) ||
                (runType === "nub" && !playerFinishesPRO.data.length && !playerFinishesTP.data.length))
            {
                messageToast.current.show({severity: 'warn', summary: 'No times found.', detail: 'This player has 0 runs in this game mode and run type.', life: 5000})
            } else {
                //Player has completions, start analyzing

                //extract bonuses. Can't do inside loop because it messes with unfinished maps
                if (runType === "pro") {
                    finished_bonuses = playerFinishesPRO.data.filter(finish => 
                        finish.stage !== 0
                    )
                } else if (runType === "tp") {
                    finished_bonuses = playerFinishesTP.data.filter(finish => 
                        finish.stage !== 0
                    )
                } else if (runType === "nub") {
                    //Sikari structure
                    const pro_finished_bonuses = playerFinishesPRO.data.filter(finish => 
                        finish.stage !== 0
                    )
                    let all_finished_bonuses = pro_finished_bonuses.concat(playerFinishesTP.data.filter(finish => 
                        finish.stage !== 0
                    )).sort((a, b) => a.time - b.time)
                  
                    const mapStageCache = new Map()
                  
                    all_finished_bonuses.forEach(finish => {
                      const key = `${finish.map_id}_${finish.stage}`
                      
                      if (!mapStageCache.has(key)) {
                        finished_bonuses.push(finish)
                        mapStageCache.set(key, true)
                      }
                    })
                }

                for (let i = 0; i < globalMaps.length; i++) {

                    const globalMap = globalMaps[i]

                    if (globalMap.name.startsWith("kzpro_") && runType === "tp") {
                        continue
                    }
                    
                    //For total POSSIBLE completions, find the filter
                    const filterFound = mapFilters.find(filter => 
                        globalMap.id === filter.map_id && filter.stage === 0
                    )

                    if (filterFound) {
                        maps_total ++
                        maps_by_tier[globalMap.difficulty - 1] ++
                    } else {
                        continue
                    }
                    
                    //For total COMPLETED maps, find the finish
                    
                    //Depending on run Type, we'll iterate through different data:
                    let mapFound = null

                    if (runType === "pro") {
                        mapFound = playerFinishesPRO.data.find(finish => 
                            globalMap.id === finish.map_id && finish.stage === 0
                        )
                    } else if (runType === "tp") {
                        mapFound = playerFinishesTP.data.find(finish => 
                            globalMap.id === finish.map_id && finish.stage === 0
                        )
                    } else if (runType === "nub") {
                        const proMapFound = playerFinishesPRO.data.find(finish => 
                            globalMap.id === finish.map_id && finish.stage === 0
                        )
                        const tpMapFound = playerFinishesTP.data.find(finish => 
                            globalMap.id === finish.map_id && finish.stage === 0
                        )
                        if (!proMapFound) {
                            mapFound = tpMapFound
                        } else if (!tpMapFound) {
                            mapFound = proMapFound
                        } else {
                            mapFound = proMapFound.time <= tpMapFound.time ? proMapFound : tpMapFound
                        }
                    }

                    if (mapFound) {
                        finished_maps.push({...mapFound, difficulty: globalMap.difficulty})
    
                        completed_maps_total ++
                        completed_maps_by_tier[globalMap.difficulty - 1] ++
                        
                        points_total += mapFound.points
                        if (mapFound.points === 1000){
                            points_1000s ++
                        } else if (mapFound.points >= 900) {
                            points_900s ++
                        } else if (mapFound.points >= 800) {
                            points_800s ++
                        }
    
                    } else {
                        unfinished_maps.push({
                            map_name: globalMap.name,
                            difficulty: globalMap.difficulty,
                            created_on: globalMap.created_on
                        })
                    }
                }

                //Rank
                //The rank is bassically dependant on avg points, but if you have less than 50k points, your rank will be decreased by an ease cubic function
                let promedio = points_total/50000
                let easeOutCubic = (1 - (Math.pow(1 - promedio, 3)))
                if (easeOutCubic > 1) {
                    easeOutCubic = 1
                }
                points_avg = completed_maps_total !== 0 ? points_total/completed_maps_total : 0 //to prevent divided by zero errors
                player_rank = RANKING[(Math.floor((points_avg * easeOutCubic) * 0.02))]
            }

            //Now we update states with our temporary variables
            setFinishedMaps(finished_maps)
            setFinishedBonuses(finished_bonuses)
            setUnfinishedMaps(unfinished_maps)
            setMapsByTier(maps_by_tier)
            setMapsTotal(maps_total)
            setCompletedMapsByTier(completed_maps_by_tier)
            setCompletedMapsTotal(completed_maps_total)
            setPointsTotal(points_total)
            setPointsAvg(points_avg.toFixed(2))
            setPoints1000s(points_1000s)
            setPoints900s(points_900s)
            setPoints800s(points_800s)
            setPlayerRank(player_rank)

            setLoadingData(false)
            
        }
    }, [globalMaps, mapFilters, gameMode, playerFinishesPRO, playerFinishesTP, runType, steamid, messageToast])

    const PanelDecoder = (panelID, key) => {
        const matches = /^([a-z]{2})([1-6]{1})([1-6]{1})\/?([\w-]{1,})?$/.exec(panelID)
        const panelType  = matches[1]
        const rows = key ? matches[2] : undefined //In each panel, I check if rows and columns exist to conditionally render a grid or a standalone panel
        const columns = key ? matches[3] : undefined
        const panelValue = matches[4]

        //If there's a key, the panel is for the information grid. If not, is just a standalone panel.

        //Completions
        if (panelType === "co") {
            return (
                <CompletionsPanel
                    mapsByTier={mapsByTier}
                    mapsTotal={mapsTotal}
                    completedMapsByTier={completedMapsByTier}
                    completedMapsTotal={completedMapsTotal}
                    rows={rows}
                    columns={columns}
                    key={key}
                />
            )
        }

        //Imgur
        if (panelType === "im") {
            return (
                <ImagePanel
                    imageID={`https://i.imgur.com/${panelValue}.jpg`}
                    rows={rows}
                    columns={columns}
                    key={key}
                />
            )
        }
        
        //Giphy
        if (panelType === "gi") {
            return (
                <ImagePanel
                    imageID={`https://media.giphy.com/media/${panelValue}/giphy.gif`}
                    rows={rows}
                    columns={columns}
                    key={key}
                />
            )
        }
        
        //Youtube
        if (panelType === "yt") {
            return (
                <VideoPanel
                    videoSource={`https://www.youtube.com/embed/${panelValue}`}
                    rows={rows}
                    columns={columns}
                    key={key}
                />
            )
        }
        
        //Twitch Clip
        if (panelType === "tc") {
            return (
                <VideoPanel
                    videoSource={`https://clips.twitch.tv/embed?clip=${panelValue}&parent=${window.location.hostname}&autoplay=false`}
                    rows={rows}
                    columns={columns}
                    key={key}
                />
            )
        }
        
        //Twitch Video
        if (panelType === "tv") {
            return (
                <VideoPanel
                    videoSource={`https://player.twitch.tv/?video=${panelValue}&parent=${window.location.hostname}&autoplay=false`}
                    rows={rows}
                    columns={columns}
                    key={key}
                />
            )
        }
        
        //Twitch Channel
        if (panelType === "tw") {
            return (
                <VideoPanel
                    videoSource={`https://player.twitch.tv/?channel=${panelValue}&parent=${window.location.hostname}&muted=true&autoplay=${steamProfileData?.steamid === steamid ? "false" : "true"}`}
                    rows={rows}
                    columns={columns}
                    key={key}
                />
            )
        }

        //Bilibili Video
        if (panelType === "bi") {
            return (
                <VideoPanel
                    videoSource={`https://player.bilibili.com/player.html?bvid=${panelValue}`}
                    rows={rows}
                    columns={columns}
                    key={key}
                />
            )
        }
    }

    return (
        <div>
            <div className="playerBackground">
                <BannerBackground mapID={playerBanner} />
                <ModeChooser />
                <div className="p-d-flex p-jc-around p-ai-center p-flex-wrap" style={{margin:"1.5rem 0"}}>
                    <div style={{marginBottom:"2.5rem", marginTop:"auto"}}>
                        <PlayerPanel
                            playerName={playerName}
                            playerID={steamid}
                            playerNationality={playerNationality}
                            playerAvatar={playerAvatar}
                            playerRank={playerRank}
                            pointsTotal={pointsTotal}
                            pointsAvg={pointsAvg}
                            points1000s={points1000s}
                            points900s={points900s}
                            points800s={points800s}
                            playerRoles={playerRoles}
                            playerSocials={playerSocials}
                        />
                    </div>
                    <div className="banner-panel">
                        {playerPanel && PanelDecoder(playerPanel)}
                    </div>
                </div>
                <div>
                    <ul className="tab-menu">
                        <li className="tab-menu-item">
                            <NavLink
                                className="tab-menu-link"
                                exact to={match.url}
                                activeStyle={{boxShadow: `inset 0px -0.25rem 0px 0px ${playerColor}`, color: playerColor, transition: "none"}}
                            >
                                <span className="tab-menu-icon pi pi-info-circle"></span>
                                <span className="tab-menu-text p-d-none p-d-md-inline-flex">Information</span>
                            </NavLink>
                        </li>
                        <li className="tab-menu-item">
                            <NavLink
                                className="tab-menu-link"
                                to={`${match.url}/finishes`}
                                activeStyle={{boxShadow: `inset 0px -0.25rem 0px 0px ${playerColor}`, color: playerColor, transition: "none"}}
                            >
                                <span className="tab-menu-icon pi pi-align-justify"></span>
                                <span className="tab-menu-text p-d-none p-d-md-inline-flex">Finishes</span>
                            </NavLink>
                        </li>
                        <li className="tab-menu-item">
                            <NavLink
                                className="tab-menu-link"
                                to={`${match.url}/jumpstats/${currentJumpstat.steamid === steamid ? currentJumpstat.jumpstat : "longjump"}`}
                                isActive={()=>{
                                    return jumpstatsMatch !== null
                                }}
                                activeStyle={{boxShadow: `inset 0px -0.25rem 0px 0px ${playerColor}`, color: playerColor, transition: "none"}}
                            >
                                <span className="tab-menu-icon pi pi-chart-line"></span>
                                <span className="tab-menu-text p-d-none p-d-md-inline-flex">Jumpstats</span>
                            </NavLink>
                        </li>
                        <li className="tab-menu-item">
                            <NavLink
                                className="tab-menu-link"
                                to={`${match.url}/stats`}
                                activeStyle={{boxShadow: `inset 0px -0.25rem 0px 0px ${playerColor}`, color: playerColor, transition: "none"}}
                            >
                                <span className="tab-menu-icon pi pi-chart-bar"></span>
                                <span className="tab-menu-text p-d-none p-d-md-inline-flex">Stats</span>
                            </NavLink>
                        </li>
                        <li className="tab-menu-item">
                            <NavLink
                                className="tab-menu-link"
                                to={`${match.url}/achievements`}
                                activeStyle={{boxShadow: `inset 0px -0.25rem 0px 0px ${playerColor}`, color: playerColor, transition: "none"}}
                            >
                                <span className="tab-menu-icon pi pi-unlock"></span>
                                <span className="tab-menu-text p-d-none p-d-md-inline-flex">Achievements</span>
                            </NavLink>
                        </li>
                        {steamProfileData && steamProfileData.steamid === steamid &&
                        <li className="tab-menu-item">
                            <NavLink
                                className="tab-menu-link"
                                to={`${match.url}/settings`}
                                activeStyle={{boxShadow: `inset 0px -0.25rem 0px 0px ${playerColor}`, color: playerColor, transition: "none"}}
                            >
                                <span className="tab-menu-icon pi pi-cog"></span>
                                <span className="tab-menu-text p-d-none p-d-md-inline-flex">Settings</span>
                            </NavLink>
                        </li>}
                    </ul>
                </div>
            </div>
            <div>
                <Switch>
                    <Route exact path={match.path} >
                        {gridPanels ?
                        <InformationGrid gridPanels={gridPanels} PanelDecoder={PanelDecoder} /> :
                        <div className="p-text-center" style={{color:"rgba(255,255,255,0.6)"}}>
                            <p>No information given.</p>
                            {steamProfileData && steamid === steamProfileData.steamid &&
                            <p>Go get some panels in the <Link to="/store">Store</Link>!</p>
                            }
                        </div>
                        }
                    </Route>
                    <Route path={`${match.path}/finishes`} >
                        <div className="p-d-flex p-jc-evenly p-flex-wrap p-pl-0 p-pl-sm-2 p-pr-0 p-pr-sm-2" style={{margin:"0", minHeight:"100vh", padding:"0.5rem"}}>
                            <div className="p-pl-0 p-pl-sm-2 p-pr-0 p-pr-sm-2" style={{flex:"2", padding:"0.5rem", width:"100%"}}>
                                <Finishes
                                    finishedMaps={finishedMaps}
                                    finishedBonuses={finishedBonuses}
                                    fetchPlayerFinishes={fetchPlayerFinishes}
                                    defaultSortOrder={
                                        steamProfileData && steamProfileData.steamid === steamid ? localSettings.defaultSortOrderYours : localSettings.defaultSortOrderOthers
                                    }
                                    dateFormat={localSettings.dateFormat}
                                    loading={loadingData}
                                    messageToast={messageToast}
                                />
                            </div>
                            <div className="p-pl-0 p-pl-sm-2 p-pr-0 p-pr-sm-2" style={{flex:"1", padding:"0.5rem", width:"100%"}}>
                                <Unfinishes
                                    unfinishedMaps={unfinishedMaps}
                                    fetchPlayerFinishes={fetchPlayerFinishes}
                                    dateFormat={localSettings.dateFormat}
                                    loading={loadingData}
                                />
                            </div>
                        </div>
                    </Route>
                    <Route path={`${match.path}/jumpstats/:jumpstat`} >
                        <div style={{paddingTop:"1rem", minHeight:"100vh"}}>
                            <Jumpstats
                                steamid={steamid}
                                currentJumpstat={currentJumpstat}
                                setCurrentJumpstat={setCurrentJumpstat}
                                jumpstatData={jumpstatData}
                                setJumpstatData={setJumpstatData}
                            />
                        </div>
                    </Route>
                    <Route path={`${match.path}/stats`} >
                        Stats
                    </Route>
                    <Route path={`${match.path}/achievements`} >
                        <Suspense fallback={<></>}>
                            <Achievements />
                        </Suspense>
                    </Route>
                    {steamProfileData && steamProfileData.steamid === steamid &&
                    <Suspense fallback={<></>}>
                        <Route path={`${match.path}/settings`} >
                            <Settings
                                messageToast={messageToast}
                            />
                        </Route>
                    </Suspense>
                    }
                </Switch>
            </div>
        </div>
    )
}

export default Player