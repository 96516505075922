import React, { useState, useRef } from "react"

import "./MapRanking.css"

import { useRunTypeContext } from "../../../context/GameModeContext"

import { colorPoints, mapTimeString, serverLink, formatDate, invertedSort, playerLink } from "../../../Utils/TableRows"

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

function MapRanking ({ loading, mapTimesData, steamid, fetchInitialData, loadMore, tableTitle }) {
    const [runType] = useRunTypeContext()

    const dt = useRef(null)
    const [globalFilter, setGlobalFilter] = useState("")
    const [first, setFirst] = useState({pro: 0, tp: 0, nub: 0})
    const [rows, setRows] = useState(100)

    const tableTop = (
        <div className="p-d-flex p-ai-center" style={{height:"100%"}}>
            <div className="tableTopItem">
                <Button
                    icon="pi pi-refresh"
                    className="p-button-rounded p-button-text p-button-white"
                    onClick={e => {
                        fetchInitialData(true)
                    }}
                    tooltip="Refresh data"
                    tooltipOptions={{ position: 'top', showDelay: 500}}
                />
            </div>
            <div className="tableTopItem p-text-center">{tableTitle}</div>
            <div className="tableTopItem p-text-right">
                <span className="p-input-icon-right">
                    <InputText className="p-inputtext-sm" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" type="search" style={{width:"100%"}} />
                </span>
            </div>
        </div>
    )

    const tableFooter = (
        //if it's not divisible by 100 it means that we've reached the end. The last condition is to show it only on last page.
        //Only flaw is when a map has exactly 100 or a multiple of 100 amount of runs. There might be a cleaner solution.
        mapTimesData && globalFilter === "" && mapTimesData.length % 100 === 0 && first[runType] === mapTimesData.length - rows &&
        <div className="p-d-flex p-jc-center">
            <Button
                label="+ LOAD MORE"
                className="p-button-text load-more-button"
                loading={loading}
                onClick={e => {
                    loadMore(mapTimesData.length)
                    setFirst(oldFirst => {
                        return {...oldFirst, [runType]: oldFirst[runType] + 100}
                    })
                }}
            >
            </Button>
        </div>
    )

    return (
        <div className="p-d-flex p-jc-center">
            <DataTable 
                className="p-datatable-sm infinite-table"
                style={{maxWidth:"1140px"}}
                rowClassName={(rowData) => {
                    return {"highlight-row": rowData.steamid64 === steamid}
                }}
                value={mapTimesData}
                header={tableTop}
                footer={tableFooter}
                ref={dt}
                globalFilter={globalFilter}
                sortMode="multiple"
                multiSortMeta={[{field: 'time', order: 1}]}
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rows={rows}
                rowsPerPageOptions={[20,50,100]}
                rowHover={true}
                emptyMessage="No records found."
                //scrollable
                loading={loading}
                first={first[runType]}
                onPage={(e) => {
                    setFirst(oldFirst => {
                        return {...oldFirst, [runType]: e.first}
                    })
                    setRows(e.rows)
                }}
                //filters={localFilters}
                //onFilter={(e) => setLocalFilters(e.filters)}
                //frozenWidth="136px"
            >
                <Column 
                    header="#"
                    field="place"
                    headerStyle={{width:"34px"}}>
                </Column>
                <Column 
                    field="player_name"
                    header="Player"
                    body={playerLink}
                    className="p-text-nowrap p-text-truncate p-text-left"
                    headerStyle={{width:"136px"}}
                    //frozen
                    sortable>
                </Column>
                <Column 
                    field="time"
                    header="Time"
                    body={mapTimeString}
                    headerStyle={{width:"136px"}}
                    sortable>
                </Column>
                <Column 
                    field="points"
                    header="Points"
                    body={colorPoints}
                    headerStyle={{width:"136px"}}
                    sortable
                    sortFunction={e => invertedSort(e, dt)}>
                </Column>
                <Column 
                    field="teleports"
                    header="TPs"
                    headerStyle={{width:"136px"}}
                    sortable>
                </Column>
                <Column 
                    field="created_on"
                    header="Date"
                    body={rowData => formatDate(rowData)}
                    headerStyle={{width:"136px"}}
                    sortable
                    sortFunction={e => invertedSort(e, dt)}>
                </Column>
                <Column 
                    field="server_name"
                    header="Server"
                    body={serverLink}
                    className="p-text-nowrap p-text-truncate p-text-left"
                    headerStyle={{width:"136px"}}
                    sortable>
                </Column>
            </DataTable>
        </div>
    )
}

export default MapRanking