import React, { useState, useEffect, useCallback } from "react"

import { useSearchParams } from "../../hooks/useSearchParams"
import { GetAPI_Jumpstats } from '../../Utils/APICalls'
import JumpstatColor, { jumpClubs } from '../../Utils/JumpstatColor'

import { useParams, useHistory, useRouteMatch } from "react-router-dom"

import { formatDate } from "../../Utils/TableRows"

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import {Checkbox} from 'primereact/checkbox'

function Jumpstats ({ steamid, currentJumpstat, setCurrentJumpstat, jumpstatData, setJumpstatData }) {
    //for testing: julianio's id: 76561197998450788
    let { jumpstat } = useParams()
    const history = useHistory()
    const match = useRouteMatch()

    const [loadingJumpstats, setLoadingJumpstats] = useState(false)

    const [first, setFirst] = useState(0)
    const [rows, setRows] = useState(50)

    const [crouchbind, setCrouchbind] = useSearchParams("crouchbind", currentJumpstat.crouchbind !== undefined ? currentJumpstat.crouchbind : true)

    const [dropdownValue, setDropdownValue] = useState(jumpstat)
    const dropdownItems = [
        {label: 'Longjump', value: 'longjump'},
        {label: 'Bhop', value: 'bhop'},
        {label: 'Multibhop', value: 'multibhop'},
        {label: 'Ladderjump', value: 'ladderjump'},
        {label: 'Weirdjump', value: 'weirdjump'},
        {label: 'Dropbhop', value: 'dropbhop'},
        {label: 'Countjump', value: 'countjump'}
    ]

    const fetchJumpstatData = useCallback((offset, refresh) => {
        if (currentJumpstat.steamid !== steamid || currentJumpstat.jumpstat !== jumpstat || currentJumpstat.crouchbind !== crouchbind || refresh || offset >= 50) {
            setLoadingJumpstats(true)

            GetAPI_Jumpstats(steamid, jumpstat, crouchbind, offset)
                .then(result => {
                    if (offset === 0){
                        setJumpstatData([]) //to reset rgb animation of first rows, yes, it's stupid
                    }
                    setJumpstatData(oldData => {
                        return (offset === 0 ? result.data : oldData.concat(result.data))
                    })
                    setCurrentJumpstat({
                        steamid: steamid,
                        jumpstat: jumpstat,
                        crouchbind: crouchbind
                    })
                    
                    setLoadingJumpstats(false)
                })
                .catch(err => {
                    console.log(err)
                    setLoadingJumpstats(false)
                })
        }
    },[steamid, jumpstat, crouchbind, currentJumpstat, setCurrentJumpstat, setJumpstatData])

    useEffect(()=>{
        fetchJumpstatData(0)
    },[fetchJumpstatData])

    const tableTop = (
        <div className="p-d-flex p-ai-center" style={{height:"100%"}}>
            <div className="tableTopItem">
                <Button
                    icon="pi pi-refresh"
                    className="p-button-rounded p-button-text p-button-white"
                    onClick={e => {
                        fetchJumpstatData(0, true)
                    }}
                    tooltip="Refresh data"
                    tooltipOptions={{ position: 'top', showDelay: 500}}
                />
            </div>
            <div className="tableTopItem p-text-center">
                <Dropdown
                    value={dropdownValue}
                    options={dropdownItems}
                    onChange={(e) => {
                        setDropdownValue(e.value)
                        if (e.value === "ladderjump") {
                            setCrouchbind(false)
                        }
                        history.push(`/player/${match.params.steamid}/jumpstats/${e.value}`)
                    }}
                    placeholder="Select a Jumpstat"
                    style={{background:"none", border:"none", marginTop:"-0.375rem", marginBottom:"-0.375rem"}}
                />
            </div>
            <label className="tableTopItem p-text-right">
                <span className="p-mr-2 p-d-none p-d-sm-inline-block">Crouchbind</span>
                <span className="p-mr-2 p-d-sm-none p-d-inline-block">CJ</span>
                <Checkbox className="p-mr-2" onChange={e => setCrouchbind(e.checked)} checked={crouchbind}></Checkbox>
            </label>
        </div>
    )

    const jumptier = (rowData) => {
        let tier = JumpstatColor(rowData.distance, rowData.jump_type, rowData.is_crouch_boost === 1 ? true : false)
        if (tier === "rgb-effect") {
            tier = `${rowData.is_crouch_boost === 1 ? jumpClubs[rowData.jump_type - 1].bind: jumpClubs[rowData.jump_type - 1].unbind} CLUB`
        }
        return (
            <span style={{textTransform:"capitalize"}}>
                {tier ? tier : "Meh"}
            </span>
        )
    }

    const stylizedDistance = (rowData) => {
        const jumpTier = JumpstatColor(rowData.distance, rowData.jump_type, rowData.is_crouch_boost === 1 ? true : false)
        return (
            <span className={jumpTier !== "rgb-effect" ? jumpTier : ""}>
                {rowData.distance.toLocaleString(undefined, { minimumFractionDigits: 4 })}
            </span>
        )
    }

    const tableFooter = (
        //if it's not divisible by 50 it means that we've reached the end. The last condition is to show it only on last page.
        //Only flaw is when a map has exactly 50 or a multiple of 50 amount of runs. There might be a cleaner solution.
        jumpstatData.length % 50 === 0 && first === jumpstatData.length - rows ?
        <div className="p-d-flex p-jc-center">
            <Button
                label="+ LOAD MORE"
                className="p-button-text load-more-button"
                loading={loadingJumpstats}
                onClick={e => {
                    fetchJumpstatData(jumpstatData.length)
                }}
            >
            </Button>
        </div> :
        null
    )

    useEffect(()=>{
        //if we haven't reached the end of the data, then 50 times have been added. Show from the first one of those 50 new ones.
        if (jumpstatData.length % 50 === 0 && jumpstatData.length >= 50) {
            setFirst(jumpstatData.length - 50)
        } else { //when we reach the end of the ranking, then instead of subtracting 50, we subtract whatever amount of times have been added.
            setFirst(jumpstatData.length - jumpstatData.length % 50)
        }
        //This can also be done by calculating how many new jumps we've gotten with the use of a ref of the length of the data.
    },[jumpstatData])

    return (
        <div className="p-d-flex p-jc-center">
            <DataTable 
                className="p-datatable-sm infinite-table"
                style={{maxWidth:"1140px"}}
                value={jumpstatData}
                header={tableTop}
                footer={tableFooter}
                rowClassName={(rowData) => {
                    const condition = rowData.is_crouch_boost === 1 ? (rowData.distance >= jumpClubs[rowData.jump_type - 1].bind) : (rowData.distance >= jumpClubs[rowData.jump_type - 1].unbind)
                    return {"rgb-effect": condition}
                }}
                //ref={dt}
                //globalFilter={globalFilter}
                sortMode="multiple"
                multiSortMeta={[{field: 'distance', order: -1}]}
                defaultSortOrder={-1}
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rows={rows}
                rowsPerPageOptions={[20,50,100]}
                rowHover={true}
                emptyMessage="No records found."
                //scrollable
                loading={loadingJumpstats}
                first={first}
                onPage={(e) => {
                    setFirst(e.first)
                    setRows(e.rows)
                }}
                //filters={localFilters}
                //onFilter={(e) => setLocalFilters(e.filters)}
                //frozenWidth="136px"
            >
                <Column 
                    header="Jump Tier"
                    body={jumptier}
                    headerStyle={{width:"136px"}}
                    sortable>
                </Column>
                <Column 
                    field="distance"
                    header="Distance"
                    body={stylizedDistance}
                    headerStyle={{width:"136px"}}
                    sortable>
                </Column>
                <Column 
                    field="strafe_count"
                    header="Strafes"
                    headerStyle={{width:"136px"}}
                    sortable>
                </Column>
                <Column 
                    field="is_crouch_boost"
                    header="Crouchbind"
                    body={(rowData)=>{
                        return (rowData.is_crouch_boost === 1 ? "Yes" : "No")
                    }}
                    headerStyle={{width:"136px"}}
                    sortable>
                </Column>
                <Column 
                    field="created_on"
                    header="Date"
                    body={rowData => formatDate(rowData)}
                    headerStyle={{width:"136px"}}
                    sortable>
                </Column>
                <Column 
                    field="server_id"
                    header="Server"
                    headerStyle={{width:"136px"}}
                    sortable>
                </Column>
            </DataTable>
        </div>
    )
}

export default Jumpstats