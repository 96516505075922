import React from "react"

import "./PlayerPanel.css"
import WrMedal from '../../../img/medals/WRs.png'
import WrMedalEmpty from '../../../img/medals/WRs-empty.png'
import RedMedal from '../../../img/medals/900s.png'
import RedMedalEmpty from '../../../img/medals/900s-empty.png'
import BlueMedal from '../../../img/medals/800s.png'
import BlueMedalEmpty from '../../../img/medals/800s-empty.png'
import AvatarFrame from '../../../img/frame1.png'

import PlayerFlag from '../../Essentials/PlayerFlag'

import { FaSteam, FaTwitch, FaYoutube, FaDiscord } from "react-icons/fa"
import { Badge } from 'primereact/badge'

function PlayerPanel ({playerName, playerID, playerNationality, playerAvatar, playerRank, pointsTotal, pointsAvg, points1000s, points900s, points800s, playerRoles, playerSocials}) {

    const rolesStyle = [
        {
            name: "GLOBAL TEAM",
            color: "#1abc9c"
        },
        {
            name: "APPROVAL TEAM",
            color: "#eee44d"
        },
        {
            name: "MAPPER",
            color: "#458cff"
        },
        {
            name: "SERVER OWNER",
            color: "#df6969"
        }
    ]

    const PlayerSocials = (
        <>
            {playerSocials.s &&
            <a href={playerSocials.s} target="_blank" rel="noreferrer" >
                <FaSteam />
            </a>}
            {playerSocials.t &&
            <a href={playerSocials.t} target="_blank" rel="noreferrer" className="p-overlay-badge">
                <FaTwitch />
                <Badge style={{background:"#e91916"}} />
            </a>}
            {playerSocials.y &&
            <a href={playerSocials.y} target="_blank" rel="noreferrer" >
                <FaYoutube />
            </a>}
            {playerSocials.d &&
            <a href={playerSocials.d} target="_blank" rel="noreferrer" >
                <FaDiscord />
            </a>}
        
        </>
    )

    const WrMedalDiv = (
        points1000s > 0 ?
        <div className="p-order-1 p-order-md-0" style={{marginTop:"0.5rem", position:"relative"}}>
            <img src={WrMedal} alt="WRs" />
            <span className="wr-badge">{points1000s}</span>
        </div>
        :
        <div className="p-order-1 p-order-md-0" style={{marginTop:"0.5rem", position:"relative"}}>
            <img src={WrMedalEmpty} alt="WRs" />
        </div>
    )

    const RedMedalDiv = (
        points900s > 0 ?
        <div className="p-order-0 p-order-md-1" style={{marginTop:"0.875rem", position:"relative"}}>
            <img src={RedMedal} alt="900s" />
            <span className="red-badge">{points900s}</span>
        </div>
        :
        <div className="p-order-0 p-order-md-1" style={{marginTop:"0.875rem", position:"relative"}}>
            <img src={RedMedalEmpty} alt="900s" />
        </div>
    )

    const BlueMedalDiv = (
        points800s > 0 ?
        <div className="p-order-2" style={{marginTop:"0.125rem", position:"relative"}}>
            <img src={BlueMedal} alt="800s" />
            <span className="blue-badge">{points800s}</span>
        </div>
        :
        <div className="p-order-2" style={{marginTop:"0.125rem", position:"relative"}}>
            <img src={BlueMedalEmpty} alt="800s" />
        </div>
    )

    const PlayerRoles = playerRoles.map(role=>{
        return (
            <div className="info-role" key={role} style={{borderColor: rolesStyle[role].color, color: rolesStyle[role].color}}>
                <span>{rolesStyle[role].name}</span>
            </div>
        )
    })
    
    return (
        <div className="player-panel">
            
            <div className="picture-col">
                <div className="picture-div">
                    <a href={`https://steamcommunity.com/profiles/${playerID}/`} target="_blank" rel="noreferrer" >
                        <div className="avatar-frame">
                            <img src={AvatarFrame} alt="frame" />
                        </div>
                        {playerAvatar &&
                        <img className="avatar-image" src={playerAvatar} alt="playerAvatar"></img>
                        }
                    </a>
                </div>
                <div className="socials-row">
                    {PlayerSocials}
                </div>
            </div>
            
            <div className="medals-col">
                {WrMedalDiv}
                {RedMedalDiv}
                {BlueMedalDiv}
            </div>

            <div className="info-col">
                <div className="info-name">
                    <span>{playerName}</span>
                    <PlayerFlag nationality={playerNationality} />
                </div>
                <div className={"info-rank " + playerRank}>
                    {playerRank}
                </div>
                <div className="info-points">
                    {`${pointsTotal.toLocaleString()} (${pointsAvg})`}
                </div>
                <div className="info-roles">
                    {PlayerRoles}
                </div>
            </div>
        </div>
    )
}

export default PlayerPanel