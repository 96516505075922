import axios from 'axios'

import { GetSteam64, GetSteam32 } from "./SteamID"

const globalAPI = axios.create({
    baseURL: 'https://kztimerglobal.com/api/v2.0/'
})

const steamAPI = axios.create({
    baseURL: 'https://kzprofile-api.vercel.app/api/'
})

let steamProfileSource
let leaderboardSources
let jumpstatSource

export const GetAPI_GlobalMaps = (validated) => {
    const url = "maps"

    const params = {
        is_validated: validated,
        limit: 9999
    }

    return globalAPI.get(url, {params: params})
}

export const GetAPI_PlayerTimes = (steamID, map_name, stage, gameMode, runType, limit) => {
    const url = 'records/top'

    let params = {
        steamid64: GetSteam64(steamID),
        map_name: map_name,
        stage: stage,
        modes_list_string: gameMode,
        tickrate: 128,
        limit: limit
    }

    if (runType === "pro") {
        params = {...params, has_teleports: false}
    } else if (runType === "tp") {
        params = {...params, has_teleports: true}
    }
    //If it's nub, don't add has_teleports. Most of the times I fetch both pro and tp at the same time anyways.

    return globalAPI.get(url, {params: params})
}

export const GetAPI_MapTimes = (map_name, stage, mode, runType, limit, offset) => {
    const url = 'records/top'

    let params = {
        map_name: map_name,
        stage: stage,
        modes_list_string: mode,
        tickrate: 128,
        limit: limit,
        offset: offset
    }

    if (runType === "pro") {
        params = {...params, has_teleports: false}
    } else if (runType === "tp") {
        params = {...params, has_teleports: true}
    } else if (runType === "nub") {
        params = {...params, overall: true}
    }

    return globalAPI.get(url, {params: params})
}

export const GetAPI_MapFilters = (map_ids, stages, mode_ids, has_teleports) => {
    //Careful, kzpro filters appear in has_teleports=true right now.
    const url = 'record_filters'
    
    let params = new URLSearchParams({
        tickrates: 128,
        limit: 9999
    })

    if (mode_ids) {
        params.append("mode_ids", mode_ids)
    }

    if (has_teleports !== undefined) {
        params.append("has_teleports", has_teleports)
    }
    
    if (map_ids) {
        params.append("map_ids", map_ids)
    }

    if (stages) {
        stages.map(stage=>{
            params.append("stages", stage)
            return null
        })
    }

    return globalAPI.get(url, {params: params})
}

export const GetAPI_Players = (steamid64_list, name, limit, offset) => {
    //Does player exist in API?
    const url = 'players'

    //Haven't found an occasion where more than 1 id is needed yet.
    const params = {
        steamid64_list: GetSteam64(steamid64_list),
        name: name,
        limit: limit,
        offset: offset
    }

    return globalAPI.get(url, {params: params})
}

export const GetAPI_PlayerRanks = (mode_ids, runType, stages, steamid64s) => {
    const url = 'player_ranks'
    
    let params = new URLSearchParams({
        mode_ids: mode_ids,
        tickrates: 128,
        limit: 20
    })

    stages.map(stage=>{
        params.append("stages", stage)
        return null
    })
    steamid64s && steamid64s.map((id) => {
        params.append("steamid64s", GetSteam64(id))
        return null
    })

    if (runType === "pro") {
        params.append("has_teleports", false)
    } else if (runType === "tp" || runType === "nub") {
        params.append("has_teleports", true)
    }

    if (leaderboardSources !== undefined) {
        leaderboardSources.cancel("A new leaderboard was requested")
    }

    leaderboardSources = axios.CancelToken.source()

    return globalAPI.get(url, {
        params: params,
        cancelToken: leaderboardSources.token
    })
}

export const GetAPI_WorldRecords = (mode_ids, runType, stages) => {
    const url = 'records/top/world_records'
    
    let params = new URLSearchParams({
        mode_ids: mode_ids,
        tickrates: 128,
        limit: 20
    })

    stages.map(stage=>{
        params.append("stages", stage)
        return null
    })

    if (runType === "pro") {
        params.append("has_teleports", false)
    } else if (runType === "tp") {
        params.append("has_teleports", true)
    } //if it's nub, don't append has_teleports and fetch all, pro and tp combined.

    if (leaderboardSources !== undefined) {
        leaderboardSources.cancel("A new leaderboard was requested")
    }

    leaderboardSources = axios.CancelToken.source()

    return globalAPI.get(url, {
        params: params,
        cancelToken: leaderboardSources.token
    })
}

export const GetAPI_JumpstatsTop = (jumpType, is_crouch_bind) => {
    const url = `jumpstats/${jumpType}/top`

    const maxDistance = {
        longjump: 292,
        bhop: 350,
        multibhop: 365,
        ladderjump: 210,
        weirdjump: 308,
        dropbhop: 350,
        countjump: 315
    }
    //crouch_bind is necessary for top without cheaters 
    let params = {
        is_crouch_bind: is_crouch_bind,
        is_crouch_boost: is_crouch_bind,
        less_than_distance: maxDistance[jumpType],
        limit: 20
    }

    if (leaderboardSources !== undefined) {
        leaderboardSources.cancel("A new leaderboard was requested")
    }

    leaderboardSources = axios.CancelToken.source()

    return globalAPI.get(url, {
        params: params,
        cancelToken: leaderboardSources.token
    })
}

export const GetAPI_Jumpstats = (steamid64, jumptype, is_crouch_bind, offset) => {
    const url = 'jumpstats'
    
    const params = {
        //the jumpstats endpoint "steamid64" parameter is broken! MUST use 32bits id.
        steam_id: GetSteam32(steamid64),
        jumptype: jumptype,
        is_crouch_bind: is_crouch_bind,
        is_crouch_boost: is_crouch_bind,
        limit: 50,
        offset: offset
    }

    if (jumpstatSource !== undefined) {
        jumpstatSource.cancel("A new leaderboard was requested")
    }

    jumpstatSource = axios.CancelToken.source()

    return globalAPI.get(url, {
        params: params,
        cancelToken: jumpstatSource.token
    })
}

export const GetAPI_Servers = () => {
    const url = "servers"

    const params = {
        approval_status: 1,
        limit: 9999
    }

    return globalAPI.get(url, {params: params})
}

export const GetAPI_Server = (server_id) => {
    const url = `servers/${server_id}`

    return globalAPI.get(url)
}

export const GetAPI_Place = (runID) => {
    const url = `records/place/${runID}`

    return globalAPI.get(url)
}

export const GetAPI_Recent = (map_name, modes_list_string, has_teleports, stage, place_top_at_least, limit) => {
    const url = "records/top/recent"

    const params = {
        map_name,
        modes_list_string,
        has_teleports,
        stage,
        place_top_at_least,
        tickrate: 128,
        limit
    }

    return globalAPI.get(url, {params: params})
}

export const GetAPI_Distributions = (map_ids, mode_ids, has_teleports, stages, limit) => {
    const url = "record_filters/distributions"

    const params = {
        map_ids,
        mode_ids,
        has_teleports,
        stages,
        limit
    }

    return globalAPI.get(url, {params: params})
}

export const GetSteam_Profile = (steamIds) => {
    const url = 'steam/profile'

    steamIds = steamIds.map((steamid)=>{
        return GetSteam64(steamid)
    })

    let params = {
        steamids: steamIds.join(",")
    }

    if (steamProfileSource !== undefined) {
        steamProfileSource.cancel("A new player/mode/type was requested")
    }

    steamProfileSource = axios.CancelToken.source()

    return steamAPI.get(url, {
        params: params,
        cancelToken: steamProfileSource.token
    })
}

export const GetSteam_VanityURL = (vanityURL) => {
    const url = 'steam/vanity'

    const vanityKey = vanityURL.split("/id/").pop().replace("/","")

    const params = {
        url: vanityKey
    }

    return steamAPI.get( url, {params: params} )
}

export const GetSteam_ServersInfo = (servers) => {
    const url = 'steam/servers/info'

    const params = {
        servers: servers.join(",")
    }

    return steamAPI.get( url, {params: params} )
}

export const GetSteam_IpServers = (ip) => {
    const url = 'steam/servers/ip'

    const params = {
        ip: ip
    }

    return steamAPI.get( url, {params: params} )
}

export const GetSteam_MapServers = (mapName, mapID) => {
    const url = 'steam/servers/map'

    const params = {
        name: mapName,
        id: mapID
    }

    return steamAPI.get( url, {params: params} )
}