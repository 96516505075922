import React from "react"

import "./LoadingKZ.css"
import Loading from "../../img/loading.gif"

import { useWindowSize } from "../../hooks/useWindowSize"

function LoadingKZ () {
    //Parent needs to be a 100% width, relative positioned with hidden overflow div
    
    const size = useWindowSize()

    return (
        <div className="loading-moving" style={{animationDuration: `${size.width/215}s`, animationDelay: `-${(size.width/215)*0.5}s`}}>
            <img alt="loading" src={Loading} height="128px"></img>
        </div>
    )
}

export default LoadingKZ