import React, { useState, useEffect, createContext, useContext, useCallback, useRef } from "react"

import axios from "axios"

import { GetAPI_GlobalMaps, GetAPI_MapFilters, GetAPI_Players, GetAPI_PlayerTimes, GetAPI_Servers } from '../Utils/APICalls'
import { GetSteam64 } from "../Utils/SteamID"

//No default export. Instead, two exports:
//MapsContext for consuming the global state by calling useContext(MapsContext) (remember to import it)
export const MapsContext = createContext()

//And MapsProvider as a React Component to place everything that needs to access the global state inside of it.
//children is an element of the "props" object, we are just destructuring the props object to get only the children.
//We get the children of this component because it acts as a "Higher Order Component".
export const MapsProvider = ({children, messageToast}) => {

    const [globalMaps, setGlobalMaps] = useState([]) //if no length, don't do anything
    const [kzProfileMaps, setKzProfileMaps] = useState() //if no length I don't care, so just check if it's !== null

    useEffect(() => {
        GetAPI_GlobalMaps(true)
            .then((response) => {
                setGlobalMaps(response.data)
            })
            .catch(err => {
                console.log(err)
            })

        //This data gets cached until 00:00:00 UTC. The Workers data is updated at 23:30 UTC.
        axios.get("https://kzprofile-maps.nicoquattrochi.workers.dev")
            .then(response => {
                setKzProfileMaps(response.data)
            })
            .catch(err => {
                setKzProfileMaps([])
                messageToast.current.show({severity: 'warn', summary: 'KZ Profile', detail: "An error occurred with KZ Profile's backend.", life: 5000})
                console.log(err)
            })
    }, [messageToast])

    //Only called when needed and cache the response for fewer calls.
    let globalFiltersRef = useRef({
        200: null,  //kz_timer
        201: null,  //kz_simple
        202: null   //kz_vanilla
    })

    const getGlobalFilters = useCallback(async (gameModeID) => {

        if (globalFiltersRef.current[gameModeID]) {
            return Promise.resolve(globalFiltersRef.current[gameModeID])
        }

        try {
            const response = await GetAPI_MapFilters(null, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], gameModeID, false)

            globalFiltersRef.current[gameModeID] = response.data
            return Promise.resolve(response.data)

        } catch (err) {
            return Promise.reject(err)
        }
    },[])

    //Only called when needed and cache the response for fewer calls.
    let APIPlayerRef = useRef()

    const getAPIPlayer = useCallback(async (steamid) => {

        steamid = GetSteam64(steamid)

        const steamProfileData = JSON.parse(window.localStorage.getItem("steamProfileData"))

        if (steamProfileData?.steamid === steamid && APIPlayerRef.current) {
            return Promise.resolve(APIPlayerRef.current)
        }

        try {
            const response = await GetAPI_Players(steamid)

            if (steamProfileData?.steamid === steamid) {
                APIPlayerRef.current = response
            }

            return Promise.resolve(response)

        } catch (err) {
            return Promise.reject(err)
        }
    },[])

    //Only called when needed and cache the response for fewer calls.
    let playerTimesRef = useRef({
        mode: "",
        data: null
    })

    const getPlayerTimes = useCallback(async (steamid, gameMode, refresh) => {

        steamid = GetSteam64(steamid)

        const steamProfileData = JSON.parse(window.localStorage.getItem("steamProfileData"))

        if (steamProfileData?.steamid === steamid &&
            playerTimesRef.current.mode === gameMode &&
            !refresh
        ) {
            return Promise.resolve(playerTimesRef.current.data)
        }

        try {
            const results = await Promise.all([
                GetAPI_PlayerTimes(steamid, null, null, gameMode, "pro", 9999),
                GetAPI_PlayerTimes(steamid, null, null, gameMode, "tp", 9999)
            ])

            if (steamProfileData?.steamid === steamid) {
                playerTimesRef.current = {
                    mode: gameMode,
                    data: results
                }
            }

            return Promise.resolve(results)

        } catch (err) {
            return Promise.reject(err)
        }
    },[])

    //Only called when needed and cache the response for fewer calls.
    let globalServersRef = useRef()

    const getGlobalServers = useCallback(async () => {

        if (globalServersRef.current) {
            return Promise.resolve(globalServersRef.current)
        }

        try {
            const response = await GetAPI_Servers()

            globalServersRef.current = response.data
            return Promise.resolve(response.data)

        } catch (err) {
            return Promise.reject(err)
        }
    },[])

    //Remember I can pass the set state functions too if needed
    return (
        <MapsContext.Provider value={{globalMaps, kzProfileMaps, getGlobalFilters, getAPIPlayer, getPlayerTimes, getGlobalServers}}>
            { children }
        </MapsContext.Provider>
    )

}

export function useGlobalMaps() {
    const context = useContext(MapsContext)
    if (context === undefined) {
        throw new Error("Context must be used within a Provider")
    }
    return context.globalMaps
}

export function useKzProfileMaps() {
    const context = useContext(MapsContext)
    if (context === undefined) {
        throw new Error("Context must be used within a Provider")
    }
    return context.kzProfileMaps
}