import React, { useState, useEffect, useContext, useCallback } from "react"

import { GameModeContext } from "../../../context/GameModeContext"
import { GetAPI_MapTimes } from '../../../Utils/APICalls'

import { useParams, useHistory, useRouteMatch } from "react-router-dom"

import MapRanking from "./MapRanking"

import { Dropdown } from 'primereact/dropdown'

function BonusRanking ({ mapName, currentBonusData, setCurrentBonusData, getMapFilters, mapID, steamid, localSettings, messageToast }) {
    
    let { stage } = useParams()
    const history = useHistory()
    const match = useRouteMatch()

    const {gameMode, runType} = useContext(GameModeContext)

    const [loadingBonus, setLoadingBonus] = useState(false)

    const [dropdownValue, setDropdownValue] = useState(parseInt(stage))
    const [bonusDropdownItems, setBonusDropdownItems] = useState()

    const fetchInitialData = useCallback((refresh) => {
        if (currentBonusData.mapName !== mapName || currentBonusData.stage !== stage || currentBonusData.gameMode !== gameMode || refresh) {
            messageToast.current.clear()
            setLoadingBonus(true)

            //Are we using nub or tp?
            const secondaryRunType = localSettings.modeChooserType.split("-").pop()

            const promises = [
                GetAPI_MapTimes(mapName, stage, gameMode, "pro", 100, 0),
                GetAPI_MapTimes(mapName, stage, gameMode, secondaryRunType, 100, 0)
            ]

            Promise.all(promises)
                .then(results => {
                    setCurrentBonusData(oldData => {
                        return {
                            mapName: mapName,
                            stage: stage,
                            gameMode: gameMode,
                            filters: results[2] ? results[2].data : oldData.filters,
                            pro: results[0].data.map((record, index) => {
                                return {...record, place: index + 1}
                            }),
                            [secondaryRunType]: results[1].data.map((record, index) => {
                                return {...record, place: index + 1}
                            })
                        }
                    })
                    
                    setLoadingBonus(false)
                })
                .catch(err => {
                    console.log(err)
                    setLoadingBonus(false)
                })
        }
    },[mapName, stage, gameMode, currentBonusData, setCurrentBonusData, localSettings, messageToast])

    useEffect(()=>{
        fetchInitialData(false)
    },[fetchInitialData])

    const loadMore = (offset) => {
        setLoadingBonus(true)

        GetAPI_MapTimes(mapName, stage, gameMode, runType, 100, offset)
            .then(response => {
                const dataWithIndex = response.data.map((record, index) => {
                    return {...record, place: index + 1 + offset}
                })

                setCurrentBonusData(oldData => {
                    return {...oldData, [runType]: [...oldData[runType], ...dataWithIndex]}
                })

                setLoadingBonus(false)
                window.scrollTo(0, 0)
            })
            .catch(err => {
                messageToast.current.show({severity: 'error', summary: 'Global API', detail: 'An error occurred with the global API.', sticky: true})
                console.log(err)
                setLoadingBonus(false)
            })
    }

    const getStages = useCallback(async () => {
        if (mapID) {
            const currentFilters = await getMapFilters(mapID, gameMode, runType, true)
            setBonusDropdownItems(currentFilters.map(filter => {
                return {
                    label: `Bonus ${filter.stage}`,
                    value: filter.stage
                }
            }))
        }
    },[mapID, gameMode, runType, getMapFilters])

    useEffect(() => {
        getStages()
    },[getStages])

    const tableTitle = (
        <Dropdown
            value={dropdownValue}
            options={bonusDropdownItems}
            onChange={(e) => {
                setDropdownValue(e.value)
                history.push(`/maps/${match.params.mapName}/bonus/${e.value}`)
            }}
            placeholder="Select a Bonus"
            valueTemplate={(option, props)=>{
                if (option) {
                    return `${option.label} Ranking`
                } else {
                    return props.placeholder
                }
            }}
            emptyMessage="No bonus found"
            style={{background:"none", border:"none", marginTop:"-0.375rem", marginBottom:"-0.375rem"}}
        />
    )

    return (
        <MapRanking
            loading={loadingBonus}
            mapTimesData={currentBonusData[runType]}
            steamid={steamid}
            fetchInitialData={fetchInitialData}
            loadMore={loadMore}
            tableTitle={tableTitle}
        />
    )
}

export default BonusRanking