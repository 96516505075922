import React, { useContext } from "react"

import "./ModeChooser.css"

import { GameModeContext } from "../../context/GameModeContext"
import { useLocalSettings } from "../../hooks/useLocalSettings"

function ModeChooser () {

    const {gameMode, runType, setGameMode, setRunType} = useContext(GameModeContext)
    const [localSettings] = useLocalSettings()

    return (
        <div className="modesRow">
            <label className="gamemodeRadio">
                <input
                    type="radio"
                    name="gameModeRadio"
                    value="kz_timer"
                    checked={gameMode === "kz_timer"}
                    onChange={e => setGameMode(e.target.value)}
                />
                <span>KZ Timer</span>
            </label>
            <label className="gamemodeRadio">
                <input
                    type="radio"
                    name="gameModeRadio"
                    value="kz_simple"
                    checked={gameMode === "kz_simple"}
                    onChange={e => setGameMode(e.target.value)}
                />
                <span>Simple KZ</span>
            </label>
            <label className="gamemodeRadio" style={{paddingRight: "max(2vw, 12px)", borderRight: "2px solid white"}}>
                <input
                    type="radio"
                    name="gameModeRadio"
                    value="kz_vanilla"
                    checked={gameMode === "kz_vanilla"}
                    onChange={e => setGameMode(e.target.value)}
                />
                <span>Vanilla</span>
            </label>
            {/* Run Type */}
            <label className="gamemodeRadio" style={{paddingLeft:"max(2vw, 12px)"}}>
                <input
                    type="radio"
                    name="runTypeRadio"
                    value="pro"
                    checked={runType === "pro"}
                    onChange={e => setRunType(e.target.value)}
                />
                <span>Pro</span>
            </label>
            {localSettings.modeChooserType === "pro-tp" &&
            <label className="gamemodeRadio">
                <input
                    type="radio"
                    name="runTypeRadio"
                    value="tp"
                    checked={runType === "tp"}
                    onChange={e => setRunType(e.target.value)}
                />
                <span>Tp</span>
            </label>}
            {localSettings.modeChooserType === "pro-nub" &&
            <label className="gamemodeRadio">
                <input
                    type="radio"
                    name="runTypeRadio"
                    value="nub"
                    checked={runType === "nub"}
                    onChange={e => setRunType(e.target.value)}
                />
                <span>Nub</span>
            </label>}
        </div>
    )
}

export default ModeChooser