import { useState } from "react"

export const useLocalSettings = () => {
    const defaultValue = {
        modeChooserType: "pro-tp",
        defaultSortOrderYours: "points",
        defaultSortOrderOthers: "points",
        defaultProfilePage: "",
        dateFormat: "yyyy-MM-dd"
    }

    const [localSettings, setLocalSettings] = useState(() => {
      try {
        const item = window.localStorage.getItem("localSettings")
        return item ? JSON.parse(item) : defaultValue
      } catch (error) {
        console.log(error)
        return defaultValue
      }
    })

    const setValue = (value) => {
      try {
        const valueToStore = value instanceof Function ? value(localSettings) : value
        setLocalSettings(valueToStore)
        window.localStorage.setItem("localSettings", JSON.stringify(valueToStore))
      } catch (error) {
        console.log(error)
      }
    }
    return [localSettings, setValue]
}