import React, { useState, useEffect } from "react"

import "./Leaderboard.css"

import {
    Link,
    NavLink,
    useParams,
    useHistory
} from "react-router-dom"
import { useSearchParams } from "../../hooks/useSearchParams"

import axios from 'axios'

import { GetAPI_JumpstatsTop, GetSteam_Profile } from '../../Utils/APICalls'
import LeaderBoardCard from "./LeaderboardCard"
import BannerBackground from "../Essentials/BannerBackground"
import LoadingKZ from "../Essentials/LoadingKZ"
import PlayerFlag from "../Essentials/PlayerFlag"
import JumpstatColor from "../../Utils/JumpstatColor"

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Skeleton } from 'primereact/skeleton'
import { Dropdown } from 'primereact/dropdown'

function JumpstatsLeaderboard () {

    const history = useHistory()

    const { jumptype } = useParams()
    const [crouchbind, setCrouchbind] = useSearchParams("crouch_bind", true)

    const [loading, setLoading] = useState(true)
    
    const [jumpstatsData, setJumpstatsData] = useState()
    const emptyTable = new Array(20)

    const jumpTypes = [
        {label: 'Longjump', value: 'longjump'},
        {label: 'Bhop', value: 'bhop'},
        {label: 'Multibhop', value: 'multibhop'},
        {label: 'Weirdjump', value: 'weirdjump'},
        {label: 'Dropbhop', value: 'dropbhop'},
        {label: 'Countjump', value: 'countjump'},
        {label: 'Ladderjump', value: 'ladderjump'}
    ]

    const handleErrors = (err) => {
        if (axios.isCancel(err)) {
            console.log('Request canceled: ', err.message)
        } else {
            console.log(err)
        }
    }

    useEffect(()=>{
        setLoading(true)

        GetAPI_JumpstatsTop(jumptype, crouchbind)
            .then(APIresponse => {
                const steamid64s = APIresponse.data.map(player=>{
                    return player.steamid64
                })
                
                GetSteam_Profile(steamid64s)
                    .then(steamResponse => {
                        for (let i = 0; i < APIresponse.data.length; i++) {
                            const steamPlayer = steamResponse.data.response.players.find(player=>
                                APIresponse.data[i].steamid64 === player.steamid
                            )
                            APIresponse.data[i].avatarfull = steamPlayer.avatarfull
                            APIresponse.data[i].avatar = steamPlayer.avatar
                            APIresponse.data[i].loccountrycode = steamPlayer.loccountrycode
                        }
                        setJumpstatsData(APIresponse.data)
                        setLoading(false)
                    })
                    .catch(err => {
                        handleErrors(err)
                    })
            })
            .catch(err => {
                handleErrors(err)
            })
    },[jumptype, crouchbind])

    const rowIndex = (rowData, column) => {
        return(
            loading ? 
            <Skeleton></Skeleton> :
            <div>
                {column.rowIndex + 1}
            </div>
        )
    }

    const playerLink = (rowData) => {
        return (
            loading ?
            <Skeleton></Skeleton> :
            <Link className="p-d-inline-flex p-ai-center" to={`/player/${rowData.steamid64}/jumpstats/longjump`}>
                <img src={rowData.avatar} alt="avatar" style={{borderRadius:"50%"}}></img>
                <span className="p-pl-3 p-pr-3">{rowData.player_name}</span>
                <PlayerFlag nationality={rowData.loccountrycode} flagHeight="22" />
            </Link>
        )
    }

    const stylizedDistance = (rowData) => {
        return (
            loading ?
            <Skeleton></Skeleton> :
            <div className={JumpstatColor(rowData.distance, rowData.jump_type, rowData.is_crouch_boost === 1 ? true : false)}>
                {rowData.distance.toLocaleString(undefined, { minimumFractionDigits: 4 })}
            </div>
        )
    }

    const stylizedStrafes = (rowData) => {
        return (
            loading ?
            <Skeleton></Skeleton> :
            <div>
                {rowData.strafe_count}
            </div>
        )
    }

    return (
        <div>
            <div className="leaderboard-banner">
                <BannerBackground mapID="kz_nassau" />
                <div className="leaderboard-banner-wrapper">
                    {/*only reason to create this wrapper is to prevent overflow when moving loading exits the screen*/}
                    <div className="modesRow">
                        <Dropdown
                            className="jumptype-dropdown p-d-md-none"
                            value={jumptype}
                            options={jumpTypes}
                            onChange={e => history.push(e.value)}
                        />
                        <div className="p-d-none p-d-md-inline-flex" style={{paddingRight: "max(1vw, 6px)"}}>
                            {jumpTypes.map(type => {
                                return (
                                    <NavLink className="jumptype-navlink" to={type.value} key={type.value}>
                                        {type.label}
                                    </NavLink>
                                )
                            })}
                        </div>
                        {/* Crouchbind */}
                        <label className="gamemodeRadio" style={{paddingLeft:"max(2vw, 12px)", borderLeft: "2px solid white"}}>
                            <input
                                type="checkbox" 
                                checked={crouchbind} 
                                onChange={()=>setCrouchbind(!crouchbind)}
                            />
                            <span>Crouchbind</span>
                        </label>
                    </div>
                    {loading ?
                    <LoadingKZ /> :
                    <div className="p-d-flex p-jc-evenly p-mt-3">
                        <LeaderBoardCard
                            playerPlace = {2}
                            playerID = {jumpstatsData[1].steamid64}
                            playerName = {jumpstatsData[1].player_name}
                            playerScore = {jumpstatsData[1].distance}
                            playerAvatar = {jumpstatsData[1].avatarfull}
                            leaderboardType = {"jumpstats"}
                        />
                        <LeaderBoardCard
                            playerPlace = {1}
                            playerID = {jumpstatsData[0].steamid64}
                            playerName = {jumpstatsData[0].player_name}
                            playerScore = {jumpstatsData[0].distance}
                            playerAvatar = {jumpstatsData[0].avatarfull}
                            leaderboardType = {"jumpstats"}
                        />
                        <LeaderBoardCard
                            playerPlace = {3}
                            playerID = {jumpstatsData[2].steamid64}
                            playerName = {jumpstatsData[2].player_name}
                            playerScore = {jumpstatsData[2].distance}
                            playerAvatar = {jumpstatsData[2].avatarfull}
                            leaderboardType = {"jumpstats"}
                        />
                    </div>
                    }
                </div>
            </div>
            <div className="leaderboard-body">
                <div className="p-d-flex p-jc-center">
                    <DataTable
                        value={loading? emptyTable : jumpstatsData}
                        className="minimal-datatable p-pt-3"
                        sortField="distance"
                        sortOrder={-1}
                        autoLayout={true}
                        style={{overflow:"auto"}} //careful, this might brake some functionality, but works.
                    >
                        <Column
                            header="#"
                            body={rowIndex}
                            headerStyle={{textAlign:"center"}}
                            bodyStyle={{textAlign:"center"}}>
                        </Column>
                        <Column
                            field="player_name"
                            header="Name"
                            body={playerLink}
                            headerStyle={{width: "25vw", maxWidth: "500px", minWidth: "15rem"}}>
                        </Column>
                        <Column
                            field="distance"
                            header="Distance"
                            body={stylizedDistance}
                            headerStyle={{textAlign:"center", minWidth: "6rem"}}
                            bodyStyle={{textAlign:"center"}}>
                        </Column>
                        <Column
                            field="strafe_count"
                            header="Strafes"
                            body={stylizedStrafes}
                            headerStyle={{textAlign:"center", minWidth: "6rem"}}
                            bodyStyle={{textAlign:"center"}}>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    )
}

export default JumpstatsLeaderboard