import React from "react"

import { FaSteam } from "react-icons/fa"

function LoginButton () {

    const steamOpenID = 
        'https://steamcommunity.com/openid/login?' +
        'openid.ns=http://specs.openid.net/auth/2.0&' +
        'openid.mode=checkid_setup&' +
        `openid.return_to=${window.location.protocol}//${window.location.host}/login?` +
        `openid.realm=${window.location.protocol}//${window.location.host}&` +
        'openid.ns.sreg=http://openid.net/extensions/sreg/1.1&' +
        'openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&' +
        'openid.identity=http://specs.openid.net/auth/2.0/identifier_select'

    return (
        <a href={steamOpenID}>
            <img
                src="https://community.akamai.steamstatic.com/public/images/signinthroughsteam/sits_01.png"
                alt="steamLogin"
                className="p-d-none p-d-sm-block">
            </img>
            <FaSteam className="p-d-block p-d-sm-none" style={{fontSize:"1.75rem"}} />
        </a>
    )
}

export default LoginButton