import React, {useState, useRef} from "react"

import { mapLink, formatDate } from "../../Utils/TableRows"

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Tooltip } from 'primereact/tooltip'

function Unfinishes ({unfinishedMaps, fetchPlayerFinishes, dateFormat, loading}) {

    const [globalFilter, setGlobalFilter] = useState("")
    const [localFilters, setLocalFilters] = useState({})
    const [currentFilter, setCurrentFilter] = useState("map_name") //to know what overlay panel is currently open, so I can close it when another one opens (a bug doesn't close it sometimes)

    const [mapsFilterValue, setMapsFilterValue] = useState("")
    const [mapsFilterMode, setMapsFilterMode] = useState("contains")

    const [tiersFilterValue, setTiersFilterValue] = useState([])
    const TIER = ["Very Easy","Easy","Medium","Hard","Very Hard","Extreme","Death"]

    const [datesFilterValue, setDatesFilterValue] = useState("")
    const [datesFilterMode, setDatesFilterMode] = useState("dateIs")
    
    //Filter modes for dropdowns
    const stringFilterModes = [
        {label: 'Contains', value: 'contains'},
        {label: 'Starts with', value: 'startsWith'},
        {label: 'Ends with', value: 'endsWith'},
        {label: 'Equals', value: 'equals'},
        {label: 'Not equals', value: 'notEquals'}
    ]
    const tiersMultiselectOptions = [
        {label: 'Very Easy', value: 1},
        {label: 'Easy', value: 2},
        {label: 'Medium', value: 3},
        {label: 'Hard', value: 4},
        {label: 'Very Hard', value: 5},
        {label: 'Extreme', value: 6},
        {label: 'Death', value: 7}
    ]
    const dateFilterModes = [
        {label: 'Date is', value: 'dateIs'},
        {label: 'Date before', value: 'dateBefore'},
        {label: 'Date after', value: 'dateAfter'}
    ]
    //Refs
    const dt = useRef(null);
    const mapsOPanel = useRef(null)
    const tiersOPanel = useRef(null)
    const datesOPanel = useRef(null)
    
    const fieldReferences = {
        map_name: {header: "Map", overlayPanel: mapsOPanel},
        difficulty: {header: "Tier", overlayPanel: tiersOPanel},
        created_on: {header: "Date", overlayPanel: datesOPanel},
    }

    const tableTop = (
        <div className="p-d-flex p-ai-center" style={{height:"100%"}}>
            <div className="tableTopItem">
                <Button
                    icon="pi pi-refresh"
                    className="p-button-rounded p-button-text p-button-white"
                    onClick={e => {
                        fetchPlayerFinishes(true)
                    }}
                    tooltip="Refresh data"
                    tooltipOptions={{ position: 'top', showDelay: 500}}
                />
                <Button
                    icon="pi pi-filter-slash"
                    className="p-button-rounded p-button-text p-button-white"
                    onClick={e => {
                        setGlobalFilter("")
                        setLocalFilters({})
                        setMapsFilterValue("")
                        setTiersFilterValue([])
                        setDatesFilterValue("")
                        dt.current.reset()
                    }}
                    tooltip="Clear filters"
                    tooltipOptions={{ position: 'top', showDelay: 500}}
                />
            </div>
            <div className="tableTopItem p-text-center">Unfinished Maps</div>
            <div className="tableTopItem p-text-right">
                <span className="p-input-icon-right">
                    <InputText className="p-inputtext-sm" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" type="search" style={{width:"100%"}} />
                </span>    
            </div>
        </div>
    )

    const headerTemplate = (fieldName) => {
        //p-column-title class is added so column can be sorted when clicking the span
        return(
            <span className="p-d-inline-flex p-ai-center">
                <Button
                    onClick={
                        (e) => {
                            fieldReferences[currentFilter].overlayPanel.current.hide(e) //there's a bug where the previous panel wouldn't close automatically
                            setCurrentFilter(fieldName)
                            fieldReferences[fieldName].overlayPanel.current.show(e)
                        }
                    }
                    icon="pi pi-filter"
                    className={`p-button-rounded p-button-text ${!localFilters[fieldName] && "p-button-plain"}`}
                />
                <span className="p-column-title" style={{height:"2.25rem", padding:"0.571rem"}}>{fieldReferences[fieldName].header}</span>
            </span>
        )
    }

    const tierLabel = (rowData) => {
        return (
            <div className={(TIER[rowData.difficulty - 1]).toUpperCase().replace(/ /g, "-")}>
                {TIER[rowData.difficulty - 1]}
            </div>
        )
    }

    return (
        <div>
            <DataTable 
                className="p-datatable-sm"
                value={unfinishedMaps}
                header={tableTop}
                ref={dt}
                globalFilter={globalFilter}
                sortMode="multiple"
                multiSortMeta={[{field: 'created_on', order: -1}]}
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink RowsPerPageDropdown"
                pageLinkSize={3}
                currentPageReportTemplate="{totalRecords}"
                rows={50}
                rowsPerPageOptions={[20,50,100]}
                rowHover={true}
                //scrollable /*overflow:auto in wrapper seems to work better*/
                loading={loading}
                filters={localFilters}
                onFilter={(e) => setLocalFilters(e.filters)}
                emptyMessage="No records found."
                /*frozenWidth="136px"*/
            >
                <Column 
                    field="map_name"
                    header={headerTemplate("map_name")}
                    body={mapLink}
                    className="p-text-nowrap p-text-truncate p-text-left"
                    headerStyle={{width:"136px"}}
                    /*frozen*/
                    sortable>
                </Column>
                <Column 
                    field="difficulty"
                    header={headerTemplate("difficulty")}
                    body={tierLabel}
                    headerStyle={{width:"136px"}}
                    sortable>
                </Column>
                <Column 
                    field="created_on"
                    header={headerTemplate("created_on")}
                    body={rowData => formatDate(rowData)}
                    headerStyle={{width:"136px"}}
                    sortable>
                </Column>
            </DataTable>
            
            <OverlayPanel ref={mapsOPanel}>
                <div className="p-d-flex p-jc-center p-mb-3">
                    Maps Filter
                </div>
                <div>
                    <Dropdown
                        value={mapsFilterMode}
                        options={stringFilterModes}
                        scrollHeight="300px"
                        onChange={e => {
                            setMapsFilterMode(e.value)
                        }}
                        className="p-d-flex p-mb-3"
                    />
                    <InputText
                        placeholder={"Map search"}
                        value={mapsFilterValue}
                        onChange={e => {
                            setMapsFilterValue(e.target.value)
                        }}
                        className="p-mb-3"
                    />
                    <div className="p-d-flex p-jc-around">
                        <Button
                            label="Clear"
                            onClick={e=>{
                                mapsOPanel.current.hide(e)
                                setMapsFilterValue("")
                                setMapsFilterMode("contains")
                                dt.current.filter("", "map_name", "contains")
                            }}
                            className="p-button-outlined p-button-sm"
                        />
                        <Button
                            label="Apply"
                            onClick={e=>{
                                mapsOPanel.current.hide(e)
                                if (mapsFilterValue) {
                                    dt.current.filter(mapsFilterValue, "map_name", mapsFilterMode)
                                } else {
                                    dt.current.filter("", "map_name", mapsFilterMode)
                                }
                            }}
                            className="p-button-sm"
                        />
                    </div>
                </div>
            </OverlayPanel>
            
            <OverlayPanel ref={tiersOPanel} style={{minWidth:"240px"}}>
                <div className="p-d-flex p-jc-center p-mb-3">
                    Tiers Filter
                </div>
                <div>
                    <MultiSelect
                        value={tiersFilterValue}
                        options={tiersMultiselectOptions}
                        onChange={e => {
                            setTiersFilterValue(e.value)
                        }}
                        scrollHeight="400px"
                        className="p-mb-3"
                        style={{minWidth:"100%"}}
                    />
                    <div className="p-d-flex p-jc-around">
                        <Button
                            label="Clear"
                            onClick={e=>{
                                tiersOPanel.current.hide(e)
                                setTiersFilterValue([])
                                dt.current.filter([], "difficulty", "in")
                            }}
                            className="p-button-outlined p-button-sm"
                        />
                        <Button
                            label="Apply"
                            onClick={e=>{
                                tiersOPanel.current.hide(e)
                                if (tiersFilterValue.length > 0) {
                                    dt.current.filter(tiersFilterValue, "difficulty", "in")
                                } else {
                                    dt.current.filter([], 'difficulty', 'in')
                                }
                            }}
                            className="p-button-sm"
                        />
                    </div>
                </div>
            </OverlayPanel>

            <OverlayPanel ref={datesOPanel}>
                <div className="p-d-flex p-jc-center p-mb-3">
                    Dates Filter
                </div>
                <div>
                    <Dropdown
                        value={datesFilterMode}
                        options={dateFilterModes}
                        onChange={e => {
                            setDatesFilterMode(e.value)
                        }}
                        className="p-d-flex p-mb-3"
                    />
                    <Calendar
                        value={datesFilterValue}
                        dateFormat={dateFormat.replace("yyyy", "yy").toLowerCase()}
                        mask="9999-99-99"
                        monthNavigator
                        yearNavigator
                        yearRange="2018:2030"
                        onChange={e => {
                            setDatesFilterValue(e.value)
                        }}
                        className="p-mb-3"
                    />
                    <div className="p-d-flex p-jc-around">
                        <Button
                            label="Clear"
                            onClick={e=>{
                                datesOPanel.current.hide(e)
                                setDatesFilterValue("")
                                setDatesFilterMode("dateIs")
                                dt.current.filter("", "created_on", "dateIs")
                            }}
                            className="p-button-outlined p-button-sm"
                        />
                        <Button
                            label="Apply"
                            onClick={e=>{
                                datesOPanel.current.hide(e)
                                if (datesFilterValue) {
                                    dt.current.filter(datesFilterValue, "created_on", datesFilterMode)
                                } else {
                                    dt.current.filter("", "created_on", datesFilterMode)
                                }
                            }}
                            className="p-button-sm"
                        />
                    </div>
                </div>
            </OverlayPanel>
            <Tooltip />
        </div>
    )

}

export default Unfinishes