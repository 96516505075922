import React from "react"

function VideoPanel ({ videoSource, rows, columns }) {

    //YouTube:          https://www.youtube.com/embed/wRESTAtFul0
    //Twitch channel:   https://player.twitch.tv/?channel=isyuks&parent=${window.location.hostname}&muted=true&autoplay=false
    //Twitch video:     https://player.twitch.tv/?video=959917201&parent=${window.location.hostname}&autoplay=false
    //Twitch clip:      https://clips.twitch.tv/embed?clip=TacitObliqueSquirrelJonCarnage&parent=${window.location.hostname}&autoplay=false
    //Bilibili:         https://player.bilibili.com/player.html?bvid=BV1Py4y1a7gu

    if (rows && columns) {
        return (
            <div style={{gridArea: `span ${rows}/ span ${columns}`}}>
                <iframe
                    src={videoSource}
                    height="100%"
                    width="100%"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={true}
                    style={{border:"none", borderRadius:"5px"}}
                    title="VideoPanel"
                />
            </div>
        )
    }

    //If we don't have it, it's just a standalone panel
    return (
        <div className="aspect-ratio-box">
            <iframe
                src={videoSource}
                height="100%"
                width="100%"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
                style={{border:"none", borderRadius:"5px"}}
                title="VideoPanel"
                className="aspect-ratio-box-inside"
            />
        </div>
    )
}

export default VideoPanel