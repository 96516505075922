import React from "react"
//https://github.com/gosquared/flags

function PlayerFlag ( {nationality, flagHeight} ) {
    return (
        <>
            {nationality !== undefined &&
            <img
                title={nationality}
                alt={nationality}
                src={`/flags/${nationality.toUpperCase()}.png`}
                onError={(e)=>e.target.src = `/flags/_unknown.png`}
                height={flagHeight}
                style={{verticalAlign: "middle"}}
            />}
        </>
    )
}

export default PlayerFlag