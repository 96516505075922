const jumpTypeID = {
    1: "longjump",
    2: "bhop",
    3: "multibhop",
    4: "weirdjump",
    5: "dropbhop",
    6: "countjump",
    7: "ladderjump"
}

export const jumpClubs = [
    {bind: 290, unbind: 287},
    {bind: 348, unbind: 344},
    {bind: 363, unbind: 358},
    {bind: 306, unbind: 302},
    {bind: 348, unbind: 348},
    {bind: 313, unbind: 313},
    {bind: 208, unbind: 208},
]

//rgb-effect stats should be assaigned to the last unit barrier broken in each jumptype
function JumpstatColor (units, jumptype, crouchjump) {
    
    //LONGJUMP
    if (jumpTypeID[jumptype] === "longjump") {

        if (crouchjump) {
            if (units >= jumpClubs[jumptype-1].bind){
                return 'rgb-effect'
            }
            if (units >= 288){
                return 'wrecker'
            }
            if (units >= 285){
                return 'ownage'
            }
            if(units >= 275){
                return 'godlike'
            }
            if (units >= 270) {
                return 'impressive'
            }
            if (units >= 265) {
                return 'perfect'
            }
            return null
        }

        if (units >= jumpClubs[jumptype-1].unbind){
            return 'rgb-effect'
        }
        if (units >= 284){
            return 'wrecker'
        }
        if(units >= 282){
            return 'ownage'
        }
        if (units >= 275) {
            return 'godlike'
        }
        if (units >= 270) {
            return 'impressive'
        }
        if (units >= 265) {
            return 'perfect'
        }
        return null
    }
    
    //BHOP
    if (jumpTypeID[jumptype] === "bhop") {

        if (crouchjump) {
            if (units >= jumpClubs[jumptype-1].bind){
                return 'rgb-effect'
            }
            if (units >= 344){
                return 'wrecker'
            }
            if (units >= 342){
                return 'ownage'
            }
            if(units >= 330){
                return 'godlike'
            }
            if (units >= 325) {
                return 'impressive'
            }
            if (units >= 320) {
                return 'perfect'
            }
            return null
        }

        if (units >= jumpClubs[jumptype-1].unbind){
            return 'rgb-effect'
        }
        if (units >= 342){
            return 'wrecker'
        }
        if(units >= 340){
            return 'ownage'
        }
        if (units >= 330) {
            return 'godlike'
        }
        if (units >= 325) {
            return 'impressive'
        }
        if (units >= 320) {
            return 'perfect'
        }
        return null
    }
    
    //MULTIBHOP
    if (jumpTypeID[jumptype] === "multibhop") {

        if (crouchjump) {
            if (units >= jumpClubs[jumptype-1].bind){
                return 'rgb-effect'
            }
            if (units >= 360){
                return 'wrecker'
            }
            if (units >= 358){
                return 'ownage'
            }
            if(units >= 350){
                return 'godlike'
            }
            if (units >= 345) {
                return 'impressive'
            }
            if (units >= 340) {
                return 'perfect'
            }
            return null
        }

        if (units >= jumpClubs[jumptype-1].unbind){
            return 'rgb-effect'
        }
        if (units >= 357){
            return 'wrecker'
        }
        if(units >= 355){
            return 'ownage'
        }
        if (units >= 350) {
            return 'godlike'
        }
        if (units >= 345) {
            return 'impressive'
        }
        if (units >= 340) {
            return 'perfect'
        }
        return null
    }
    
    //WEIRDJUMP
    if (jumpTypeID[jumptype] === "weirdjump") {

        if (crouchjump) {
            if (units >= jumpClubs[jumptype-1].bind){
                return 'rgb-effect'
            }
            if (units >= 302){
                return 'wrecker'
            }
            if (units >= 300){
                return 'ownage'
            }
            if(units >= 290){
                return 'godlike'
            }
            if (units >= 285) {
                return 'impressive'
            }
            if (units >= 280) {
                return 'perfect'
            }
            return null
        }

        if (units >= jumpClubs[jumptype-1].unbind){
            return 'rgb-effect'
        }
        if (units >= 300){
            return 'wrecker'
        }
        if(units >= 298){
            return 'ownage'
        }
        if (units >= 290) {
            return 'godlike'
        }
        if (units >= 285) {
            return 'impressive'
        }
        if (units >= 280) {
            return 'perfect'
        }
        return null
    }
    
    //DROPBHOP
    if (jumpTypeID[jumptype] === "dropbhop") { //no bind dropbhops can get 57 height, so same tier for both binded and not binded
        if (units >= jumpClubs[jumptype-1].unbind){
            return 'rgb-effect'
        }
        if (units >= 342){
            return 'wrecker'
        }
        if (units >= 340){
            return 'ownage'
        }
        if(units >= 325){
            return 'godlike'
        }
        if (units >= 320) {
            return 'impressive'
        }
        if (units >= 315) {
            return 'perfect'
        }
        return null
    }
    
    //COUNTJUMP
    if (jumpTypeID[jumptype] === "countjump") {
        if (units >= jumpClubs[jumptype-1].unbind){
            return 'rgb-effect'
        }
        if (units >= 307){
            return 'wrecker'
        }
        if (units >= 305){
            return 'ownage'
        }
        if(units >= 295){
            return 'godlike'
        }
        if (units >= 290) {
            return 'impressive'
        }
        if (units >= 285) {
            return 'perfect'
        }
        return null
    }
    
    //LADDERJUMP
    if (jumpTypeID[jumptype] === "ladderjump") {
        if (units >= jumpClubs[jumptype-1].unbind){
            return 'rgb-effect'
        }
        if (units >= 200){
            return 'wrecker'
        }
        if (units >= 193){
            return 'ownage'
        }
        if(units >= 175){
            return 'godlike'
        }
        if (units >= 165) {
            return 'impressive'
        }
        if (units >= 155) {
            return 'perfect'
        }
        return null
    }

    return null
}

export default JumpstatColor