import React, { useState, useEffect, useContext, useCallback } from "react"

import "./Maps.css"

import { NavLink } from "react-router-dom"

import { useLocalStorage } from "../../hooks/useLocalStorage"

import { GameModeContext, GameModeID } from "../../context/GameModeContext"
import { MapsContext, useGlobalMaps, useKzProfileMaps } from "../../context/MapsContext"

import ModeChooser from "../Essentials/ModeChooser"
import MapCard from "./MapCard"

import { DataView } from 'primereact/dataview'
import FilterUtils from 'primereact/components/utils/FilterUtils'
import { InputText } from 'primereact/inputtext'
import { Divider } from 'primereact/divider'
import {Checkbox} from 'primereact/checkbox'
import {TriStateCheckbox} from 'primereact/tristatecheckbox'
import { Rating } from 'primereact/rating'
import { RadioButton } from 'primereact/radiobutton'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Sidebar } from 'primereact/sidebar' //Fullscreen filters overlay
import { Skeleton } from 'primereact/skeleton'
import { Dialog } from 'primereact/dialog'

function Maps () {

    const [steamProfileData] = useLocalStorage("steamProfileData", null)
    
    const {gameMode, runType} = useContext(GameModeContext)

    const globalMaps = useGlobalMaps()
    const kzProfileMaps = useKzProfileMaps()
    const { getGlobalFilters } = useContext(MapsContext)
    const { getPlayerTimes } = useContext(MapsContext)
    const [finishesAndFilters, setFinishesAndFilters] = useState()
    //This state will have the globalMaps, kzProfileMaps, mapsWithBonuses and playerTimes joined.
    const [globalMapsExtended, setGlobalMapsExtended] = useState([])

    const [loading, setLoading] = useState(true)

    //Dataview
    const [filteredData, setFilteredData] = useState(new Array(60).fill({})) //if I don't fill it, the item doesn't render.
    const [globalFilter, setGlobalFilter] = useState("")
    const [localFilters, setLocalFilters] = useState({}) //Sample: {difficulty : {value: [1,2], matchMode: "in"}}
    const [tiersFilter, setTiersFilter] = useState([])
    const [ratingFilter, setRatingFilter] = useState(null)
    const [filtersFilter, setFiltersFilter] = useState([])
    const [stageFilter, setStageFilter] = useState("0")
    const [finishedFilter, setFinishedFilter] = useState(null)
    const [releaseFilter, setReleaseFilter] = useState(null)
    const [videoFilter, setVideoFilter] = useState(null)
    const [mapperFilter, setMapperFilter] = useState(null)
    const [first, setFirst] = useState(0)
    const [filtersPanel, setFiltersPanel] = useState(false) //Fullscreen filters overlay

    const [dialogVisibility, setDialogVisibility] = useState(false)
    const [dialogProperties, setDialogProperties] = useState({})

    const [sortKey, setSortKey] = useState(null) //with ! means negative sortOrder
    const [sortField, setSortField] = useState("created_on")
    const [sortOrder, setSortOrder] = useState(-1)
    const dataViewSortOptions = [
        {label: 'Higher Tier', value: '!difficulty'},
        {label: 'Lower Tier', value: 'difficulty'},
        {label: 'Last Released', value: "!created_on"},
        {label: 'First Released', value: "created_on"},
        {label: 'Name [A-Z]', value: "name"},
        {label: 'Name [Z-A]', value: "!name"},
        {label: 'Highest Rating', value: "!rating"},
        {label: 'Lowest Rating', value: "rating"}
    ]
    const onSortChange = (event) => {
        const value = event.value

        if (value.indexOf('!') === 0) {
            setSortOrder(-1)
            setSortField(value.substring(1, value.length))
            setSortKey(value)
        }
        else {
            setSortOrder(1)
            setSortField(value)
            setSortKey(value)
        }
        setFirst(0)
    }

    useEffect(()=>{
        document.title = "Maps - KZ Profile"
        window.scrollTo(0, 0)
    },[])

    const getTimesAndFilters = useCallback((refresh) => {
        setLoading(true)
        
        let promises = [
            getGlobalFilters(GameModeID[gameMode])
        ]

        if (steamProfileData) {
            promises.push(
                getPlayerTimes(steamProfileData.steamid, gameMode, refresh)
            )
        }
        
        Promise.all(promises)
            .then(results => {
                setFinishesAndFilters({
                    filters: results[0],
                    pro: results[1] ? results[1][0].data : [],
                    tp: results[1] ? results[1][1].data : []
                })
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
        
        
    },[gameMode, steamProfileData, getGlobalFilters, getPlayerTimes])

    useEffect(()=>{
        getTimesAndFilters(false)
    },[getTimesAndFilters])

    useEffect(() => {
        if (globalMaps.length && kzProfileMaps && finishesAndFilters) {
            //Join all the data and update "globalMapsExtended"
            
            let extendedData = []

            for (let i = 0; i < globalMaps.length; i++) {
                
                const filtersFound = finishesAndFilters.filters.filter(map => {
                    return map.map_id === globalMaps[i].id
                })

                const filtersFoundReduced = filtersFound.map(filter => filter.stage).sort((a,b)=>a-b)
                //Sort them because when filtering maps with filter AND bonus, I want the data to be ALWAYS [0,1]
                //This is not the case for bonus filters than arrive before the main stage one.
                
                const extraInfoFound = kzProfileMaps.find(map => {
                    return map.id === globalMaps[i].id
                })

                const proFinishesFound = finishesAndFilters.pro.filter(map => {
                    return map.map_id === globalMaps[i].id
                })

                const proFinishesFoundReduced = proFinishesFound.map(finish => {
                    return {
                        stage: finish.stage,
                        time: finish.time,
                        points: finish.points,
                        has_teleports: !!finish.teleports
                    }
                })

                const tpFinishesFound = finishesAndFilters.tp.filter(map => {
                    return map.map_id === globalMaps[i].id
                })

                const tpFinishesFoundReduced = tpFinishesFound.map(finish => {
                    return {
                        stage: finish.stage,
                        time: finish.time,
                        points: finish.points,
                        has_teleports: !!finish.teleports
                    }
                })

                let mapObject = {
                    id: globalMaps[i].id,
                    name: globalMaps[i].name,
                    difficulty: globalMaps[i].difficulty,
                    created_on: globalMaps[i].created_on,
                    filters: filtersFoundReduced,
                    finishes: proFinishesFoundReduced.concat(tpFinishesFoundReduced)
                }

                if (extraInfoFound) {
                    //To be able to filter mappers by name, I convert the mapper object into just the mapper name string
                    //If if I need the steamid in the future, leave the whole object and create a new FilterUtil specific to mappers objects.
                    mapObject.mappers = extraInfoFound.mappers ? extraInfoFound.mappers.map(mapper => mapper.name) : false
                    //When no videos, set it to false so I can filter maps without videos (null would not filter). Same for mappers.
                    mapObject.videos = extraInfoFound.videos ? extraInfoFound.videos : false
                    mapObject.rating = extraInfoFound.votes ? extraInfoFound.stars / extraInfoFound.votes : 0
                    mapObject.votes = extraInfoFound.votes ? extraInfoFound.votes : 0
                } else {
                    mapObject.mappers = false
                    mapObject.videos = false
                    mapObject.rating = 0
                    mapObject.votes = 0
                }

                extendedData.push(mapObject)
                
            }

            setGlobalMapsExtended(extendedData)
        }
    },[globalMaps, kzProfileMaps, finishesAndFilters])

    const isFilterBlank = (filter) => {
        if(filter !== null && filter !== undefined) {
            if((typeof filter === 'string' && filter.trim().length === 0) || (filter instanceof Array && filter.length === 0))
                return true
            else
                return false
        }
        return true
    }

    const filter = (filterValue, dataField, matchMode) => {
        let newFilters = localFilters ? {...localFilters} : {}

        if(!isFilterBlank(filterValue)) {
            newFilters[dataField] = {value: filterValue, matchMode: matchMode}
        } else if(newFilters[dataField]) { //if blank delete the old filter in that field if there was one
            delete newFilters[dataField]
        }
        setLocalFilters(newFilters)
    }

    useEffect(()=> {
        if (globalMapsExtended.length) {
            let newfilteredData = []
            const filterableFields = ["name", "difficulty", "mappers", "rating", "finishes", "filters", "created_on", "videos"] //so I only interate through these fields
    
            for (let i = 0; i < globalMapsExtended.length; i++) {
                let localMatch = true
                let globalMatch = false
    
                for (let j = 0; j < filterableFields.length; j++) {
                    let objectField = globalMapsExtended[i][filterableFields[j]] //Data value to be checked
                    let fieldFilter = localFilters ? localFilters[filterableFields[j]] : null//Filter value to use to check (value and matchMode)

                    if (fieldFilter) {
                        if (!FilterUtils[fieldFilter.matchMode](objectField, fieldFilter.value)) {
                            localMatch = false
                        }

                        if (!localMatch) {
                            break
                        }
                    }
                    if (!globalMatch) { //if it's already true, don't compare the next field cause it might be false and will always return the last field bool
                        globalMatch = FilterUtils['contains'](objectField, globalFilter)
                    }
                }

                let matches = localMatch && globalMatch
    
                if(matches) {
                    newfilteredData.push(globalMapsExtended[i])
                }
            }

            //prevent rerender
            if(newfilteredData.length === globalMapsExtended.length) {
                newfilteredData = globalMapsExtended
            }

            setFilteredData(newfilteredData)
            setFirst(0)
            setLoading(false)
        }
    },[localFilters, globalFilter, globalMapsExtended])

    const dataViewHeader = (
        <>
            <div style={{width: "clamp(335px, 75%, 560px)"}}>
                <NavLink className="stylized-navlink" to="/maps">
                    MAPS GRID
                </NavLink>
                <NavLink className="stylized-navlink" to="/maps/discover">
                    DISCOVER
                </NavLink>
            </div>
            <div className="p-d-inline-flex p-d-lg-block p-ai-center">
                <Button className="p-d-inline-flex p-d-lg-none" icon="pi pi-filter" onClick={() => setFiltersPanel(true)} />
                <span className="p-input-icon-left p-d-none p-d-lg-inline-flex">
                    <i className="pi pi-search" />
                    <InputText
                        className="p-inputtext-sm"
                        type="search"
                        value={globalFilter}
                        onChange={(e) => {
                            setGlobalFilter(e.target.value)
                        }}
                        placeholder="Global search" />
                </span>
            </div>
        </>
    )

    const resetFilters = () => {
        setTiersFilter([])
        setRatingFilter(null)
        setFiltersFilter([])
        setStageFilter("0")
        setFinishedFilter(null)
        setReleaseFilter(null)
        setVideoFilter(null)
        setMapperFilter(null)
        setFirst(0)
        setSortKey(null)
        setSortField("created_on")
        setSortOrder(-1)

        setGlobalFilter("")
        setLocalFilters({})
    }

    const onTierFilterChange = (e) => {
        let currentTierFilters = [...tiersFilter]
        if(e.checked) {
            currentTierFilters.push(e.value)
        } else {
            currentTierFilters.splice(currentTierFilters.indexOf(e.value), 1)
        }
        setTiersFilter(currentTierFilters)
        filter(currentTierFilters, "difficulty", "in")
    }

    const handleStageChange = (e) => {
        //For changes in the "Finishes" filters stage chooser.
        setStageFilter(e.target.value)
        if (finishedFilter === 1) {
            //Finished
            filter({stage: parseInt(e.target.value)}, "finishes", "exists")
        }
        else if (finishedFilter === 0) {
            //Unfinished
            filter({stage: parseInt(e.target.value)}, "finishes", "notExists")
        }
    }

    const dataViewLocalFilters = (
        <>
            <div className="p-d-block p-d-lg-none">
                <h3 className="p-mt-0">Search</h3>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        className="p-inputtext-sm"
                        type="search"
                        value={globalFilter}
                        onChange={(e) => {
                            setGlobalFilter(e.target.value)
                        }}
                        placeholder="Global search" />
                </span>
                <div className="p-d-inline-flex" style={{float: "right"}}>
                    <Button icon="pi pi-filter-slash" className="p-button-rounded p-button-text p-button-plain" onClick={resetFilters} />
                    <Button icon="pi pi-refresh" className="p-button-text p-button-plain p-button-rounded" onClick={() => getTimesAndFilters(true)} />
                </div>
                <Divider />
                <h3>Sort</h3>
            </div>
            <div className="p-d-flex p-jc-between p-ai-center">
                <Dropdown className="map-dataview-dropdown" value={sortKey} options={dataViewSortOptions} placeholder="Sorting options" onChange={onSortChange}/>
                <div className="p-d-none p-d-lg-flex">
                    <Button icon="pi pi-filter-slash" className="p-button-rounded p-button-text p-button-plain" onClick={resetFilters} />
                    <Button icon="pi pi-refresh" className="p-button-text p-button-plain p-button-rounded" onClick={() => getTimesAndFilters(true)} />
                </div>
            </div>
            <Divider />
            <h3>Tiers</h3>
            <div className="p-pl-2">
                <label className="p-field-checkbox">
                    <Checkbox value={1} onChange={onTierFilterChange} checked={tiersFilter.includes(1)}></Checkbox>
                    Very Easy
                </label>
                <label className="p-field-checkbox">
                    <Checkbox value={2} onChange={onTierFilterChange} checked={tiersFilter.includes(2)}></Checkbox>
                    Easy
                </label>
                <label className="p-field-checkbox">
                    <Checkbox value={3} onChange={onTierFilterChange} checked={tiersFilter.includes(3)}></Checkbox>
                    Medium
                </label>
                <label className="p-field-checkbox">
                    <Checkbox value={4} onChange={onTierFilterChange} checked={tiersFilter.includes(4)}></Checkbox>
                    Hard
                </label>
                <label className="p-field-checkbox">
                    <Checkbox value={5} onChange={onTierFilterChange} checked={tiersFilter.includes(5)}></Checkbox>
                    Very Hard
                </label>
                <label className="p-field-checkbox">
                    <Checkbox value={6} onChange={onTierFilterChange} checked={tiersFilter.includes(6)}></Checkbox>
                    Extreme
                </label>
                <label className="p-field-checkbox">
                    <Checkbox value={7} onChange={onTierFilterChange} checked={tiersFilter.includes(7)}></Checkbox>
                    Death
                </label>
            </div>
            <Divider />
            <h3>Rating</h3>
            <div className="p-pl-2">
                <Rating value={ratingFilter} onChange={(e) => {
                    setRatingFilter(e.value)
                    filter(e.value, "rating", "gte")
                }} />
            </div>
            <Divider />
            <h3>Filters</h3>
            <div className="p-pl-2">
                <label className="p-field-checkbox">
                    <Checkbox checked={filtersFilter.includes(0)} onChange={(e)=>{
                        let newArray = [...filtersFilter]
                        //Splice at index 0 an array of 0 length and add 0 (add 0 always in index 0) : Remove the first item (will always be 0)
                        e.checked ? newArray.splice(0, 0, 0) : newArray.shift()
                        setFiltersFilter(newArray)
                        filter(newArray, "filters", "contains")
                    }}></Checkbox>
                    Has Filter
                </label>
                <label className="p-field-checkbox">
                    <Checkbox checked={filtersFilter.includes(1)} onChange={(e)=>{
                        let newArray = [...filtersFilter]
                        //Push 1 to the array, will always be at the end : Remove the last item (will always be 1)
                        e.checked ? newArray.push(1) : newArray.pop()
                        setFiltersFilter(newArray)
                        filter(newArray, "filters", "contains")
                    }}></Checkbox>
                    Has Bonus
                </label>
            </div>
            <Divider />
            <h3>Finishes</h3>
            <div className="p-pl-2">
                <div className="p-d-flex p-mb-4 stage-selector">
                    <input
                        id="mainStageRadio"
                        type="radio"
                        name="stageRadio"
                        value="0"
                        checked={stageFilter === "0"}
                        onChange={handleStageChange}
                    />
                    <label htmlFor="mainStageRadio">
                        <i className="pi pi-clock p-mr-2"></i>
                        <span className="p-d-inline-flex p-d-lg-none p-d-xl-inline-flex">Main</span>
                    </label>
                    <input
                        id="bonusStageRadio"
                        type="radio"
                        name="stageRadio"
                        value="1"
                        checked={stageFilter === "1"}
                        onChange={handleStageChange}
                    />
                    <label htmlFor="bonusStageRadio">
                        <i className="pi pi-flag p-mr-2"></i>
                        <span className="p-d-inline-flex p-d-lg-none p-d-xl-inline-flex">Bonus</span>
                    </label>
                </div>
                {stageFilter === "1" && <div className="secondary-comment" >Only stage 1 finishes count.</div>}
                <label className="p-field-radiobutton">
                    <RadioButton name="finishesRadio" value={null} checked={finishedFilter === null}
                        onChange={(e) => {
                            setFinishedFilter(e.value)
                            filter(e.value, "finishes", "exists")
                        }}
                    />
                    All
                </label>
                <label className="p-field-radiobutton">
                    <RadioButton name="finishesRadio" value={1} checked={finishedFilter === 1}
                        onChange={(e) => {
                            setFinishedFilter(e.value)
                            filter({stage: parseInt(stageFilter)}, "finishes", "exists")
                        }}
                    />
                    Finished
                </label>
                <label className="p-field-radiobutton">
                    <RadioButton name="finishesRadio" value={0} checked={finishedFilter === 0}
                        onChange={(e) => {
                            setFinishedFilter(e.value)
                            filter({stage: parseInt(stageFilter)}, "finishes", "notExists")
                        }}
                    />
                    Unfinished
                </label>
            </div>
            <Divider />
            <h3>Features</h3>
            <div className="p-pl-2">
                <label className="p-field-checkbox">
                    <Checkbox checked={releaseFilter} onChange={(e)=>{
                        setReleaseFilter(e.checked)
                        if (e.checked) {
                            const latestMap = globalMaps.reduce((mapA, mapB) => {
                                const aMill = new Date (mapA.created_on).getTime()
                                const bMill = new Date (mapB.created_on).getTime()
                                return (aMill >= bMill ? mapA : mapB)
                            })
                            filter(latestMap.created_on.substring(0, 10), "created_on", "contains")
                        } else {
                            filter(null, "created_on", "contains")
                        }
                    }}></Checkbox>
                    Last Release
                </label>
                <label className="p-field-checkbox">
                    <TriStateCheckbox value={videoFilter} onChange={e => {
                        setVideoFilter(e.value)
                        if (e.value) {
                            filter(false, "videos", "notEquals")
                        } else {
                            filter(e.value, "videos", "equals")
                        }
                    }} />
                    Has Video
                </label>
                <label className="p-field-checkbox">
                    <TriStateCheckbox value={mapperFilter} onChange={e => {
                        setMapperFilter(e.value)
                        if (e.value) {
                            filter(false, "mappers", "notEquals")
                        } else {
                            filter(e.value, "mappers", "equals")
                        }
                    }} />
                    Has Mapper
                </label>
            </div>
        </>
    )

    const dataViewTemplate = (map) => {
        return (
            <div className="p-col dataview-card" style={{minWidth:"250px", maxWidth:"300px"}}>
                {loading ?
                    <Skeleton height="162px"></Skeleton> :
                    <MapCard map={map} runType={runType} setDialogVisibility={setDialogVisibility} setDialogProperties={setDialogProperties} />
                }
            </div>
        )
    }

    //weekly featured, daily featured, last release, recommended for you, [mapper]'s maps, Unfinished [tier], <700 [tier], unfinished bonuses, short/long maps???, best rated, oldest runs, tendencias (los mas jugados de los ultimos 100 recent/records)
    return (
        <div>
            <ModeChooser />
            <div className="p-grid p-m-0">
                <DataView
                    className="dataview-maps p-col-12 p-lg-10"
                    value={filteredData}
                    layout="grid"
                    header={dataViewHeader}
                    itemTemplate={dataViewTemplate}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    rows={20}
                    rowsPerPageOptions={[12,20,24,60]}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    first={first}
                    onPage={(e) => {
                        setFirst(e.first)
                    }}
                    emptyMessage="No maps found.">
                </DataView>
                <div className="p-d-none p-d-lg-block p-col-2">
                    {dataViewLocalFilters}
                </div>
            </div>
            <Sidebar visible={filtersPanel} fullScreen onHide={() => setFiltersPanel(false)} style={{overflow:"auto"}}>
                {dataViewLocalFilters}
            </Sidebar>
            <Dialog
                visible={dialogVisibility}
                header={dialogProperties.header}
                breakpoints={{'960px': '75vw', '640px': '100vw'}}
                style={{width: '60vw'}}
                onHide={() => setDialogVisibility(false)}
                maskClassName="mask-transition"
                contentStyle={{backgroundColor:"#121212", padding:"1.25rem"}}
                dismissableMask={true}
                keepInViewport={false}
            >
                {dialogProperties.content}
            </Dialog>
        </div>
    )
}

export default Maps