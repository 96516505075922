import React from "react"

function InformationGrid ({gridPanels, PanelDecoder}) {

    return (
        <div className="information-grid" >
            {gridPanels.map((panelID, index)=>{
                return PanelDecoder(panelID, panelID + index)
            })}
        </div>
    )
}

export default InformationGrid