import React, { createContext, useState, useEffect, useRef, useCallback, useContext } from "react"

import { GetSteam64 } from "../Utils/SteamID"

import { initializeApp } from 'firebase/app'
import { initializeAuth, indexedDBLocalPersistence, onAuthStateChanged, signOut } from 'firebase/auth'
import { getFirestore, doc, getDoc } from 'firebase/firestore/lite'

//I initialize everything outside the provider because it only needs to run once, part of the reason I use a Context.

const prodConfig = {
    apiKey: "AIzaSyCKlB9lY9BlT4UdzOzCM7paMekm0XbrqiU",
    authDomain: "kz-profile.firebaseapp.com",
    databaseURL: "https://kz-profile.firebaseio.com",
    projectId: "kz-profile",
    storageBucket: "kz-profile.appspot.com",
    messagingSenderId: "822702334066",
    appId: "1:822702334066:web:2e6b76fcf80420d0cae2eb",
    measurementId: "G-4DL48MWK8E"
}

const devConfig = {
    apiKey: "AIzaSyDBXIRb75m_WxvHWu2sqPvw3hprXkmIo3A",
    authDomain: "kz-profile-dev.firebaseapp.com",
    projectId: "kz-profile-dev",
    storageBucket: "kz-profile-dev.appspot.com",
    messagingSenderId: "692615779129",
    appId: "1:692615779129:web:3441352379573e04d58ee8"
}

const firebaseConfig = process.env.NODE_ENV === 'production' ? prodConfig : devConfig

//If it's getting called twice just import getApps and check if it has any length
const firebaseApp = initializeApp(firebaseConfig)

//Using initializeAuth() instead of getAuth() in order to exclude code for social login from being included in the bundle.
const auth = initializeAuth(firebaseApp, { persistence: [indexedDBLocalPersistence] })
const db = getFirestore(firebaseApp)

//To prevent calling the fetch function "getFirestoreUser" more than once I use this out of scope variable instead of the internal state.


/*if (process.env.NODE_ENV === 'production') {
    const analytics = getAnalytics()
}*/

export const FirebaseContext = createContext()

export const FirebaseProvider = ({children}) => {
    
    //For accessing the value changes in real time. Use this only if we know a higher component is already fetching the data.
    //For example, Player.js calls "getFirestoreUser", and Settings.js calls "firestoreUser"
    const [firestoreUser, setFirestoreUser] = useState()
    
    //For internal check, so we don't re render stuff (don't have to include firestoreUser in useCallback depedency)
    let firestoreUserRef = useRef()

    //For fetching the data for the first time or getting the cached data.
    //Using useRef and useCallback to prevent unnecesary re-renders (double fetching the database)
    const getFirestoreUser = useCallback(async (steamid) => {

        steamid = GetSteam64(steamid)
    
        const steamProfileData = JSON.parse(window.localStorage.getItem("steamProfileData"))
    
        if (steamProfileData && steamProfileData.steamid === steamid && firestoreUserRef.current) {
            //console.log("Returning cached user.")
            return Promise.resolve(firestoreUserRef.current)
        }
    
        try {
            const docRef = doc(db, "users", steamid)
            const docSnap = await getDoc(docRef)
        
            if (docSnap.exists()) {
                if (steamProfileData && steamProfileData.steamid === steamid) {
                    //console.log("Saving user in cache.")
                    firestoreUserRef.current = docSnap.data()
                    setFirestoreUser(docSnap.data())
                }
                //console.log("Returning data fetched from Firestore.")
                return Promise.resolve(docSnap.data())
            }
    
            //console.log("A called was made to Firestore but no data was found.")
            return Promise.resolve(undefined)
        } catch (err) {
            // docSnap.data() will be undefined in this case
            return Promise.reject(err)
        }
    },[])

    return (
        <FirebaseContext.Provider value={{auth, db, getFirestoreUser, firestoreUser}}>
            { children }
        </FirebaseContext.Provider>
    )
}

export function useAuth () {
    return auth
}

export function useFirestore () {
    return db
}

export function useFirebaseUser () {
    
    const [currentUser, setCurrentUser] = useState()
    const [loadingUser, setLoadingUser] = useState(true)

    useEffect(() => {
        const listener = onAuthStateChanged(auth, user => {
            if (user) {
                setCurrentUser(user)
            }
            setLoadingUser(false)
        })
    
        return () => {
            listener()
        }
    }, [])

    return [currentUser, loadingUser]
}

export function SignOut () {

    signOut(auth).then(() => {
        window.localStorage.removeItem("steamProfileData")
        window.location.href = "/"
    }).catch(error => {
        console.log(error)
    })
}

export function useFirestoreUser () {
    
    const context = useContext(FirebaseContext)
    if (context === undefined) {
        throw new Error("Context must be used within a Provider")
    }
    return context.firestoreUser
}