import React from "react"

import "./CompletionsPanel.css"
import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';

function CompletionsPanel ({mapsByTier, mapsTotal, completedMapsByTier, completedMapsTotal, rows, columns}) {
    
    const foregroundColors = ["#40fe40", "#a1fe47", "#ece37a", "#e4ae39", "#fe4040", "#fe0000", "#d22ce5"]
    const backgroundColors = ["#1b411b4D", "#2f411d4D", "#3e3c274D", "#3c311a4D", "#411b1b4D", "#410e0e4D", "#38173c4D"]

    if (rows && columns) {
        return (
            <div style={{gridArea: `span ${rows}/ span ${columns}`, borderRadius:"5px", overflow:"auto"}}>
                <Card className="completions-card" title="Completions" subTitle={`${completedMapsTotal}/${mapsTotal} • ${mapsTotal === 0 ? 0 : (completedMapsTotal/mapsTotal*100).toFixed(2)}%`}>
                    {mapsByTier.map((mapCount, index) => {
                        const tierPercentage = mapCount === 0 ? 0 : completedMapsByTier[index]/mapCount*100 //to prevent divided by 0 errors while loading
                        return (
                            <div className="completions-progress" key={index}>
                                <span className="completions-percent">{`${tierPercentage.toFixed(2).padStart(5,"0").substring(0, 5)}%`}</span>
                                <ProgressBar
                                    value={tierPercentage}
                                    color={foregroundColors[index]}
                                    style={{backgroundColor: backgroundColors[index]}}
                                />
                                <span className="completions-totals">{`${completedMapsByTier[index]}/${mapCount}`}</span>
                            </div>
                        )
                    })}
                </Card>
            </div>
        )
    }

    //If we don't have it, it's just a standalone panel
    return (
        <Card className="completions-card" title="Completions" subTitle={`${completedMapsTotal}/${mapsTotal} • ${mapsTotal === 0 ? 0 : (completedMapsTotal/mapsTotal*100).toFixed(2)}%`}>
            {mapsByTier.map((mapCount, index) => {
                const tierPercentage = mapCount === 0 ? 0 : completedMapsByTier[index]/mapCount*100 //to prevent divided by 0 errors while loading
                return (
                    <div className="completions-progress" key={index}>
                        <span className="completions-percent">{`${tierPercentage.toFixed(2).padStart(5,"0").substring(0, 5)}%`}</span>
                        <ProgressBar
                            value={tierPercentage}
                            color={foregroundColors[index]}
                            style={{backgroundColor:backgroundColors[index]}}
                        />
                        <span className="completions-totals">{`${completedMapsByTier[index]}/${mapCount}`}</span>
                    </div>
                )
            })}
        </Card>
    )

}

export default CompletionsPanel