import React, { useState, useEffect, useContext } from "react"

import "./Leaderboard.css"

import {
    Link
} from "react-router-dom"
import { useSearchParams } from "../../hooks/useSearchParams"

import axios from 'axios'

import { GetAPI_PlayerRanks, GetSteam_Profile } from '../../Utils/APICalls'
import { GameModeContext, GameModeID } from "../../context/GameModeContext"
import BannerBackground from "../Essentials/BannerBackground"
import ModeChooser from "../Essentials/ModeChooser"
import LeaderBoardCard from "./LeaderboardCard"
import LoadingKZ from "../Essentials/LoadingKZ"
import PlayerFlag from "../Essentials/PlayerFlag"

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Skeleton } from 'primereact/skeleton'

function PointsLeaderboard () {

    const {gameMode, runType} = useContext(GameModeContext)
    const [stage, setStage] = useSearchParams("stage", "main")

    const [loading, setLoading] = useState(true)
    
    const [pointsData, setPointsData] = useState()
    const emptyTable = new Array(20)

    const handleErrors = (err) => {
        if (axios.isCancel(err)) {
            console.log('Request canceled: ', err.message)
        } else {
            console.log(err)
        }
    }

    useEffect(()=>{
        setLoading(true)
        const stages = {
            main: [0],
            bonus: [1,2,3,4,5,6,7,8,9,10],
            overall: []
        }

        GetAPI_PlayerRanks(GameModeID[gameMode], runType, stages[stage])
            .then(APIresponse => {
                const steamid64s = APIresponse.data.map(player=>{
                    return player.steamid64
                })
                GetSteam_Profile(steamid64s)
                    .then(steamResponse => {
                        for (let i = 0; i < APIresponse.data.length; i++) {
                            const steamPlayer = steamResponse.data.response.players.find(player=>
                                APIresponse.data[i].steamid64 === player.steamid
                            )
                            APIresponse.data[i].avatarfull = steamPlayer.avatarfull
                            APIresponse.data[i].avatar = steamPlayer.avatar
                            APIresponse.data[i].loccountrycode = steamPlayer.loccountrycode
                        }
                        setPointsData(APIresponse.data)
                        setLoading(false)
                    })
                    .catch(err => {
                        handleErrors(err)
                    })
            })
            .catch(err => {
                handleErrors(err)
            })
    },[gameMode, runType, stage])

    const rowIndex = (rowData, column) => {
        return(
            loading ? 
            <Skeleton></Skeleton> :
            <div>
                {column.rowIndex + 1}
            </div>
        )
    }

    const playerLink = (rowData) => {
        return (
            loading ?
            <Skeleton></Skeleton> :
            <Link className="p-d-inline-flex p-ai-center" to={`/player/${rowData.steamid64}`}>
                <img src={rowData.avatar} alt="avatar" style={{borderRadius:"50%"}}></img>
                <span className="p-pl-3 p-pr-3">{rowData.player_name}</span>
                <PlayerFlag nationality={rowData.loccountrycode} flagHeight="22" />
            </Link>
        )
    }

    const stylizedPoints = (rowData) => {
        return (
            loading ?
            <Skeleton></Skeleton> :
            <div>
                {rowData.points.toLocaleString()}
            </div>
        )
    }

    const stylizedFinishes = (rowData) => {
        return (
            loading ?
            <Skeleton></Skeleton> :
            <div>
                {rowData.finishes.toLocaleString()}
            </div>
        )
    }

    const stylizedAverage = (rowData) => {
        return (
            loading ?
            <Skeleton></Skeleton> :
            <div>
                {rowData.average.toFixed(2)}
            </div>
        )
    }

    return (
        <div>
            <div className="leaderboard-banner">
                <BannerBackground mapID="kz_nassau" />
                <div className="leaderboard-banner-wrapper">
                    {/*only reason to create this wrapper is to prevent overflow when moving loading exits the screen*/}
                    <ModeChooser />
                    {loading ?
                    <LoadingKZ /> :
                    <div className="p-d-flex p-jc-evenly p-mt-3">
                        <LeaderBoardCard
                            playerPlace = {2}
                            playerID = {pointsData[1].steamid64}
                            playerName = {pointsData[1].player_name}
                            playerScore = {pointsData[1].points}
                            playerAvatar = {pointsData[1].avatarfull}
                            leaderboardType = {"points"}
                        />
                        <LeaderBoardCard
                            playerPlace = {1}
                            playerID = {pointsData[0].steamid64}
                            playerName = {pointsData[0].player_name}
                            playerScore = {pointsData[0].points}
                            playerAvatar = {pointsData[0].avatarfull}
                            leaderboardType = {"points"}
                        />
                        <LeaderBoardCard
                            playerPlace = {3}
                            playerID = {pointsData[2].steamid64}
                            playerName = {pointsData[2].player_name}
                            playerScore = {pointsData[2].points}
                            playerAvatar = {pointsData[2].avatarfull}
                            leaderboardType = {"points"}
                        />
                    </div>
                    }
                </div>
            </div>
            <div className="leaderboard-body">
                {runType === "nub" &&
                <div className="p-text-center p-pt-3 p-pb-3" style={{color:"rgba(255,255,255,0.6"}}>
                    {/*If I fetch without has_teleports, it breaks with kz_timer, too many points to be fetched, so use TP instead*/}
                    Points leaderboards aren't compatible with NUB mode. TP mode will be used instead.
                </div>
                }
                <div className="p-d-flex p-jc-center p-p-3">
                    <div className="leaderboard-selector">
                        <input
                            id="bonusStageRadio"
                            type="radio"
                            name="stagesRadio"
                            value="bonus"
                            checked={stage === "bonus"}
                            onChange={()=>setStage("bonus")}
                        />
                        <label htmlFor="bonusStageRadio">BONUS</label>
                    </div>
                    <div className="leaderboard-selector">
                        <input
                            id="mainStageRadio"
                            type="radio"
                            name="stagesRadio"
                            value="main"
                            checked={stage === "main"}
                            onChange={()=>setStage("main")}
                        />
                        <label htmlFor="mainStageRadio">MAIN STAGE</label>
                    </div>
                    <div className="leaderboard-selector">
                        <input
                            id="overallStageRadio"
                            type="radio"
                            name="stagesRadio"
                            value="overall"
                            checked={stage === "overall"}
                            onChange={()=>setStage("overall")}
                        />
                        <label htmlFor="overallStageRadio">OVERALL</label>
                    </div>
                </div>
                <div className="p-d-flex p-jc-center p-mt-3">
                    <DataTable
                        value={loading? emptyTable : pointsData}
                        className="minimal-datatable"
                        sortField="points"
                        sortOrder={-1}
                        autoLayout={true}
                        style={{overflow:"auto"}} //careful, this might brake some functionality, but works.
                    >
                        <Column
                            header="#"
                            body={rowIndex}
                            headerStyle={{textAlign:"center"}}
                            bodyStyle={{textAlign:"center"}}>
                        </Column>
                        <Column
                            field="player_name"
                            header="Name"
                            body={playerLink}
                            headerStyle={{width: "25vw", maxWidth: "500px", minWidth: "15rem"}}>
                        </Column>
                        <Column
                            field="points"
                            header="Points"
                            body={stylizedPoints}
                            headerStyle={{textAlign:"center", minWidth: "6rem"}}
                            bodyStyle={{textAlign:"center"}}>
                        </Column>
                        <Column
                            field="finishes"
                            header="Finishes"
                            body={stylizedFinishes}
                            headerStyle={{textAlign:"center", minWidth: "6rem"}}
                            bodyStyle={{textAlign:"center"}}>
                        </Column>
                        <Column
                            field="average"
                            header="Average"
                            body={stylizedAverage}
                            headerStyle={{textAlign:"center", minWidth: "6rem"}}
                            bodyStyle={{textAlign:"center"}}>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    )
}

export default PointsLeaderboard