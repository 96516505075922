import React, { useState, useEffect, useRef } from "react"

import "./Navbars.css"

import {useFirebaseUser, SignOut} from "../../context/FirebaseContext"
import { useGlobalMaps } from "../../context/MapsContext"

import { useLocalStorage } from "../../hooks/useLocalStorage"
import { useLocalSettings } from "../../hooks/useLocalSettings"

import { GetSteam_VanityURL } from "../../Utils/APICalls"

import { NavLink, Link, useRouteMatch, useHistory } from "react-router-dom"

import LoginButton from "./LoginButton"

import { GetSteam64, isValidInput } from "../../Utils/SteamID"

import { Sidebar } from 'primereact/sidebar'
import { Button } from 'primereact/button'
import { AutoComplete } from 'primereact/autocomplete'
import { Chip } from 'primereact/chip'
import { Avatar } from 'primereact/avatar'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { confirmDialog } from 'primereact/confirmdialog'

function Navbars ({ messageToast }) {
    const [currentUser, loadingUser] = useFirebaseUser()
    
    const [steamProfileData] = useLocalStorage("steamProfileData", null)
    const [localSettings] = useLocalSettings()

    const globalMaps = useGlobalMaps()
    const [filteredMaps, setFilteredMaps] = useState()
    const autocompleteInput = useRef()
    const history = useHistory()

    const profileMatch = useRouteMatch("/player/:steamid")
    const jumpstatsLeaderboardMatch = useRouteMatch("/leaderboards/jumpstats/:jumptype")

    const [visibleSidebar, setVisibleSidebar] = useState(false)
    const [searchValue, setSearchValue] = useState('')

    const [leaderboardAccordionIndex, setLeaderboardAccordionIndex] = useState(null)

    const handleKeyDown = (e) => {
        if (!document.querySelector("input:focus, textarea:focus")){
            autocompleteInput.current.inputRef.current.focus()
        }
    }

    useEffect(() => {
        document.addEventListener("keypress", handleKeyDown)
        return () => document.removeEventListener("keypress", handleKeyDown)
    },[])

    const searchMaps = (event) => {
        let _filteredMaps
        if (!event.query.trim().length) {
            _filteredMaps = [...globalMaps]
        }
        else {
            _filteredMaps = globalMaps.filter((map) => {
                return map.name.toLowerCase().includes(event.query.toLowerCase())
            })
        }

        setFilteredMaps(_filteredMaps)
    }

    const autocompleteMapTemplate = (item) => {
        const TIER = ["Very Easy","Easy","Medium","Hard","Very Hard","Extreme","Death"]
        return (
            <div className="p-d-flex p-jc-between p-ai-center">
                <span className="p-text-nowrap p-text-truncate p-mr-2">{item.name}</span>
                <span
                    className={`${(TIER[item.difficulty - 1]).toUpperCase().replace(/ /g, "-")} p-d-none p-d-md-block`}
                    style={{fontSize:"0.725rem"}}
                >
                    {TIER[item.difficulty - 1].toUpperCase()}
                </span>
                <span
                    className={`${(TIER[item.difficulty - 1]).toUpperCase().replace(/ /g, "-")} p-d-block p-d-md-none`}
                >
                    {item.difficulty}
                </span>
            </div>
        )
    }

    const confirmSignOut = () => {
        confirmDialog({
            message: 'Are you sure you want to sign out?',
            header: 'Sign Out',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                setVisibleSidebar(false)
                SignOut()
            },
            //reject: () => rejectFunc()
        })
    }

    return (
        <div>
            <div className="p-d-flex topBar">

                <div className="p-d-flex p-ai-center p-jc-center" style={{flex:"1"}}>
                    <Button onClick={(e) => setVisibleSidebar(true)} className="p-button-rounded p-mr-auto" icon="pi pi-bars" style={{background:"none"}} />
                </div>

                <div className="p-d-flex p-ai-center p-jc-center p-pr-3 p-pl-3" style={{flex:"1"}}>
                    <span className="p-input-icon-left autocomplete-wrapper">
                        <i className="pi pi-search" />
                        <AutoComplete
                            ref={autocompleteInput}
                            type="search"
                            className="p-inputtext-sm"
                            panelClassName="navbar-autocomplete-panel"
                            style={{width:"100%"}}
                            size={15} //to overwrite the browser default min width
                            inputStyle={{flexGrow:"1", backgroundColor:"transparent", paddingLeft:"2.5rem"}}
                            placeholder="Search"
                            delay={0}
                            minLength={2}
                            autoHighlight
                            value={searchValue}
                            suggestions={filteredMaps}
                            field="name"
                            itemTemplate={autocompleteMapTemplate}
                            virtualScrollerOptions={{ itemSize: 43, scrollHeight:"200px", style:{maxHeight:`${filteredMaps && filteredMaps.length * 43}px`, overflowX:"hidden"}}}
                            completeMethod={searchMaps}
                            onChange={e => setSearchValue(e.value)}
                            onSelect={e => {
                                setSearchValue("")
                                e.originalEvent.target.blur()
                                //This push makes the browser think it has to download the map's page again (ie localhost:3000/maps/kz_kiwiterror).
                                //It's harmless apparently.
                                history.push(`/maps/${e.value.name}`)
                            }}
                            onKeyPress={e => {
                                if (e.key === 'Enter' && e.target.value.trim().length >= 2) {
                                    //This is only called when no dropdown suggestion was selected.
                                    setSearchValue("")
                                    e.stopPropagation() //blur doesn't work if I don't stop propagation
                                    e.target.blur()
                                    
                                    if (isValidInput(searchValue)) {
                                        const steam64 = GetSteam64(searchValue)

                                        if (steam64) {
                                            history.push(`/player/${steam64}`)
                                        } else {
                                            //It's a vanity url, the only valid input that returns null with GetSteam64
                                            GetSteam_VanityURL(searchValue)
                                                .then(response => {
                                                    if (response.data.response.success === 1) {
                                                        history.push(`/player/${response.data.response.steamid}`)
                                                    } else {
                                                        messageToast.current.show({severity: 'warn', summary: 'Steam', detail: "This vanity URL doesn't exist.", life: 5000})
                                                    }
                                                })
                                                .catch(() => {
                                                    messageToast.current.show({severity: 'warn', summary: 'Steam', detail: "An error occurred retrieving this vanity URL.", life: 5000})
                                                })
                                        }
                                    } else if (/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}(?::[0-9]{1,5})?$/.test(searchValue)) {
                                        //The user is searching for a server ip address
                                        history.push(`/servers?search=${searchValue}`)
                                    } else {
                                        //The user is searching for a player.
                                        history.push(`/playersearch?player=${searchValue}`)
                                    }
                                }
                            }}
                            onFocus={e=>e.target.select()} //To start writing right away without having to delete previous value.
                        />
                    </span>
                </div>

                <div className="p-d-flex p-jc-center p-ml-auto" style={{flex:"1"}}>
                        {steamProfileData ? 
                        <div className="p-ml-auto p-d-flex p-ai-center">
                            <Chip className="p-mr-3 p-d-none p-d-sm-inline-flex" style={{fontSize:"0.875rem"}} label="526" icon="pi pi-ticket" />
                            <Link style={{borderRadius:"50%"}} to={`/player/${steamProfileData.steamid}${localSettings.defaultProfilePage}`}>
                                <Avatar
                                    style={{width:"2.5rem", height:"2.5rem", display:"block", backgroundColor:"#1E1E1E"}}
                                    image={!loadingUser ? currentUser && currentUser.photoURL ? currentUser.photoURL : steamProfileData.avatarmedium : null}
                                    shape="circle"
                                />
                            </Link>
                        </div> :
                        <div className="p-ml-auto p-d-flex p-ai-center">
                            <LoginButton />
                        </div>
                        }
                </div>

            </div>

            <Sidebar className="main-sidebar" visible={visibleSidebar} onHide={() => setVisibleSidebar(false)} showCloseIcon={false} >
                <Button onClick={(e) => setVisibleSidebar(false)} icon="pi pi-bars" className="p-button-rounded" style={{background:"none", marginLeft:"0.75rem"}} />
                <div className="p-menu">
                    <ul>
                        <li className="p-submenu-header">MENU</li>
                        <li className="p-menuitem">
                            <NavLink onClick={(e) => setVisibleSidebar(false)} className="p-menuitem-link" exact to="/" >
                                <span className="p-menuitem-icon pi pi-home"></span>
                                <span className="p-menuitem-text">Home</span>
                            </NavLink>
                        </li>
                        {steamProfileData && 
                        <li className="p-menuitem">
                            <NavLink
                                onClick={(e) => setVisibleSidebar(false)}
                                className="p-menuitem-link"
                                to={`/player/${steamProfileData.steamid}${localSettings.defaultProfilePage}`}
                                isActive={()=>{
                                    return profileMatch
                                }}
                            >
                                <span className="p-menuitem-icon pi pi-user"></span>
                                <span className="p-menuitem-text">Profile</span>
                            </NavLink>
                        </li>}
                        <li className="p-menuitem">
                            <NavLink onClick={(e) => setVisibleSidebar(false)} className="p-menuitem-link" to="/maps" >
                                <span className="p-menuitem-icon pi pi-map"></span>
                                <span className="p-menuitem-text">Maps</span>
                            </NavLink>
                        </li>
                        <li className="p-menuitem">
                            <NavLink onClick={(e) => setVisibleSidebar(false)} className="p-menuitem-link" to="/store" >
                                <span className="p-menuitem-icon pi pi-shopping-cart"></span>
                                <span className="p-menuitem-text">Store</span>
                            </NavLink>
                        </li>

                        <li className="p-submenu-header">COMMUNITY</li>
                        <Accordion
                            className="sidebar-accordion"
                            activeIndex={leaderboardAccordionIndex}
                            onTabChange={(e) => setLeaderboardAccordionIndex(e.index)}
                            expandIcon="pi pi-angle-right"
                            collapseIcon="pi pi-angle-down"
                        >
                            <AccordionTab
                                header={
                                    <div>
                                        <span className="p-menuitem-icon pi pi-flag"></span>
                                        <span className="p-menuitem-text">Leaderboards</span>
                                    </div>
                                }
                            >
                                <NavLink
                                    onClick={(e) => setVisibleSidebar(false)}
                                    className="p-menuitem-link"
                                    to="/leaderboards/records"
                                >
                                    <span className="p-menuitem-text">World Records</span>
                                </NavLink>
                                <NavLink
                                    onClick={(e) => setVisibleSidebar(false)}
                                    className="p-menuitem-link"
                                    to="/leaderboards/points"
                                >
                                    <span className="p-menuitem-text">Points</span>
                                </NavLink>
                                <NavLink
                                    onClick={(e) => setVisibleSidebar(false)}
                                    className="p-menuitem-link"
                                    to="/leaderboards/jumpstats/longjump"
                                    isActive={()=>{
                                        return jumpstatsLeaderboardMatch
                                    }}
                                >
                                    <span className="p-menuitem-text">Jumpstats</span>
                                </NavLink>
                            </AccordionTab>
                        </Accordion>
                        <li className="p-menuitem">
                            <NavLink onClick={(e) => setVisibleSidebar(false)} className="p-menuitem-link" to="/servers" >
                                <span className="p-menuitem-icon pi pi-desktop"></span>
                                <span className="p-menuitem-text">Servers</span>
                            </NavLink>
                        </li>
                        <li className="p-menuitem">
                            <NavLink onClick={(e) => setVisibleSidebar(false)} className="p-menuitem-link" to="/bans" >
                                <span className="p-menuitem-icon pi pi-ban"></span>
                                <span className="p-menuitem-text">Bans</span>
                            </NavLink>
                        </li>

                        <li className="p-submenu-header">TOOLS</li>
                        <li className="p-menuitem">
                            <NavLink onClick={(e) => setVisibleSidebar(false)} className="p-menuitem-link" to="/playersearch" >
                                <span className="p-menuitem-icon pi pi-search"></span>
                                <span className="p-menuitem-text">Player Search</span>
                            </NavLink>
                        </li>
                        <li className="p-menuitem">
                            <NavLink onClick={(e) => setVisibleSidebar(false)} className="p-menuitem-link" to="/compare" >
                                <span className="p-menuitem-icon pi pi-users"></span>
                                <span className="p-menuitem-text">Compare</span>
                            </NavLink>
                        </li>
                        <li className="p-menuitem">
                            <NavLink onClick={(e) => setVisibleSidebar(false)} className="p-menuitem-link" to="/distbug" >
                                <span className="p-menuitem-icon pi pi-pencil"></span>
                                <span className="p-menuitem-text">Distbug Calculator</span>
                            </NavLink>
                        </li>
                        <li className="p-menuitem">
                            <NavLink onClick={(e) => setVisibleSidebar(false)} className="p-menuitem-link" to="/games" >
                                <span className="p-menuitem-icon pi pi-th-large"></span>
                                <span className="p-menuitem-text">Games</span>
                            </NavLink>
                        </li>
                        {steamProfileData &&
                        <>
                            <li className="p-submenu-header">ACCOUNT</li>
                            <li className="p-menuitem">
                                <NavLink
                                    onClick={(e) => setVisibleSidebar(false)}
                                    className="p-menuitem-link"
                                    to={`/player/${steamProfileData.steamid}/settings`}
                                    isActive={()=>{
                                        return false
                                    }}
                                >
                                    <span className="p-menuitem-icon pi pi-cog"></span>
                                    <span className="p-menuitem-text">Settings</span>
                                </NavLink>
                            </li>
                            <li className="p-menuitem">
                                <NavLink
                                    onClick={e => {
                                        confirmSignOut()
                                    }}
                                    className="p-menuitem-link"
                                    isActive={()=>{
                                        return false
                                    }}
                                    exact to="/"
                                >
                                    <span className="p-menuitem-icon pi pi-power-off"></span>
                                    <span className="p-menuitem-text">Sign Out</span>
                                </NavLink>
                            </li>
                        </>}
                    </ul>
                </div>
            </Sidebar>
        </div>
        
    )
}

export default Navbars