import React, {useState, useEffect} from "react"

import "./VideoGallery.css"

import ErrorImage from '../../img/error-image.png'

import { Carousel } from 'primereact/carousel'

function VideoGallery ({ videos, mapName }) {

    const [videosArray, setVideosArray] = useState([])

    const [currentPage, setCurrentPage] = useState(0)

    useEffect(()=>{
        let newVideosArray = videos.map(videoCode => {
        
            const [isValid, site, id] = /^([a-z]{2})\/([\w-?=]{1,})$/.exec(videoCode)
            
            if (isValid) {
                //Youtube
                if (site === "yt") {
                    return `https://www.youtube.com/embed/${id}`
                }
                
                //Twitch Clip
                if (site === "tc") {
                    return `https://clips.twitch.tv/embed?clip=${id}&parent=${window.location.hostname}&autoplay=false`
                }
                
                //Twitch Video
                if (site === "tv") {
                    return `https://player.twitch.tv/?video=${id}&parent=${window.location.hostname}&autoplay=false`
                }
    
                //Bilibili Video
                if (site === "bi") {
                    return `https://player.bilibili.com/player.html?bvid=${id}`
                }
            }

            return null
        })

        if (mapName) {
            newVideosArray.push(mapName)
        }

        setVideosArray(newVideosArray)
        setCurrentPage(0)
    },[videos, mapName])

    const itemTemplate = (item) => {
        return (
            <div className="aspect-ratio-box">
                {item === mapName ?
                <div
                    className="aspect-ratio-box-inside map-image"
                    style={{backgroundImage: `url(https://raw.githubusercontent.com/KZGlobalTeam/map-images/public/webp/${mapName}.webp), url(${ErrorImage})`}}
                >
                    <a
                        href={`https://raw.githubusercontent.com/KZGlobalTeam/map-images/public/images/${mapName}.jpg`}
                        target="_blank"
                        rel="noreferrer"
                        className="image-maximize"
                    >
                        <i className="pi pi-window-maximize" style={{fontSize:"1.25rem"}}></i>
                    </a>
                </div> :
                <iframe
                    src={item}
                    height="100%"
                    width="100%"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={true}
                    className="aspect-ratio-box-inside"
                    style={{border:"none"}}
                    title="VideoPanel"
                />
                }
            </div>
        )
    }

    return (
        <div className="video-gallery">
            <Carousel
                value={videosArray}
                itemTemplate={itemTemplate}
                className="video-carousel"
                page={currentPage}
                onPageChange={(e) => setCurrentPage(e.page)}>
            </Carousel>
            <div className="carousel-indicators">
                {videosArray.map((video, index)=>{
                    let videoThumbnail = ""
                    if (video === mapName) {
                        videoThumbnail = `https://raw.githubusercontent.com/KZGlobalTeam/map-images/public/webp/${mapName}.webp`
                    }else if (video.indexOf("videoseries") !== -1) {
                        videoThumbnail = "/img/Playlist.png"
                    } else if (video.indexOf("twitch") !== -1) {
                        videoThumbnail = "/img/twitch-thumbnail.png"
                    } else if (video.indexOf("bilibili") !== -1) {
                        videoThumbnail = "/img/bilibili-thumbnail.png"
                    } else {
                        videoThumbnail = `https://img.youtube.com/vi/${video.substring(30, 41)}/0.jpg`
                    }

                    return (
                        <div style={{position:"relative"}} key={video}>
                            <button
                                className="carousel-indicator"
                                style={{
                                    backgroundImage:`url(${videoThumbnail}), url(${ErrorImage})`,
                                    opacity: currentPage === index ? 1 : 0.2,
                                    border: currentPage === index ? "1px solid white" : "none"
                                }}
                                onClick={()=>{
                                    setCurrentPage(index)
                                }}>
                            </button>
                            {video !== mapName && <div className="video-thumbnail-overlay"></div>}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default VideoGallery