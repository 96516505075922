import React, { useEffect } from "react"
import axios from "axios"

import { signInWithCustomToken } from "firebase/auth"
import { useAuth } from "../../context/FirebaseContext"

import { useLocation } from "react-router-dom"

import { useLocalStorage } from "../../hooks/useLocalStorage"
import { GetSteam_Profile } from "../../Utils/APICalls"
import LoadingKZ from "../Essentials/LoadingKZ"

function Login () {
    
    const auth = useAuth()
    const location = useLocation()

    const [steamProfileData, setSteamProfileData] = useLocalStorage("steamProfileData", null)

    useEffect(()=>{
        if (!steamProfileData) {
            console.log("Getting token")
            axios.get(`${process.env.NODE_ENV === 'production' ? 'https://kzprofile-api.vercel.app' : 'http://localhost:5005'}/api/MintFirebaseToken${location.search}`)
            .then(response => {
                signInWithCustomToken(auth, response.data.JWT)
                    .then(userCredential => {
                        const user = userCredential.user
                        
                        GetSteam_Profile([user.uid])
                            .then(steamResponse => {
                                setSteamProfileData(steamResponse.data.response.players[0])
                                window.location.href = `/player/${user.uid}/settings`
                            })
                            .catch(error => {
                                console.log(error)
                            })
                    })
                    .catch((error) => {
                        const errorCode = error.code
                        const errorMessage = error.message
                        console.log(errorCode, errorMessage)
                        // ...
                    });
            })
            .catch(error => {
                console.log(error)
            })
        }
    },[auth, location, steamProfileData, setSteamProfileData])

    return (
        <div className="p-d-flex p-jc-center" style={{minHeight:"20vh", overflow:"hidden", position:"relative"}}>
            <h1>Loggin In...</h1>
            <LoadingKZ />
        </div>
    )
}

export default Login